import React from 'react';
import article from 'store/article';
import auth from 'store/auth';
import notification from 'store/notification';
import admin from 'store/admin';
import user from 'store/user';
import mobiler from 'store/mobiler';
import tag from 'store/tag';
import channel from 'store/channel';
import tgChannel from 'store/tgChannel';
import station from 'store/station';
import support from 'store/support';
import comment from 'store/comment';
import advice from 'store/advice';
import quiz from 'store/quiz';
import role from 'store/role';
//import file from 'store/file';
import select from 'store/select';
import quizResult from 'store/quizResult';
import report from 'store/report';
import journal from 'store/journal';
import fcm from 'store/fcm';
import logger from 'store/logger';
import event from 'store/event';

export const stores = Object.freeze({
    article,
    tag,
    auth,
    notification,
    admin,
    user,
    mobiler,
    support,
    comment,
    advice,
    quiz,
    role,
    //file,
    select,
    quizResult,
    report,
    channel,
    tgChannel,
    station,
    journal,
    fcm,
    logger,
    event,
});

export const storesContext = React.createContext(stores);
//export const StoresProvider = storesContext.Provider;

//export const useStores = () => React.useContext(storesContext);
export const useStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
    React.useContext(storesContext)[store];
