import { makeAutoObservable } from 'mobx';

import { fcmApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IFcmInList {
    id: number;
    title: string;
    // isPublished: boolean;
    // publishedAt: Date;
    // status: number;
    // statusAt: Date;
    // createdAt: Date;
}

class FcmStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<{ id: number }, {}, {}>(fcmApi);

    readonly list = new ListStore<IFcmInList>(fcmApi);
}

export default new FcmStore();
