import { makeAutoObservable } from 'mobx';
import { userApi } from 'api';

import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IUserInList {
    id: number;
    login: string;
    email: string;
    create: Date;
    firstname: string;
    lastname: string;
    types: Array<'mobile' | 'guest' | 'admin'>;
}

export interface IUser {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    commonName: string;
    role?: { id: number; title: string };
    status: number;
}

export interface IUserUpdate {
    login?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    commonName?: string;
    password?: string;
    status?: number;
}

export interface IUserCreate {
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    commonName: string;
}

class UserStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IUser, IUserUpdate, IUserCreate>(userApi);

    list = new ListStore<IUserInList>(userApi);
}

export default new UserStore();
