// *************************************************************************************************************
// конфигурация приложения
import { useEffect, useState, useRef } from 'react';

import { authApi } from 'api';

type TPublicConfig = {
    passwordMinLength: number;
    passwordMaxLength: number;
    passwordPattern: string | RegExp;
};

/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/lines-between-class-members */
class Config {
    public isReady = false;
    public url_backend_server = '';
    public url_smi_backend_server = '';
    public countly_url = '';
    public countly_app_key = '';
    public publicConfig: TPublicConfig = {
        passwordMinLength: 0,
        passwordMaxLength: 0,
        passwordPattern: '',
    };
    public ready = false;

    public readonly api = {
        getBaseUrl: () => this.url_backend_server,
        getSmiBaseUrl: () => this.url_smi_backend_server,
    };

    public readonly formats = {
        time: 'DD-MM-YYYY HH:mm',
        timeWithSeconds: 'DD-MM-YYYY HH:mm:ss',
        onlyDate: 'DD-MM-YYYY',
        onlyTime: 'HH:mm',
    };

    public readonly article = {
        maxCoverFileSizeBytes: 4194304,
    };

    init(content: Record<string, string>) {
        this.isReady = true;
        this.url_backend_server = content.url_backend_server || '';
        this.url_smi_backend_server = content.url_smi_backend_server || '';
        this.countly_url = content.countly_url || '';
        this.countly_app_key = content.countly_app_key || '';
    }

    setPublicConfig(publicConfig: TPublicConfig) {
        this.publicConfig = {
            passwordMinLength: publicConfig.passwordMinLength,
            passwordMaxLength: publicConfig.passwordMaxLength,
            passwordPattern: new RegExp(publicConfig.passwordPattern),
        };
        this.ready = true;
    }
}

export const config = new Config();

export const usePublicConfig = () => {
    const isMounted = useRef(false);
    const [, setRefresh] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        if (!config.ready) {
            authApi
                .getPublicConfig()
                .then(({ data }) => {
                    config.setPublicConfig(data);
                    if (isMounted.current) setRefresh(true);
                })
                .catch();
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    return { ready: config.ready, publicConfig: config.publicConfig };
};
