import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const List: React.FC = observer(() => {
    const storeName = 'user';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.users;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    itemName="Пользователь"
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                />
            ),
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'email',
            text: 'Почта',
            sort: true,
        },
        //hide-fio
        // {
        //     dataField: 'fio',
        //     text: 'ФИО',
        //     sort: true,
        // },
        {
            dataField: 'role',
            text: 'Роль',
            sort: true,
        },
        {
            dataField: 'commonName',
            text: 'Псевдоним',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Статус',
            sort: true,
        },
        {
            formatter: (date) => formatDate(date, config.formats.time),
            dataField: 'create',
            text: 'Дата создания',
            sort: true,
            headerStyle: {
                width: '17em',
            },
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: {
            title: 'Добавить пользователя',
            path: '/admin/users/create',
        },
    };

    useResetListIfGoOut(storeName, 'users');

    return (
        <ItemsListTable
            filters={[
                {
                    type: 'text',
                    name: 'textFilter',
                    filterTitle: 'Поиск по тексту',
                    dropdownItemTitle: 'Поиск по тексту',
                },
            ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'fio',
                sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Список пользователей" {...creatingProp} />
        </ItemsListTable>
    );
});

export default List;
