import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const ReportList: React.FC = observer(() => {
    const storeName = 'report';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.quizes;

    const isActionButtonsHidden = userAccess?.read === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    itemName="Отчет"
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.read === true}
                    isDelete={false}
                />
            ),
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '7em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '25em',
                maxWidth: '25em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    useResetListIfGoOut(storeName, 'reports');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'id',
                sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Список отчетов" />
        </ItemsListTable>
    );
});

export default ReportList;
