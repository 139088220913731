import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import * as appConst from 'app.const';
import { stat } from 'lib/stat';
import { ReactComponent as ReactSvg } from 'assets/svg/arrows/arrow-circle-left-solid.svg';
import FormTextInput from 'components/inputs/FormTextInput';
import { authApi } from 'api';
import LoaderClassic from 'components/LoaderClassic';
import LoginBackground from './LoginBackground';
import LoginButton from './LoginButton';

//статус исполнения запроса
const REQUEST_STATUS_EMPTY = 0; //запроса не было
const REQUEST_STATUS_DONE = 1; //запрос выполнен
const REQUEST_STATUS_ERROR = 2; //ошибка

const StartResetPassword: React.FC = () => {
    const navigate = useNavigate();
    //данные запроса на восстановление пароля
    const [content, setContent] = useState({ status: REQUEST_STATUS_EMPTY, message: '' });
    //состояние выполнения
    const [running, setRunning] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useRef(false);
    //данные формы ввода
    const initialValues = {
        email: '',
    };

    //*************************************************************************************************************
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    //*************************************************************************************************************
    //отправить запрос на восстановление
    const requestStartResetPasswod = (email: string) => {
        setRunning(true);
        authApi
            .startResetPassword({ email })
            .then(() => {
                if (isMounted.current) setContent({ status: REQUEST_STATUS_DONE, message: '' });
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    //уведомление об ошибке сервера будет автоматически отображено через ближайший компонент <Notification /> (notifyStore.showNotify)
                    //а здесь просто сохраним инфо
                    setContent({
                        status: REQUEST_STATUS_ERROR,
                        message: error.message || 'ошибка сервера',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setRunning(false);
            });
    };

    //*************************************************************************************************************
    //Завершить работу, т.к. теперь надо открыть email и перейти по ссылке
    const handleDone = () => {
        navigate('/auth/login', { replace: true });
    };

    //*************************************************************************************************************
    //Используется для проверки отдельных свойств формы
    const validationSchema = yup.object().shape({
        email: yup.string().email('Некорректный формат почты').required(`Поле не должно быть пустым`),
    });

    const { dirty, values, handleChange, handleSubmit, errors } = useFormik({
        initialValues,
        validationSchema,
        //validateOnMount: false,
        //validateOnBlur: true, - default
        //validateOnChange: true, - default
        // eslint-disable-next-line
        onSubmit: (data: any) => {
            if (dirty) {
                //событие
                stat.addEvent({ name: 'password_forget', value: { email: data.email } });
                //запрос
                requestStartResetPasswod(data.email);
            }
        },
    });

    return (
        <LoginBackground>
            <ReactSvg
                height={24}
                fill={appConst.backgroundPrimaryColor}
                onClick={() => {
                    navigate(-1);
                }}
                cursor="pointer"
            />
            <div className="h2 text-left mt-2 mb-1">Восстановление доступа</div>

            {/* запрос еще не отправлен или ошибка */}
            {content.status === REQUEST_STATUS_EMPTY || content.status === REQUEST_STATUS_ERROR ? (
                <>
                    <div className="text-muted pr-5 mb-3">
                        <small className="text-dark">Мы отправим инструкцию по восстановлению доступа на e-mail</small>
                    </div>
                    <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
                        <Row>
                            <Col className="mb-3">
                                <FormTextInput
                                    placeholder="E-mail"
                                    name="email"
                                    value={values.email}
                                    invalidText={errors.email}
                                    handleChange={handleChange}
                                />
                            </Col>
                        </Row>

                        {/* кнопка */}
                        <LoginButton isDisabled={running} actionName="ОТПРАВИТЬ" type="submit" onClick={handleSubmit} />
                    </Form>
                </>
            ) : null}
            {/* запрос выполнен */}
            {content.status === REQUEST_STATUS_DONE ? (
                <>
                    <div className="text-muted mb-3 pr-5">
                        <small className="text-dark">
                            Инструкция по восстановлению доступа в течение 5 минут придет вам на почту
                        </small>
                    </div>

                    {/* выравнивание по высоте */}
                    <div className="mt-6" />
                    {/* кнопка */}
                    <LoginButton onClick={handleDone} actionName="ПРОДОЛЖИТЬ" type="button" />
                </>
            ) : null}
            {/* индикатор выполнения */}
            {running && (
                <Row className="mt-5 d-flex justify-content-center">
                    <LoaderClassic />
                </Row>
            )}
        </LoginBackground>
    );
};

export default StartResetPassword;
