import React from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import { serviceApi } from 'api';
import Loader from 'components/Loader';
import LoaderClassic from 'components/LoaderClassic';
import ErrorWarning from 'components/errors/ErrorWarning';
import { useOperList, useOperExec, TResult } from './queries';

//*************************************************************************************************************
//Чтение PID (тест кластера)
const getPID = async (): Promise<TResult> => {
    const pidObject: { [key: string]: number } = {};
    const promiseArray = [];

    for (let i = 0; i < 1000; ++i) {
        promiseArray.push(
            serviceApi.getPID(i).then(({ data }) => {
                const { pid } = data;
                pidObject[pid] = pidObject[pid] ? pidObject[pid] + 1 : 1;

                return i;
            })
        );
    }
    await Promise.all(promiseArray);

    return {
        data: {
            result: JSON.stringify(pidObject),
        },
    };
};

//*************************************************************************************************************
//Список операций
const ServiceList: React.FC = () => {
    //чтение списка операций
    const operList = useOperList();
    //выполнение операции
    const operExec = useOperExec(getPID);

    if (operList.isLoading) return <Loader />;
    if (operList.isError) return <ErrorWarning error={operList.error as Error} />;

    //Заполнить массив операций. Первый элемент = операция getPID()
    const aOperations = [{ endpoint: 'getPID', name: 'Тест кластера' }, ...(operList.data || [])];

    //сформировать визуальный список операций
    const v: JSX.Element[] = aOperations.map((item) => (
        <Row key={item.endpoint} className="mt-3">
            <Button
                className="btn-icon"
                color="primary"
                outline={true}
                type="button"
                onClick={async () => operExec.mutate(item.endpoint)}
            >
                <span className="h3">{`${item.name}`}</span>
            </Button>
        </Row>
    ));
    if (v.length === 0) v.push(<span>пустой список операций</span>);

    return (
        <React.Fragment>
            <AdminViewHeader title="Выполнение сервисных операций" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {/* сервисные операции */}
                                {v}

                                {/* результат выполнения */}
                                {operExec.isSuccess && operExec.data.data.result && (
                                    <>
                                        <Col className="mt-3">Результат:</Col>
                                        <Col className="mt-1">
                                            <pre className="pre-scrollable">
                                                {JSON.stringify(operExec.data.data.result, null, 4)}
                                            </pre>
                                        </Col>
                                    </>
                                )}

                                {operExec.isLoading && (
                                    <Row className="mt-5 d-flex justify-content-center">
                                        <LoaderClassic />
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ServiceList;
