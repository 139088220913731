import React from 'react';
import { FormGroup } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { FormikErrors } from 'formik/dist/types';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import classnames from 'classnames';

import InvalidLabel from 'components/labels/InvalidLabel';
import 'assets/css/date-select.css';
import { config } from 'lib/config';

interface IDateSelect {
    name: string;
    placeholder?: string;
    dateFormat?: string;
    timeFormat?: string | boolean;
    label?: string;
    invalidText?: string;
    initialValue?: Date | string | Moment;
    setFieldValue?: (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    disabled?: boolean;
}

const DateSelect: React.FC<IDateSelect> = ({
    name,
    placeholder = 'Укажите дату',
    label,
    invalidText,
    initialValue,
    setFieldValue,
    dateFormat = config.formats.onlyDate,
    timeFormat = config.formats.onlyTime,
    disabled = false,
}) => {
    const handleChange = (e: Moment | string) => {
        if (setFieldValue) {
            setFieldValue(name, moment(e).toISOString());
        }
    };

    let pattern = config.formats.time;
    if (!timeFormat) pattern = config.formats.onlyDate;
    const initialDate = initialValue ? moment(initialValue).format(pattern) : undefined;

    return (
        <FormGroup className="mb-1">
            {label && <label className="form-control-label">{label}</label>}
            <ReactDatetime
                initialValue={initialDate}
                className={classnames({
                    'invalid-border': Boolean(invalidText),
                })}
                onChange={handleChange}
                locale="ru"
                closeOnSelect={true}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                closeOnClickOutside={true}
                inputProps={{
                    placeholder,
                    disabled,
                }}
            />
            {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
        </FormGroup>
    );
};

export default DateSelect;
