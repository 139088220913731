import { makeAutoObservable } from 'mobx';

import { tgChannelApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface ITgChannelInList {
    id: number;
    title: string;
    isTest: boolean;
}

class ChannelStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<{ id: number }, {}, {}>(tgChannelApi);

    readonly list = new ListStore<ITgChannelInList>(tgChannelApi);
}

export default new ChannelStore();
