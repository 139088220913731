import { useQuery, useMutation, useQueryClient } from 'react-query';

import { fcmApi as itemApi } from 'api';
import { IItemContent, IItem } from './types';

//ключ кеша
const queryKey = 'fcmContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    return (await itemApi.getOne(id)).data;
}
export function useRqItemRead(fcmId: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(fcmId),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    return (await itemApi.updateOne(id, content)).data;
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    return (await itemApi.createOne(content)).data;
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
