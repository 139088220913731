import React, { useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router-dom';

import articleStore from 'store/article';
import MobileBlockForm from './MobileBlockForm';
import PortalBlockForm from './PortalBlockForm';
import TelegramBlockForm from './TelegramBlockForm';

interface IArticleEditForm {
    item: typeof articleStore.item;
    mode: 'create' | 'edit';
    activeTab: number;
    setUnsavedTab: (tabId: number, isSaved: boolean) => void;
    setSavingFunction: (func: () => Promise<boolean>) => void;
}

const ArticleEditForm: React.FC<IArticleEditForm> = observer(
    ({ item, mode, activeTab, setUnsavedTab, setSavingFunction }) => {
        const plainItem = toJS(item);

        //*************************************************************************************************************
        //после создания переход в список
        const navigate = useNavigate();
        useEffect(() => {
            if (mode !== 'edit' && plainItem.isCreated) {
                navigate(`/admin/articles/${plainItem.data?.id}/edit?tabId=${activeTab}`);
            }

            return () => {
                if (mode !== 'edit') item.resetState();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [plainItem.isCreated, mode]);

        return (
            <React.Fragment>
                <TabContent activeTab={`tabs${activeTab}`}>
                    {activeTab === 1 && ( //страница Статья
                        <TabPane tabId={`tabs${activeTab}`}>
                            <MobileBlockForm
                                item={item}
                                mode={mode}
                                setUnsavedTab={(isSaved: boolean) => setUnsavedTab(activeTab, isSaved)}
                                setSavingFunction={setSavingFunction}
                            />
                        </TabPane>
                    )}

                    {activeTab === 2 && ( //страница Портал
                        <TabPane tabId={`tabs${activeTab}`}>
                            <PortalBlockForm
                                item={item}
                                mode={mode}
                                setUnsavedTab={(isSaved: boolean) => setUnsavedTab(activeTab, isSaved)}
                                setSavingFunction={setSavingFunction}
                            />
                        </TabPane>
                    )}

                    {activeTab === 3 && ( //страница Телеграм
                        <TabPane tabId={`tabs${activeTab}`}>
                            <TelegramBlockForm
                                item={item}
                                mode={mode}
                                setUnsavedTab={(isSaved: boolean) => setUnsavedTab(activeTab, isSaved)}
                                setSavingFunction={setSavingFunction}
                            />
                        </TabPane>
                    )}
                </TabContent>
            </React.Fragment>
        );
    }
);

export default ArticleEditForm;
