import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import StationEditForm from 'views/stations/Components/Form';
import useItemEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import { useStore } from 'store';

const StationEdit: React.FC = observer(() => {
    const { item, id } = useItemEditHooks({ storeName: 'station' });
    useResetListIfGoOut('station', 'stations');

    if (item.isLoading) return <Loader />;
    if (isUndefined(item.data)) return null;

    //читать право на редактирование внешнего id
    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.settings;
    const isAllowEditExternalId = userAccess?.read === true;

    return (
        <React.Fragment>
            <AdminViewHeader title="Редактировать филиал" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Филиал ID: {id}</h3>
                            </CardHeader>
                            <CardBody>
                                <StationEditForm
                                    item={item}
                                    mode="edit"
                                    isAllowEditExternalId={isAllowEditExternalId}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default StationEdit;
