import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import useItemEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

//*************************************************************************************************************
//Просмотр события
const LoggerEdit: React.FC = observer(() => {
    const { item, id } = useItemEditHooks({ storeName: 'logger' });
    useResetListIfGoOut('logger', 'logger');

    if (item.isLoading) return <Loader />;
    if (isUndefined(item.data)) return null;

    const plainItem = toJS(item);

    let parameters = '';
    if (plainItem.data?.parameters) {
        try {
            parameters = JSON.stringify(JSON.parse(plainItem.data.parameters), null, '\t');
        } catch {
            parameters = plainItem.data?.parameters;
        }
    }

    return (
        <React.Fragment>
            <AdminViewHeader title="Просмотр события" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Событие ID: {id}</h3>
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Контекст:</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="h4">{plainItem.data?.context}</span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Сообщение:</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="h4">{plainItem.data?.message}</span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Instance:</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="h4">{plainItem.data?.instance}</span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Уровень:</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span
                                            className={`h4 ${
                                                plainItem.data?.level === 'error' ? 'text-danger' : 'text-dark'
                                            }`}
                                        >
                                            {plainItem.data?.level}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Время:</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="h4">
                                            {plainItem.data?.createdAt &&
                                                formatDate(plainItem.data?.createdAt, config.formats.timeWithSeconds)}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-auto">
                                        <span className="h4 form-control-label text-primary">Parameters:</span>
                                    </Col>
                                    <Col className="col">
                                        <pre className="h4 pre-scrollable">{parameters}</pre>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default LoggerEdit;
