import React from 'react';
import { Button } from 'reactstrap';

interface ICounter {
    value: number;
    onPlus: () => void;
    onMinus: () => void;
    disabled?: boolean;
}

export const Counter = ({ value, onPlus, onMinus, disabled = false }: ICounter) => {
    return (
        <>
            <div className="d-flex border rounded-pill justify-content-center align-items-center">
                <Button
                    title="Уменьшить"
                    className="btn-icon rounded-pill mt-1 mb-1 ml-2 mr-3"
                    outline={true}
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={onMinus}
                    disabled={disabled}
                >
                    <span className="btn-inner--icon">
                        <i className="fas fa-minus" />
                    </span>
                </Button>
                <span className="h3 d-inline-block mt-1 mb-auto">{`${value}`}</span>
                <Button
                    title="Увеличить"
                    className="btn-icon rounded-pill mt-1 mb-1 ml-3 mr-2"
                    outline={true}
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={onPlus}
                    disabled={disabled}
                >
                    <span className="btn-inner--icon">
                        <i className="fas fa-plus" />
                    </span>
                </Button>
            </div>
        </>
    );
};
