import React from 'react';
import { Button, Spinner, Col, Row } from 'reactstrap';

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    buttonType?: 'button' | 'submit';
    color?: string;
    title: string;
    size?: string;
    outline?: boolean;
}

const SpinnerButton: React.FC<IProps> = ({
    onClick,
    disabled = false,
    isLoading = false,
    buttonType = 'button',
    color = 'primary',
    title,
    size = 'sm',
    outline = false,
}) => {
    const [height, setHeight] = React.useState(0);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onClick) onClick();
    };

    //динамически измерить высоту span, включающего spinner,
    //и установить высоту этого spinner
    const measuredRef = React.useCallback((node) => {
        if (node !== null) {
            const v = node.getBoundingClientRect().height;
            setHeight(v);
        }
    }, []);

    return (
        <Button
            className="my-0"
            {...(buttonType !== 'submit' && { onClick: handleClick })}
            disabled={disabled}
            size={size}
            color={color}
            type={buttonType}
            outline={outline}
        >
            <Row className="mx-0 px-0">
                <Col className="col px-0" style={{ width: '2.0rem' }}>
                    {isLoading && (
                        <span className="btn-inner--icon">
                            <Spinner style={{ width: `${height}px`, height: `${height}px` }} />
                        </span>
                    )}
                </Col>
                <Col className="col-auto px-2">
                    <span ref={measuredRef}>{title}</span>
                </Col>
                <Col className="col px-0" />
            </Row>
        </Button>
    );
};

export default SpinnerButton;
