import React from 'react';
import { Col, Form, Row, Button, Card, CardBody } from 'reactstrap';
import { useForm, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';

import { stat } from 'lib/stat';
import { useIsMounted } from 'lib/hooks';
import { getMonthList, getYearList, getLocalDateInfo } from 'lib/datetime';
import { eventApi } from 'api';
import SpinnerButton from 'components/buttons/SpinnerButton';
import ReactDropzone from 'components/inputs.uncontrolled/ReactDropzoneRhf';
import StringSelect from 'components/inputs.uncontrolled/dropdown-selects/StringSelectRhf';
import ChoicesSelect from 'components/inputs.uncontrolled/dropdown-selects/ChoicesSelectFromRemoteRhf';
import FormCheckBox from 'components/inputs.uncontrolled/FormCheckBoxRhf';
import InvalidLabel from 'components/labels/InvalidLabel';
import { IEditorData, IParamsContent } from '../types';

const monthList = getMonthList();
const yearList = getYearList(3);

const validationSchema = yup.object({
    cover: yup.string().optional(),
    themes: yup.array().min(1, 'Нет привязки к темам').required(`Нет привязки к темам`),
});

interface IProps {
    setTabState: (id: number, mode: boolean) => void;
    setTab: (id: number) => void;
    setEditorData: (v: IEditorData) => void;
    getDefaultParams: () => IParamsContent;
    setDefaultParams: (params: IParamsContent) => void;
}
const Params: React.FC<IProps> = ({ setTabState, setTab, setEditorData, getDefaultParams, setDefaultParams }) => {
    //ключи для контролов ввода ReactDropzone, используются для принудительной перерисовки контрола
    const keyReactDropzone = React.useRef(uuid());
    //состояние выполнения
    const [isLoading, setIsLoading] = React.useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        setValue,
        trigger,
        getValues,
    } = useForm<IParamsContent>({ defaultValues: getDefaultParams(), resolver: yupResolver(validationSchema) });

    const onSubmit = async (values: IParamsContent) => {
        setIsLoading(true);
        setDefaultParams(values); //сохранить текущие данные как defaults
        setTabState(2, true);
        const month = monthList.findIndex((item) => item === values.month);
        const year = Number(values.year);
        const timezone = getLocalDateInfo().timezone;
        const { themes, cover, coverId, showImages } = values;

        //сбор статистики
        try {
            // получить полное описание по массиву id
            const themesList = (await eventApi.getSelect({ defaultSelects: themes })).data.result
                .filter((item) => item.selected)
                .map((item) => ({ id: item.value, title: item.label }));
            stat.addEvent({ name: 'event_html', value: { themes: themesList } });
        } catch {
            // eslint-disable-next-line
        }

        Promise.all([eventApi.html({ month, year, timezone, cover, coverId, themes, showImages }), eventApi.pattern()])
            .then(async (data) => {
                //если сервер возвращает объект Buffer, то data.data содержит готовый Blob
                //axios ... { responseType: 'blob' }
                //если возвращается файл, то надо создавать Blob:
                //const blob = new Blob([result.data], { type: "text/plain" });
                if (isMounted.current) {
                    const { pattern, style, fonts } = data[1].data;
                    setEditorData({
                        ...{ content: await (data[0].data as Blob).text() },
                        pattern,
                        style,
                        fonts,
                    });
                    setTab(2);
                }
            })
            // eslint-disable-next-line
            .catch(() => {})
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    //*************************************************************************************************************
    //сформировать отчет
    const handleSave = async () => {
        const v = await trigger();
        if (!v) return;
        handleSubmit(onSubmit)();
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h6 className="h2 mb-3">Подготовить календарь в html формате</h6>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Form>
                                {/* если показывать обложку */}
                                <Row className="justify-content-start">
                                    <Col xs="11" md="7" className="mb-3">
                                        <ReactDropzone
                                            // key используются для принудительной перерисовки контрола, иначе не рендерится компонент при изменении значения через setValue
                                            key={`ReactDropzone-${keyReactDropzone.current}`}
                                            buttonText="Загрузите лого здесь"
                                            setFileName={(name) => setValue('cover', name, { shouldDirty: true })}
                                            setFileId={(fileId) => setValue('coverId', fileId, { shouldDirty: true })}
                                            label="Лого"
                                            preloadedImageUrl={getValues('cover')}
                                            invalidText={errors.cover?.message}
                                            clearValueOnError={false}
                                            height={20}
                                        />
                                    </Col>
                                    {/* удалить обложку */}
                                    <Col
                                        className="d-flex flex-column justify-content-center align-items-start mb-3"
                                        xs="1"
                                        md="5"
                                    >
                                        <Button
                                            className="btn-icon"
                                            color="danger"
                                            size="sm"
                                            outline={true}
                                            type="button"
                                            onClick={() => {
                                                setValue('cover', '', {
                                                    shouldDirty: true,
                                                    shouldTouch: true,
                                                    shouldValidate: true,
                                                });
                                                setValue('coverId', 0, {
                                                    shouldDirty: true,
                                                    shouldTouch: true,
                                                    shouldValidate: true,
                                                });
                                                keyReactDropzone.current = uuid(); //принудительно перерисовать контрол
                                            }}
                                        >
                                            <span className="btn-inner--icon">
                                                <i className="fas fa-times" />
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>

                                {/* месяц */}
                                <Row>
                                    <Col className="mb-3" md="3">
                                        <StringSelect name="month" control={control} label="Месяц" list={monthList} />
                                    </Col>
                                </Row>

                                {/* год */}
                                <Row>
                                    <Col className="mb-3" md="3">
                                        <StringSelect name="year" control={control} label="Год" list={yearList} />
                                    </Col>
                                </Row>

                                {/* Выбор тем */}
                                <Row>
                                    <Col className="mb-3" md="6">
                                        <ChoicesSelect
                                            name="themes"
                                            control={control}
                                            maxItemCount={50}
                                            getSelect={eventApi.getSelect}
                                            placeholder="Начните вводить название темы"
                                            label="Темы"
                                            shouldSort={false}
                                        />
                                    </Col>
                                </Row>
                                {(errors.themes as unknown as FieldError) && (
                                    <Row>
                                        <Col>
                                            <InvalidLabel>
                                                {(errors.themes as unknown as FieldError)?.message}
                                            </InvalidLabel>
                                        </Col>
                                    </Row>
                                )}

                                {/* Выводить изображения */}
                                <Row>
                                    <Col>
                                        <FormCheckBox
                                            register={register}
                                            name="showImages"
                                            title="Выводить изображения"
                                        />
                                    </Col>
                                </Row>
                            </Form>

                            <div className="text-center mt-5">
                                <SpinnerButton
                                    onClick={handleSave}
                                    isLoading={isLoading}
                                    title="Подготовить"
                                    size="md"
                                    outline={true}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Params;
