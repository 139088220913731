import React from 'react';

import { Button, Container, Row, Col } from 'reactstrap';
import { isUndefined } from 'lodash';

export type TAction = { name: string; handle: () => void };

interface IItemListHeaderEx {
    title: string;
    buttons?: TAction[];
}

const ItemListHeaderEx: React.FC<IItemListHeaderEx> = ({ title, buttons }) => {
    //сформировать список операций
    let buttonList: JSX.Element[] | null = null;
    if (!isUndefined(buttons) && buttons.length > 0)
        buttonList = buttons.map((item) => (
            <Button key={`button-${item.name}`} className="btn-primary" color="" onClick={item.handle} size="sm">
                {item.name}
            </Button>
        ));

    return (
        <React.Fragment>
            <div className="header pb-6">
                <Container fluid={true}>
                    <div className="header-body">
                        <Row className="align-items-end py-4">
                            <Col className="me-auto">
                                <h6 className="h2 mb-0">{title}</h6>
                            </Col>
                            <Col className="d-flex flex-row col-auto">{buttonList}</Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ItemListHeaderEx;
