import React, { useRef } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useStore } from 'store';

const Notification: React.FC = () => {
    const notifyRef = useRef<NotificationAlert>(null);
    const notifyStore = useStore('notification');

    if (notifyRef.current !== null) {
        notifyStore.setNotificationRef(notifyRef.current);
    }

    return (
        <div className="rna-wrapper">
            <NotificationAlert ref={notifyRef} />
        </div>
    );
};

export default Notification;
