import React, { useRef } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { observer } from 'mobx-react-lite';

import { sleep } from 'lib/tools';
import { useStore } from 'store';
import AdminViewHeader from 'components/headers/AdminViewHeader';
import ArticleEditForm from 'views/articles/Components/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Tabs from 'components/navs/Tabs';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useTabs, useUnsavedTabs, tabList } from '../tabs';

const ItemEdit: React.FC = observer(() => {
    const { item } = useStore('article');
    useResetListIfGoOut('article', 'articles');
    const { activeTab, setTab } = useTabs(1);
    const { setTabWithCheck, setUnsavedTab, changeTabRequest, setChangeTabRequest } = useUnsavedTabs(activeTab, setTab);

    const savingFunction = useRef<() => Promise<boolean>>(async () => false);
    const setSavingFunction = useRef((f: () => Promise<boolean>) => {
        savingFunction.current = f;
    }).current;

    return (
        <React.Fragment>
            <AdminViewHeader title="Создать статью" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="pb-0">
                                <Row>
                                    <Col sm={4} className="mb-3">
                                        <h3 className="mb-0">Новая статья</h3>
                                    </Col>
                                </Row>
                                <Tabs
                                    tabs={tabList}
                                    activeTab={activeTab}
                                    setTab={(tabId: number) => setTabWithCheck(true, tabId)}
                                />
                            </CardHeader>
                            <CardBody>
                                <ArticleEditForm
                                    item={item}
                                    mode="create"
                                    activeTab={activeTab}
                                    setUnsavedTab={setUnsavedTab}
                                    setSavingFunction={setSavingFunction}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* модальное окно запроса на смену закладки */}
                {changeTabRequest && (
                    <ConfirmationModal
                        isOpen={changeTabRequest}
                        toggleOpen={setChangeTabRequest}
                        title="Переход на другую закладку"
                        text="Остались несохраненные данные. Возможно, их следует сохранить перед переходом?"
                        buttonTitle="Сохранить и перейти"
                        callback={async () => {
                            let success = true;
                            if (savingFunction.current) success = await savingFunction.current();
                            await sleep(0);
                            if (success) setTabWithCheck(false);
                        }}
                        buttonTitle2="Не сохранять и перейти"
                        callback2={() => setTabWithCheck(false)}
                        modalSize="modal-lg"
                    />
                )}
            </Container>
        </React.Fragment>
    );
});

export default ItemEdit;
