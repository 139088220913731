import React from 'react';
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';

import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { getFcmStatus, FcmType, FcmStatus, getFcmType } from 'types';
import { TFcmError, TFcmMessage, IItem } from '../types';
import { useRqFcmStatus } from './queries';

//*************************************************************************************************************
//Заголовок
interface IFcmHeaderView {
    type?: FcmType;
    title?: string;
    text?: string;
    sourceId?: number;
    sourceName?: string;
}
const FcmHeaderView: React.FC<IFcmHeaderView> = ({ type, title, text, sourceId, sourceName }) => {
    return (
        <Card className="mb-3">
            <CardHeader className="py-2">
                <span className="h4">Описание</span>
            </CardHeader>
            <CardBody className="py-2">
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Заголовок:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{title || ''}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Содержание:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{text || ''}</span>
                    </Col>
                </Row>
                {type && type !== FcmType.SIMPLE && (
                    <Row>
                        <Col className="col-auto">
                            <span className="h4 form-control-label">
                                {`${getFcmType(type)} (ID ${sourceId || ''}):`}
                            </span>
                        </Col>
                        <Col className="col-auto">
                            <span className="h4">{sourceName || ''}</span>
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Статус
interface IFcmStatusView {
    status?: FcmStatus;
    statusAt?: Date;
}
const FcmStatusView: React.FC<IFcmStatusView> = ({ status, statusAt }) => {
    let color = 'text-info';
    if (status === FcmStatus.DONE) color = 'text-success';
    else if (status === FcmStatus.ERROR) color = 'text-danger';

    return (
        <Card className="mb-3">
            <CardHeader className="py-2">
                <span className="h4">Состояние обработки</span>
            </CardHeader>
            <CardBody className="py-2">
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Статус:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className={`h4 ${color}`}>{status && getFcmStatus(status)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Время:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{statusAt && formatDate(statusAt, config.formats.time)}</span>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Ошибка отправки
interface IFcmErrorView {
    item: TFcmError;
}
const FcmErrorView: React.FC<IFcmErrorView> = ({ item }) => {
    return (
        <Card className="mb-2">
            <CardBody className="py-2">
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Код:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{item.code}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Описание:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{item.message}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Время:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{formatDate(item.createdAt, config.formats.time)}</span>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Список ошибок
interface IFcmErrorListView {
    list?: TFcmError[];
}
const FcmErrorListView: React.FC<IFcmErrorListView> = ({ list }) => {
    if (!list || list.length === 0) return null;

    return (
        <Card className="mb-3">
            <CardHeader className="py-2">
                <span className="h4 text-danger">Ошибки отправки</span>
            </CardHeader>
            <CardBody className="py-2">
                {list.map((item) => (
                    <FcmErrorView key={`FcmErrorView ${item.id}`} item={item} />
                ))}
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Отправленное сообщение
interface IFcmMessageView {
    item: TFcmMessage;
}
const FcmMessageView: React.FC<IFcmMessageView> = ({ item }) => {
    return (
        <Card className="mb-2">
            <CardBody className="py-2">
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">id сообщения:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{item.fcmMessageId}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-auto">
                        <span className="h4 form-control-label text-primary">Время:</span>
                    </Col>
                    <Col className="col-auto">
                        <span className="h4">{formatDate(item.createdAt, config.formats.time)}</span>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Список сообщений
interface IFcmMessageListView {
    list?: TFcmMessage[];
}
const FcmMessageListView: React.FC<IFcmMessageListView> = ({ list }) => {
    if (!list || list.length === 0) return null;

    return (
        <Card className="mb-3">
            <CardHeader className="py-2">
                <span className="h4">Отправленные сообщения</span>
            </CardHeader>
            <CardBody className="py-2">
                {list.map((item) => (
                    <FcmMessageView key={`FcmMessageView ${item.id}`} item={item} />
                ))}
            </CardBody>
        </Card>
    );
};

//*************************************************************************************************************
//Просмотр уведомления
interface IItemViewFormProps {
    content: IItem;
}
const ItemViewForm: React.FC<IItemViewFormProps> = ({ content }) => {
    //кеш описания уведомления
    const rqFcmStatus = useRqFcmStatus(content.id || 0);

    return (
        <React.Fragment>
            <FcmHeaderView {...rqFcmStatus.data} />
            <FcmStatusView status={rqFcmStatus.data?.status} statusAt={rqFcmStatus.data?.statusAt} />
            <FcmMessageListView list={rqFcmStatus.data?.messages} />
            <FcmErrorListView list={rqFcmStatus.data?.errors} />
        </React.Fragment>
    );
};

export default ItemViewForm;
