import React, { useState } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import QuizEditForm from 'views/quizes/Components/Form';
import Loader from 'components/Loader';
import useEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Tabs from 'components/navs/Tabs';
import { tabArray } from '../Components/Const';

const ItemEdit: React.FC = observer(() => {
    const storeName = 'quiz';
    const { item, id, itemStore } = useEditHooks({ storeName });
    useResetListIfGoOut('quiz', 'quizes');

    const [tabs, setTabs] = useState({ activeTab: itemStore.indexLastTab, tabArray });

    if (item.isLoading) return <Loader />;
    if (isUndefined(item.data)) return null;

    return (
        <React.Fragment>
            <AdminViewHeader title={`Редактировать викторину ID:${id}`} />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="pb-0">
                                <Tabs
                                    tabs={tabArray}
                                    activeTab={tabs.activeTab}
                                    setTab={(num) => setTabs((prev) => ({ ...prev, activeTab: num }))}
                                />
                            </CardHeader>
                            <CardBody>
                                <QuizEditForm tabs={tabs} setTabs={setTabs} mode="edit" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default ItemEdit;
