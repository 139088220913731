import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
    border-radius: 4px 4px 0 0 !important;
`;

interface ITabs {
    activeTab: number;
    setTab: (tab: number) => void;
    tabs: Array<{ tabId: number; href: string; title: string; disabled?: boolean }>;
}

const Tabs: React.FC<ITabs> = ({ tabs, activeTab, setTab }) => {
    return (
        <Nav
            className="nav nav-tabs nav-pills with-arrow flex-column flex-sm-row text-center round border-0"
            id="tabs-icons-text"
            pills={true}
            role="tablist"
        >
            {tabs.map((item) => (
                <NavItem key={item.tabId}>
                    <StyledNavLink
                        aria-selected={activeTab === item.tabId}
                        className={classnames('mb-sm-3 mb-md-0 text-uppercase font-weight-bold border-0 shadow-none', {
                            active: activeTab === item.tabId,
                        })}
                        onClick={() => setTab(item.tabId)}
                        href={item.href}
                        role="tab"
                        disabled={item.disabled}
                    >
                        {item.title}
                    </StyledNavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

export default Tabs;
