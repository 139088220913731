//*************************************************************************************************************
//Дополнить строку слева нулями до count символов.
export function padLeft(value: number, count: number) {
    const strValue = '0'.repeat(count) + value.toString();
    return strValue.substring(strValue.length - count);
}

//*************************************************************************************************************
//Заменить все вхождения шаблона stringToSearch в строке stringToModify на шаблон stringToReplace
export function replaceAll(stringToModify: string, stringToSearch: string, stringToReplace: string) {
    return stringToModify.split(stringToSearch).join(stringToReplace);
}
