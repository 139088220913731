import React from 'react';

import { PageButtonRendererOptions } from 'react-bootstrap-table-next';
import { isNumber } from 'lodash';
import styled from 'styled-components';

import PageSizeSelect from 'components/tables/ItemsListTable/PageSizeSelect';
import Pagination from 'components/tables/ItemsListTable/Pagination';
import { stores } from 'store';
import { ListItemsStores } from 'lib/types';
import paginationFactory, { PaginationCtxOptions } from 'react-bootstrap-table2-paginator';

const classnames = require('classnames');

interface IGetParams {
    <T extends ListItemsStores>(a: typeof stores[T]['list']): PaginationCtxOptions;
}

const PointerSpan = styled.span`
    cursor: pointer;
`;

const getPaginationOptions: IGetParams = (list) => {
    const CustomButton = ({ page, active, disabled, onPageChange }: PageButtonRendererOptions) => {
        const getPageNumber = (key: string) => {
            if (key === 'first') {
                return 1;
            }
            if (key === 'prev') {
                return list.queryParams.page - 1;
            }
            if (key === 'next') {
                return list.queryParams.page + 1;
            }
            if (key === 'last') {
                return Math.ceil(list.listSize / list.queryParams.sizePerPage);
            }

            return 1;
        };
        const customPage = page as unknown as { key: string };

        const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
            e.preventDefault();
            const targetPage = isNumber(page) ? page : getPageNumber(customPage.key);
            onPageChange(targetPage, targetPage);
        };

        const key = isNumber(page) ? page : customPage.key;

        return (
            <li key={key} className={classnames('page-item', { active }, { disabled })}>
                <PointerSpan aria-hidden="true" className="page-link" onClick={handleClick}>
                    {page}
                </PointerSpan>
            </li>
        );
    };

    return paginationFactory({
        sizePerPage: list.queryParams.sizePerPage,
        page: list.queryParams.page,
        totalSize: list.listSize,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        pageButtonRenderer: CustomButton,
        firstPageText: <span key="first" className="fas fa-angle-double-left" />,
        prePageText: <span key="prev" className="fas fa-angle-left" />,
        lastPageText: <span key="last" className="fas fa-angle-double-right" />,
        nextPageText: <span key="next" className="fas fa-angle-right" />,
        firstPageTitle: 'Первая страница',
        lastPageTitle: 'Последняя страница',
        nextPageTitle: 'Следующая страница',
        prePageTitle: 'Предыдущая страница',
        sizePerPageRenderer: ({ onSizePerPageChange }) => (
            <PageSizeSelect defaultValue={list.queryParams.sizePerPage} onSizePerPageChange={onSizePerPageChange} />
        ),
        paginationTotalRenderer: (from, to, size) => <Pagination to={to} from={from} size={size} />,
    });
};

export default getPaginationOptions;
