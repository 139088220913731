import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';

import FormTextInput from 'components/inputs/FormTextInput';
import supportStore, { SupportStatus } from 'store/support';
import getEditedValues from 'lib/getEditedValues';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import SupportStatusSelect from 'components/inputs/dropdown-selects/SupportStatusSelect';
import { supportApi } from 'api';

interface ISupportEditForm {
    store: typeof supportStore.item;
}

//const SupportEditForm: React.FC<ISupportEditForm> = ({ store }) => {
const SupportEditForm = ({ store }: ISupportEditForm) => {
    useEffect(() => {
        //для установки отметки о прочтении записи напрямую вызываем api,
        //чтобы избежать перерисовки компонента при возникновении ошибки (например, при отсутствии прав)
        const stubCheck = async () => {
            if (store.data?.id && store.data?.status === SupportStatus.NEW)
                try {
                    await supportApi.updateOne(store.data.id, {
                        status: SupportStatus.IN_PROCESS,
                    });
                    notifyStore.showNotify({
                        place: 'br',
                        message: `Обращение прочитано`,
                        type: 'success',
                    } as AlertOptions);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
        };
        stubCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = store.data ?? {
        name: '',
        email: '',
        tel: '',
        subject: '',
        text: '',
        responseText: '',
        status: 'ARCHIVED',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            const { status } = getEditedValues(initialValues, values);
            if (formik.dirty) {
                store.updateOne({ status });
            }
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput title="Имя" name="name" value={initialValues.name} disabled={true} />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput title="Почта" name="email" value={initialValues.email} disabled={true} />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput title="Телефон" name="tel" value={initialValues.tel} disabled={true} />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        title="Тема обращения"
                        name="subject"
                        value={initialValues.subject}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        type="textarea"
                        title="Текст обращения"
                        name="text"
                        value={initialValues.text}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <SupportStatusSelect
                        selectedValue={
                            formik.values.status === SupportStatus.NEW ? SupportStatus.IN_PROCESS : formik.values.status
                        }
                        label="Статус"
                        name="status"
                        setFieldValue={formik.setFieldValue}
                    />
                </Col>
            </Row>
            <FormBackOrSubmitButtons backPath="/admin/support" disabled={!formik.dirty} />
        </Form>
    );
};

export default SupportEditForm;
