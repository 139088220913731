import { useQuery, useMutation, useQueryClient } from 'react-query';
import { isUndefined } from 'lodash';

import { isoToFulltime, isoToLocal, toFulltimeStringDB } from 'lib/datetime';
import { eventApi as itemApi } from 'api';
import { IItemContent, IItem, EventRepeat } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertFromServer = (data: any): IItem => {
    const { repeat, dateBegin: isoDateBegin, dateEnd: isoDateEnd, themes, ...rest } = data;

    //особенность обработки fullTime признака
    //если fullTime=true, то в базе хранится дата в формате UTC: 2023-09-11T00:00:00.000Z
    //дата не зависит от таймзоны и равна конкретному указанному значению, в данном примере 2023-09-11
    //время при этом нулевое
    //если fullTime=false, то строка UTC преобразуется в локальную дату/время стандартным образом
    const dateBegin = rest.fullTime ? isoToFulltime(isoDateBegin) : isoToLocal(isoDateBegin);
    const dateEnd = rest.fullTime ? isoToFulltime(isoDateEnd) : isoToLocal(isoDateEnd);

    return {
        ...rest,
        //при чтении получаем массив id для контрола выбора тем
        themes: themes.map((item: { id: number }) => item.id),
        //полное описание списка тем будет использовано при подсчете статистики (readonly)
        themesList: themes.map(({ id, title }: { id: number; title: string }) => ({ id, title })), //для статистики
        ...(!isUndefined(repeat) && { repeat: repeat !== EventRepeat.EMPTY }),
        ...(!isUndefined(dateBegin) && { dateBegin }),
        ...(!isUndefined(dateEnd) && { dateEnd }),
    };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const convertToServer = (data: any): IItem => {
    //при отправке на сервер для тем используется только массив id, поэтому полное описание themesList вырезаем
    const { repeat, dateBegin, dateEnd, themesList, ...rest } = data;

    //особенность обработки fullTime признака
    //если fullTime=true, то на сервер должна уйти UTC строка с заданной датой и нулевым временем
    //дата в UTC строке не зависит от таймзоны и равна конкретному указанному значению
    //например, если задана дата 2023-09-11, то на сервер должна уйти строка 2023-09-11T00:00:00.000Z
    //независимо от локальной таймзоны
    //если fullTime=false, то локальная дата/время преобразуется в строку UTC стандартным образом
    return {
        ...rest,
        ...(!isUndefined(dateBegin) && {
            dateBegin: rest.fullTime ? toFulltimeStringDB(dateBegin) : dateBegin.toISOString(),
        }),
        ...(!isUndefined(dateEnd) && {
            dateEnd: rest.fullTime ? toFulltimeStringDB(dateEnd) : dateEnd.toISOString(),
        }),
        ...(!isUndefined(repeat) && { repeat: repeat ? EventRepeat.YEAR : EventRepeat.EMPTY }),
    };
};

//ключ кеша
const queryKeyEventContent = 'eventContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    return convertFromServer((await itemApi.getOne(id)).data);
}
export function useRqItemRead(eventId: number) {
    return useQuery({
        queryKey: [queryKeyEventContent],
        queryFn: () => itemRead(eventId),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    return convertFromServer((await itemApi.updateOne(id, convertToServer(content))).data);
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKeyEventContent], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    return convertFromServer((await itemApi.createOne(convertToServer(content))).data);
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKeyEventContent], data);
        },
    });

    return mutation;
};
