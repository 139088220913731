import React from 'react';
import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useLocation } from 'react-router-dom';

import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Header from 'components/headers/ItemListHeaderEx';
import { useStore } from 'store';

const LoggerList: React.FC = observer(() => {
    const storeName = 'logger';
    const { list: listStore } = useStore(storeName);

    //state приходит из router и содержит значение фильтра по полю contex
    const { state } = useLocation();
    listStore.queryParams.filters = state ? { contextFilter: state as string } : {};

    const columns: ColumnDescription[] = [
        {
            hidden: false,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons storeName={storeName} key={row.id} id={row.id} isEdit={true} isDelete={false} />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'context',
            text: 'Контекст',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '15em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'message',
            text: 'Сообщение',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '40em',
                maxWidth: '50em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'instance',
            text: 'Instance',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (instance) => (instance === null ? '' : String(instance)),
        },
        {
            dataField: 'level',
            text: 'Уровень',
            sort: true,
            headerStyle: {
                width: '4em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.timeWithSeconds)}</span>,
            dataField: 'createdAt',
            text: 'Время',
            sort: true,
            style: {
                width: '12em',
            },
        },
    ];

    useResetListIfGoOut(storeName, 'logger');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Протокол событий" />
        </ItemsListTable>
    );
});

export default LoggerList;
