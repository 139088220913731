import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';

interface IBackButton {
    backPath?: string;
    backTitle?: string;
}

const BackOrSubmit = ({ backPath, backTitle }: IBackButton) => {
    const location = useLocation();
    const navigate = useNavigate();

    const arrayPath = location.pathname.split('/');
    arrayPath.pop();
    const defaultBackPath = arrayPath.join('/');

    return (
        <Row>
            <Col className="d-flex justify-content-start">
                <Button
                    onClick={() => navigate(backPath || defaultBackPath, { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    {backTitle || 'Назад'}
                </Button>
            </Col>
        </Row>
    );
};

export default BackOrSubmit;
