import { makeAutoObservable } from 'mobx';

import { supportApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export enum SupportStatus {
    NEW = 'NEW',
    IN_PROCESS = 'IN_PROCESS',
    ARCHIVED = 'ARCHIVED',
}

export const SupportStatusTitle = {
    [SupportStatus.NEW]: 'Новый',
    [SupportStatus.IN_PROCESS]: 'В процессе',
    [SupportStatus.ARCHIVED]: 'В архиве',
};

export interface ISupportMessage {
    id: number;
    name: string;
    tel: string;
    email: string;
    subject: string;
    text: string;
    status: keyof typeof SupportStatus;
    responseText: string;
    responseDate: Date;
    create: Date;
    update: Date;
}

export interface ISupportMessageUpdate {
    status?: string;
    responseText?: string;
    responseDate?: Date;
}

class SupportStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<ISupportMessage, ISupportMessageUpdate, never>(supportApi);

    readonly list = new ListStore<ISupportMessage>(supportApi);
}

export default new SupportStore();
