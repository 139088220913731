import React, { useEffect, useState, useRef } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
// @ts-ignore
import ImageTool from '@editorjs/image';
// @ts-ignore
import QuoteTool from '@editorjs/quote';
import Header from '@editorjs/header';
// @ts-ignore
import RawTool from '@editorjs/raw';
// @ts-ignore
import EmbedTool from 'components/edit/HTMLEditor/tools/EmbedTool';
import { isUndefined } from 'lodash';

import { observer } from 'mobx-react-lite';

import { fileApi } from 'api';
import localization from 'components/edit/HTMLEditor/localization';
//import { useStore } from 'store';
import { FormikErrors } from 'formik/dist/types';
import InvalidLabel from 'components/labels/InvalidLabel';
import { BlockType } from './types';

interface IHTMLEditorProps {
    setFieldValue: (
        field: string,
        value: object[],
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    invalidText?: string;
    label?: string;
    initialValue?: OutputData['blocks'];
    setEditor?: (editor: EditorJS) => void;
    name: string;
    placeholder?: string;
    forceRefreshToken?: string; //если при вызове изменить значение, то принудительно создается новый редактор
    blockTypes?: BlockType[];
}

const HTMLEditor: React.FC<IHTMLEditorProps> = observer(
    ({ setFieldValue, invalidText, initialValue, label, name, placeholder, forceRefreshToken, blockTypes }) => {
        //const authStore = useStore('auth');
        const [editorJS, setEditor] = useState<EditorJS>();
        const editorActual = useRef(editorJS);
        //запомнить последнее значение editorJS
        useEffect(() => {
            editorActual.current = editorJS;
        }, [editorJS]);

        useEffect(() => {
            //освободить память от предыдущего редактора, если она еще не освобождена
            if (editorActual.current?.destroy) editorActual.current.destroy();

            //набор инструментов для нового редактора
            const tools = {
                ...((!blockTypes || blockTypes.includes(BlockType.Header)) && {
                    [BlockType.Header]: {
                        // @ts-ignore
                        class: Header,
                        inlineToolbar: ['link'],
                    },
                }),
                ...((!blockTypes || blockTypes.includes(BlockType.Image)) && {
                    [BlockType.Image]: {
                        class: ImageTool,
                        config: {
                            // endpoints: {
                            //     byFile: `${config.api.getBaseUrl()}/files/image`,
                            //     byUrl: `${config.api.getBaseUrl()}/files/by-url`,
                            // },
                            uploader: {
                                uploadByFile(file: File) {
                                    return fileApi.createImageFile(file).then((data) => data.data);
                                },
                            },
                            captionPlaceholder: 'Название изображения',
                            //additionalRequestHeaders: authStore.authHeader,
                            field: 'files',
                        },
                    },
                }),
                ...((!blockTypes || blockTypes.includes(BlockType.Quote)) && {
                    [BlockType.Quote]: {
                        class: QuoteTool,
                        inlineToolbar: true,
                        config: {
                            quotePlaceholder: 'Введите цитату',
                            captionPlaceholder: 'Источник или автор цитаты',
                        },
                    },
                }),
                ...((!blockTypes || blockTypes.includes(BlockType.Raw)) && {
                    [BlockType.Raw]: {
                        class: RawTool,
                        config: { placeholder: 'Вставьте HTML код' },
                    },
                }),
                ...((!blockTypes || blockTypes.includes(BlockType.Embed)) && {
                    [BlockType.Embed]: {
                        // @ts-ignore
                        class: EmbedTool,
                    },
                }),
            };

            //создать новый редактор
            setEditor(
                new EditorJS({
                    placeholder: placeholder || 'Напишите здесь что-нибудь...',
                    autofocus: false,
                    holder: `editorjs_${name}`,
                    ...(!isUndefined(initialValue) && {
                        data: { blocks: initialValue },
                    }),
                    //отключить ошибку Property 'embed' is incompatible with index signature.
                    // @ts-ignore
                    tools,
                    onChange: async () => {
                        if (editorActual.current) {
                            const data = await editorActual.current.save();
                            setFieldValue(name, data.blocks);
                        }
                    },
                    i18n: localization,
                })
            );

            return () => {
                //освободить память от предыдущего редактора, если она еще не освобождена
                //используется значение, сохраненное в editorActual.current, т.к. значение переменной editorJS в этот момент м.б. неопределено
                if (editorActual.current?.destroy) editorActual.current.destroy();
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [forceRefreshToken]);

        return (
            <React.Fragment>
                {label && (
                    <label className="form-control-label" htmlFor={`editorjs_${name}`}>
                        {label}
                    </label>
                )}
                <div
                    style={{
                        backgroundColor: '#fafbfd',
                        borderRadius: '5px',
                        padding: '1em',
                    }}
                    id={`editorjs_${name}`}
                />
                {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
            </React.Fragment>
        );
    }
);

export default HTMLEditor;
