import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

interface IQuizFormButtons {
    isValid?: boolean;
    isDirty?: boolean;
    isPublished?: boolean;
    onSave?: () => void;
    onNext?: () => void;
    onPrev?: () => void;
    onPublish?: () => void;
    onUnpublish?: () => void;
}

const QuizFormButtons: React.FC<IQuizFormButtons> = ({
    isValid,
    isDirty,
    isPublished,
    onSave,
    onNext,
    onPrev,
    onPublish,
    onUnpublish,
}) => {
    const navigate = useNavigate();

    const handleSave = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onSave) onSave();
    };

    const handleNext = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onNext) onNext();
    };

    const handlePrev = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onPrev) onPrev();
    };

    const handlePublish = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onPublish) onPublish();
    };

    const handleUnpublish = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onUnpublish) onUnpublish();
    };

    return (
        <Row>
            <Col className="d-flex justify-content-start">
                {/* ОТМЕНА, возврат в список */}
                <Button
                    onClick={() => navigate('/admin/quizes', { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    ОТМЕНА
                </Button>
            </Col>

            <Col className="d-flex justify-content-end">
                {/* сохранить и перейти на предыдущую закладку */}
                {onPrev && !isPublished && (
                    <Button className="btn-icon" onClick={handlePrev} size="sm" color="primary" type="button">
                        <span className="btn-inner--icon">{isDirty && <i className="fas fa-save" />}</span>
                        <span className={`${isDirty && 'btn-inner--text'}`} title="Перейти на предыдущую закладку">
                            НАЗАД
                        </span>
                    </Button>
                )}

                {/* сохранить */}
                {onSave && (
                    <Button
                        className="btn-icon"
                        onClick={handleSave}
                        //disabled={!isValid || !isDirty}
                        disabled={!isValid}
                        size="sm"
                        color="primary"
                        type="button"
                    >
                        {isPublished ? 'СОХРАНИТЬ' : 'СОХРАНИТЬ КАК ЧЕРНОВИК'}
                    </Button>
                )}

                {/* сохранить и перейти на следующую закладку */}
                {onNext && !isPublished && (
                    <Button
                        className="btn-icon"
                        onClick={handleNext}
                        disabled={!isValid}
                        size="sm"
                        color="primary"
                        type="button"
                    >
                        <span className="btn-inner--icon">{isDirty && <i className="fas fa-save" />}</span>
                        <span className={`${isDirty && 'btn-inner--text'}`} title="Перейти на следующую закладку">
                            ДАЛЕЕ
                        </span>
                    </Button>
                )}

                {/* опубликовать */}
                {onPublish && !isPublished && (
                    <Button onClick={handlePublish} size="sm" color="success" type="button">
                        ОПУБЛИКОВАТЬ
                    </Button>
                )}

                {/* снять с публикации */}
                {onUnpublish && isPublished && (
                    <Button onClick={handleUnpublish} size="sm" color="warning" type="button">
                        СНЯТЬ С ПУБЛИКАЦИИ
                    </Button>
                )}
            </Col>
        </Row>
    );
};

export default QuizFormButtons;
