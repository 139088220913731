import React from 'react';
import { Button, Spinner, Col, Row } from 'reactstrap';

import * as appConst from 'app.const';

interface ILoginButton {
    isDisabled?: boolean;
    onClick?: () => void;
    actionName?: string;
    type: 'button' | 'submit';
    isLoading?: boolean;
}

const LoginButton: React.FC<ILoginButton> = ({
    isDisabled = false,
    onClick,
    actionName = 'Продолжить',
    type,
    isLoading = false,
}) => {
    const handleSubmit = (e: React.MouseEvent) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <div className="d-flex flex-column align-items-stretch mt-3">
            <Button
                disabled={isDisabled}
                onClick={handleSubmit}
                className="my-0 rounded px-0"
                color="primary"
                type={type}
                style={{
                    backgroundColor: appConst.backgroundPrimaryColor,
                    borderColor: appConst.backgroundPrimaryColor,
                }}
            >
                <Row className="mx-0">
                    <Col className="col-2 px-0">
                        {isLoading && (
                            <span className="btn-inner--icon">
                                <Spinner style={{ width: '1.2rem', height: '1.2rem' }} />
                            </span>
                        )}
                    </Col>
                    <Col className="px-1">{actionName}</Col>
                    <Col className="col-2 px-0" />
                </Row>
            </Button>
        </div>
    );
};

export default LoginButton;
