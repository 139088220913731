import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';

import FormTextInput from 'components/inputs/FormTextInput';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import { useStore } from 'store';

const TagCreateForm: React.FC = observer(() => {
    const { item } = useStore('tag');
    const plainItem = toJS(item);
    const { dirty, values, handleChange, handleSubmit } = useFormik({
        initialValues: { title: '' },
        onSubmit: (data) => {
            if (dirty) {
                //описание события
                const eventDescriptor = {
                    name: 'tag_new',
                    props: { tag_id: 'id', tag_name: 'title' },
                };
                //создать объект
                item.createOne(data, eventDescriptor);
            }
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (plainItem.isCreated) {
            navigate(`/admin/tags`);
        }

        return () => item.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated]);

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Название тэга"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <div className="mt-4" />
            <FormBackOrSubmitButtons backTitle="К списку" backPath="/admin/tags" disabled={!dirty} />
        </Form>
    );
});

export default TagCreateForm;
