import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import { Col, Form, Row, Card, CardBody, Button } from 'reactstrap';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { fcmApi, tagApi, channelApi, TSourceSearch } from 'api';
import { getDirtyValues } from 'lib/getEditedValues';
import { sysNotify } from 'lib/system';
import { useIsMounted } from 'lib/hooks';
import SaveAndPublishButtons from 'components/buttons/SaveAndPublishButtons';
import ChoicesSelect from 'components/inputs.uncontrolled/dropdown-selects/ChoicesSelectFromRemoteRhf';
import ReactDropzone from 'components/inputs.uncontrolled/ReactDropzoneRhf';
import TtlUnitSelect from 'components/inputs.uncontrolled/dropdown-selects/TtlUnitSelectRhf';
import TtlCountSelect from 'components/inputs.uncontrolled/dropdown-selects/TtlCountSelectRhf';
import FormDateSelect from 'components/inputs.uncontrolled/FormDateSelectRhf';
import FormCheckBoxControlled from 'components/inputs/FormCheckBox';
import FormCheckBox from 'components/inputs.uncontrolled/FormCheckBoxRhf';
import FormTextInputControlled from 'components/inputs/FormTextInput';
import FormTextInput from 'components/inputs.uncontrolled/FormTextInputRhf';
import { FcmType, FcmSchedulerType, FcmTtlUnit, getFcmType } from 'types';
import { IItem, IItemContent } from '../types';
import { useRqItemUpdate, useRqItemCreate } from '../queries';
import { useSourceState } from './reducer';

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
    sourceType?: FcmType; //используется только для создания нового уведомления. В режиме edit читается из базы.
}

const validationSchema = yup
    .object({
        title: yup.string().max(128, `максимум 128 символов`).required(`поле не заполнено`),
        text: yup.string().max(256, `максимум 128 символов`).required(`поле не заполнено`),
        tags: yup.array(),
        channels: yup.array(),
    })
    .test('myCustomTest', '', (obj) => {
        if ((obj.tags?.length || 0) + (obj.channels?.length || 0) > 5)
            return new yup.ValidationError('Более 5 элементов никак нельзя!', null, 'tags');
        return true;
    });

const ItemForm: React.FC<IProps> = ({ id, content, mode, sourceType = FcmType.SIMPLE }) => {
    const navigate = useNavigate();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //шаблон поиска
    const [search, setSearch] = useState({ searchType: sourceType, searchId: '', searchName: '' });
    //управление состоянием источника уведомления
    const [source, dispatch] = useSourceState();
    //управление отображением обложки
    const [showCover, setShowCover] = useState(false);
    //управление отображением выбора тем
    const [sendAll, setSendAll] = useState(
        !content ||
            ((!content.tags || content.tags.length === 0) && (!content.channels || content.channels.length === 0))
    );

    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            type: sourceType,
            title: '',
            text: '',
            schedulerType: FcmSchedulerType.NOW,
            scheduledAt: new Date(),
            withSound: true,
            ttlUnit: FcmTtlUnit.WEEK,
            ttlCount: 1,
            cover: '',
            coverId: 0,
            isPublished: false,
            sourceId: 0,
            sourceName: '',
            tags: [],
            channels: [],
        };
    } else {
        const { id: ignoreItemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
        register,
        setValue,
        watch,
        trigger,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });
    const [
        watchSourceType,
        watchSourceId,
        watchSourceName,
        watchIsPublished,
        watchCover,
        watchSchedulerType,
        watchTtlUnit,
    ] = watch(['type', 'sourceId', 'sourceName', 'isPublished', 'cover', 'schedulerType', 'ttlUnit']);

    //обеспечить для onSubmit актуальные значения dirtyFields
    //это особенность использования кнопки Опубликовать
    //в одном обработчике кнопки будет установлен признак isPublished и вызван handleSubmit
    const actualDirtyFields = useRef(dirtyFields);
    useEffect(() => {
        actualDirtyFields.current = dirtyFields;
    }, [dirtyFields]);

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(actualDirtyFields.current, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //notify + событие countly
                            sysNotify('update', id, data /*, eventDescriptor*/);
                        }
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //notify + событие countly
                            sysNotify('create', data.id, data /*, eventDescriptor*/);
                            //после создания переход на редактирование
                            navigate(`/admin/fcms/${data.id}/edit`);
                        }
                    },
                }
            );
        }
    };

    //*************************************************************************************************************
    //при изменении шаблона источника выполняем отложенный поиск (по таймеру)
    useEffect(() => {
        //*************************************************************************************************************
        //обработка результатов поиска
        const saveSearchResults = (
            sourceId: number | undefined,
            sourceName: string | undefined,
            coverId: number | undefined,
            cover: string | undefined
        ) => {
            setValue('sourceId', sourceId || 0, { shouldDirty: true });
            setValue('sourceName', sourceName || '', { shouldDirty: true });
            setValue('coverId', coverId || 0, { shouldDirty: true });
            setValue('cover', cover || '', { shouldDirty: true });
            setShowCover(true);
        };
        const clearSearchResults = () => {
            setValue('sourceId', 0, { shouldDirty: true });
            setValue('sourceName', '', { shouldDirty: true });
            setValue('coverId', 0, { shouldDirty: true });
            setValue('cover', '', { shouldDirty: true });
        };

        //**********************************************************************
        //Поиск источника уведомления по заданному шаблону.
        //По результатам поиска выполняется действие с состоянием источника уведомления.
        async function sourceSearch({ searchType, searchId, searchName }: TSourceSearch, signal: AbortSignal) {
            //если нет данных для поиска
            if ((!searchId && !searchName) || (!searchId && searchName.length < 4)) {
                dispatch({ type: 'SOURCE_NOT_ENOUGH_DATA', payload: { warning: 'задайте шаблон поиска' } });
                return;
            }

            //начать поиск
            dispatch({ type: 'SOURCE_START' });
            try {
                //поиск в списке
                const v = (await fcmApi.sourceSearch({ searchType, searchId, searchName }, signal)).data;
                const { codeResult, id: sourceId, title, coverId, cover, total } = v;
                //если поиск удачен (найден один источник), то запомнить найденный источник
                if (codeResult === 0) {
                    saveSearchResults(sourceId, title, coverId, cover);
                    dispatch({ type: 'SOURCE_SUCCESS', payload: { sourceId: sourceId || 0, sourceName: title || '' } });
                }
                //если не найдено
                else if (codeResult === 1) {
                    clearSearchResults();
                    dispatch({ type: 'SOURCE_NOT_ENOUGH_DATA', payload: { warning: `не найдено` } });
                }
                //если найдено > 1
                else {
                    clearSearchResults();
                    dispatch({
                        type: 'SOURCE_NOT_ENOUGH_DATA',
                        ...(title &&
                            total && {
                                payload: {
                                    warning: `'${title.slice(0, 40)}' ... еще ${total - 1} вариант(ов)`,
                                },
                            }),
                    });
                }
            } catch (err) {
                //если ошибка поиска
                clearSearchResults();
                dispatch({ type: 'SOURCE_FAILURE' });
            }
        }

        const controller = new AbortController();
        const timeOutId = setTimeout(
            () =>
                sourceSearch(
                    {
                        searchType: search.searchType,
                        searchId: Number(search.searchId) || 0,
                        searchName: search.searchName,
                    },
                    controller.signal
                ),
            500
        );
        return () => {
            controller.abort();
            clearTimeout(timeOutId);
        };
    }, [search.searchType, search.searchId, search.searchName, setValue, dispatch]);

    //*************************************************************************************************************
    //Обработчик ввода шаблона поиска.
    //<EVENT,> - это особенность Generics syntax for arrow functions in JSX
    //см. https://devtrium.com/posts/react-typescript-using-generics-in-react#generics-syntax-for-arrow-functions-in-jsx
    type TInputEvent = ChangeEvent<HTMLInputElement>;
    const handleSearch = <EVENT,>(event: EVENT) =>
        setSearch((prev) => {
            const value = (event as unknown as TInputEvent).target.value;
            const name = (event as unknown as TInputEvent).target.name;
            return { ...prev, [name]: value };
        });

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        const v = await trigger();
        if (!v) return;
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Опубликовать
    const handlePublish = async () => {
        const v = await trigger();
        if (!v) return;
        setValue('isPublished', true, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Снять с публикации
    const handleUnpublish = async () => {
        //здесь нет проверки свойств, т.к. при снятии с публикации другие свойства не сохраняются
        setValue('isPublished', false, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //поменять значение schedulerType (checkbox)
    const handleSchedulerType = async () => {
        setValue(
            `schedulerType`,
            watchSchedulerType === FcmSchedulerType.NOW ? FcmSchedulerType.TIME : FcmSchedulerType.NOW,
            { shouldDirty: true }
        );
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <Form>
            {/* выбор источника уведомления */}
            {watchSourceType !== FcmType.SIMPLE && (
                <Card>
                    <CardBody>
                        {mode !== 'edit' && (
                            <>
                                <Row>
                                    <Col className="col-11">
                                        <span className="h4 form-control-label">
                                            {sourceType === FcmType.ARTICLE ? 'Выбор статьи:' : 'Выбор викторины:'}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                        <FormTextInputControlled
                                            //invalidText={errors.searchId}
                                            title="ID"
                                            name="searchId"
                                            value={search.searchId}
                                            handleChange={handleSearch}
                                            type="number"
                                        />
                                    </Col>
                                    <Col md="10">
                                        <FormTextInputControlled
                                            //invalidText={errors.searchName}
                                            title="Название"
                                            name="searchName"
                                            value={search.searchName}
                                            handleChange={handleSearch}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        {/* пояснение */}
                        {mode !== 'edit' && !source.sourceId && source.warning && (
                            <Row>
                                <Col>
                                    <span className="h5">{source.warning}</span>
                                </Col>
                            </Row>
                        )}
                        {/* найденный источник */}
                        {!!watchSourceId && watchSourceName && (
                            <Row className="mt-3">
                                <Col>
                                    <span className="h3 text-primary">{`${getFcmType(
                                        watchSourceType
                                    )} (ID ${watchSourceId}): ${watchSourceName}`}</span>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            )}

            {/* Заголовок */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        title="Заголовок"
                    />
                </Col>
            </Row>

            {/* содержание */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        control={control}
                        name="text"
                        invalidText={errors.text?.message}
                        title="Содержание"
                        type="textarea"
                        rows={2}
                        placeholder="Введите описание"
                    />
                </Col>
            </Row>

            {/* если показывать обложку */}
            {!!showCover && (
                <Row className="justify-content-end">
                    <Col className="mb-3">
                        <ReactDropzone
                            buttonText="Загрузите обложку здесь"
                            setFileName={(name) => setValue('cover', name, { shouldDirty: true })}
                            setFileId={(fileId) => setValue('coverId', fileId, { shouldDirty: true })}
                            label="Обложка"
                            preloadedImageUrl={watchCover}
                            invalidText={errors.cover?.message}
                            clearValueOnError={false}
                        />
                    </Col>
                    {/* удалить обложку */}
                    <Col className="d-flex flex-column justify-content-center align-items-center mb-3" xs="1">
                        <Button
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            outline={true}
                            type="button"
                            onClick={() => {
                                setValue('cover', '', { shouldDirty: true });
                                setValue('coverId', 0, { shouldDirty: true });
                            }}
                        >
                            <span className="btn-inner--icon">
                                <i className="fas fa-times" />
                            </span>
                        </Button>
                    </Col>
                </Row>
            )}
            {/* если скрывать обложку */}
            {!showCover && (
                <Row>
                    <Col md="10">
                        <span className="h5">{watchCover ? `Обложка: ${watchCover}` : 'обложка не выбрана'}</span>
                    </Col>
                </Row>
            )}
            {/* показать/скрыть обложку */}
            <Row>
                <Col className="col-10 mt-3 mb-3">
                    <FormCheckBoxControlled
                        name="showCover"
                        title="показать обложку"
                        value={showCover}
                        handleChange={() => setShowCover((prev) => !prev)}
                    />
                </Col>
            </Row>

            {/* расписание */}
            <Row>
                <Col className="mb-3">
                    {/* используется контролируемый CheckBox, т.к. истинный тип schedulerType = number и может быть впоследствии расширен по кол-ву значений */}
                    <FormCheckBoxControlled
                        name="schedulerType"
                        title="отправка сразу при публикации"
                        value={watchSchedulerType === FcmSchedulerType.NOW}
                        handleChange={handleSchedulerType}
                    />
                </Col>
            </Row>
            {watchSchedulerType !== FcmSchedulerType.NOW && (
                <Row>
                    <Col md="4" className="mb-3">
                        <FormDateSelect
                            control={control}
                            name="scheduledAt"
                            label="Дата/время отправки"
                            placeholder="Введите дату"
                            invalidText={errors.scheduledAt?.message}
                        />
                    </Col>
                </Row>
            )}

            {/* звук */}
            <Row>
                <Col className="mb-3">
                    <FormCheckBox register={register} name="withSound" title="со звуком" />
                </Col>
            </Row>

            {/* TTL */}
            <Row className="align-items-end mb-3">
                {/* количество TTL */}
                <Col
                    md="3"
                    //для выполнения рендера при изменении ttlUnit
                    key={`ttlCount ${watchTtlUnit}`}
                >
                    <TtlCountSelect name="ttlCount" control={control} label="Срок хранения" type={watchTtlUnit} />
                </Col>
                {/* единицы TTL */}
                <Col md="3">
                    <TtlUnitSelect
                        name="ttlUnit"
                        control={control}
                        // label="Тип срока хранения"
                    />
                </Col>
            </Row>

            {/* показать/скрыть выбор тем отправки */}
            <Row>
                <Col className="col-10 mt-3 mb-3">
                    <FormCheckBoxControlled
                        name="sendAll"
                        title="отправить всем"
                        value={sendAll}
                        handleChange={() => {
                            //если закрыть выбор тем
                            if (!sendAll) {
                                setValue('tags', [], { shouldDirty: true });
                                setValue('channels', [], { shouldDirty: true });
                            }
                            setSendAll((prev) => !prev);
                        }}
                    />
                </Col>
            </Row>

            {/* Выбор тем отправки по тегам и каналам */}
            {!sendAll && (
                <React.Fragment>
                    <Row>
                        <Col>
                            <span className="form-control-label">
                                Можно выбрать любую комбинацию Теги+Каналы, но не более 5 элементов:
                            </span>
                        </Col>
                    </Row>
                    {(errors.tags as unknown as FieldError) && (
                        <Row>
                            <Col>
                                <span className="form-control-label text-danger">
                                    {(errors.tags as unknown as FieldError)?.message}
                                </span>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="mb-3" md="6">
                            <ChoicesSelect
                                name="tags"
                                control={control}
                                maxItemCount={5}
                                getSelect={tagApi.getSelect}
                                placeholder="Начните вводить название тэга"
                                label="Тэги"
                            />
                        </Col>
                        <Col className="mb-3" md="6">
                            <ChoicesSelect
                                name="channels"
                                control={control}
                                maxItemCount={5}
                                getSelect={channelApi.getSelect}
                                placeholder="Начните вводить название канала"
                                label="Каналы"
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            )}

            {/* кнопки */}
            <div className="mt-5" />
            <SaveAndPublishButtons
                isPublished={watchIsPublished || false}
                isValid={true}
                isDirty={isDirty}
                pathToList="/admin/fcms"
                onSave={handleSave}
                onPublish={handlePublish}
                onUnpublish={handleUnpublish}
                isLoading={isLoading}
            />
        </Form>
    );
};

export default ItemForm;
