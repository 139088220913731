import React, { useState } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import QuizEditForm from 'views/quizes/Components/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Tabs from 'components/navs/Tabs';
import { tabArray, ITab } from '../Components/Const';

const QuizCreate: React.FC = () => {
    //отключить все закладки, кроме первой
    const [tabs, setTabs] = useState({
        activeTab: 1,
        tabArray: tabArray.map((t, index) => ({ ...t, disabled: index !== 0 })) as ITab[],
    });

    useResetListIfGoOut('quiz', 'quizes');

    return (
        <React.Fragment>
            <AdminViewHeader title="Создать викторину" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="pb-0">
                                <Tabs
                                    tabs={tabs.tabArray}
                                    activeTab={tabs.activeTab}
                                    setTab={(num) => setTabs((prev) => ({ ...prev, activeTab: num }))}
                                />
                            </CardHeader>
                            <CardBody>
                                <QuizEditForm tabs={tabs} setTabs={setTabs} mode="create" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default QuizCreate;
