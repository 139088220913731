import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const TgChannelList: React.FC = observer(() => {
    const storeName = 'tgChannel';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.tgChannels;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '20em',
                maxWidth: '20em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'isTest',
            text: 'Тип',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            style: {
                minWidth: '40em',
            },
            formatter: (isTest) => (isTest ? 'Тестовый' : 'Рабочий'),
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: { title: 'Добавить канал', path: '/admin/tgChannels/create' },
    };
    useResetListIfGoOut(storeName, 'tgChannels');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'title',
                sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Телеграм каналы" {...creatingProp} />
        </ItemsListTable>
    );
});

export default TgChannelList;
