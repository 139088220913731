import { makeAutoObservable } from 'mobx';

import { quizResultApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IQuizResultInList {
    quizId: number;
    userId: number;
    createdAt: Date;
    appliedTime: number;
    correctCount: number;
    userCommonName: string;
    userEmail: string;
    quizTitle: string;
    percent: number;
}

export interface IQuizResult {
    id: number;
}

class QuizResultStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IQuizResult, IQuizResult, IQuizResult>(quizResultApi);

    readonly list = new ListStore<IQuizResultInList>(quizResultApi);
}

export default new QuizResultStore();
