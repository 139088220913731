import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { AlertOptions } from 'react-notification-alert';

import { useIsMounted } from 'lib/hooks';
import SaveButtons from 'components/buttons/SaveButtons';
import FormCheckBox from 'components/inputs/FormCheckBox';
import notifyStore from 'store/notification';
import { TSecurityArray, useRqUpdateSecurity } from './queries';

interface ISecurityForm {
    securityArray: TSecurityArray;
}

//*************************************************************************************************************
//Безопасность
const SecurityForm: React.FC<ISecurityForm> = ({ securityArray }) => {
    const [values, setValues] = useState<TSecurityArray>(securityArray.map((a) => ({ ...a })));
    //признак наличия исправлений
    const [isDirty, setIsDirty] = useState(false);
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqUpdateSecurity = useRqUpdateSecurity();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    //поменять значение TTL для элемента index массива values
    const handleTTL = (index: number) => {
        const newValues = values.map((a) => ({ ...a }));
        newValues[index].ttl = !values[index].ttl;
        setIsDirty(true);
        setValues(newValues);
    };

    //поменять значение factor2 для элемента index массива values
    const handleFactor2 = (index: number) => {
        const newValues = values.map((a) => ({ ...a }));
        newValues[index].factor2 = !values[index].factor2;
        setIsDirty(true);
        setValues(newValues);
    };

    return (
        <React.Fragment>
            <Row>
                <Col className="d-flex col-5 mt-0 mb-3 justify-content-center text-center">Роль</Col>
                <Col className="d-flex col-2 mt-0 mb-3 justify-content-center text-center">
                    Время действия пароля 90 дней
                </Col>
                <Col className="d-flex col-2 mt-0 mb-3 justify-content-center text-center">
                    Двухфакторная аутентификация
                </Col>
            </Row>
            {values.map((item, index) => (
                <Row key={`item-${item.roleId}`}>
                    <Col
                        className={`d-flex col-5 mt-0 pl-3 py-2 justify-content-start ${
                            index === 0 && 'border-top'
                        } border-bottom`}
                    >
                        {item.title}
                    </Col>
                    <Col
                        className={`d-flex col-2 mt-0 py-2 justify-content-center ${
                            index === 0 && 'border-top'
                        } border-bottom`}
                    >
                        <FormCheckBox
                            name={`${item.roleId}-ttl`}
                            value={item.ttl}
                            handleChange={() => handleTTL(index)}
                        />
                    </Col>
                    <Col
                        className={`d-flex col-2 mt-0 py-2 justify-content-center ${
                            index === 0 && 'border-top'
                        } border-bottom`}
                    >
                        <FormCheckBox
                            name={`${item.roleId}-factor2`}
                            value={item.factor2}
                            handleChange={() => handleFactor2(index)}
                        />
                    </Col>
                </Row>
            ))}

            {/* кнопки */}
            <div className="mt-4" />
            <SaveButtons
                isDirty={isDirty}
                onSave={() =>
                    rqUpdateSecurity.mutate(values, {
                        onSuccess: (data /*, variables, context */) => {
                            if (isMounted.current) {
                                const newValues = (data.data as TSecurityArray).map((a) => ({ ...a }));
                                setIsDirty(false);
                                setValues(newValues);
                            }
                            notifyStore.showNotify({
                                place: 'br',
                                message: 'данные сохранены',
                                type: 'success',
                            } as AlertOptions);
                        },
                    })
                }
                backTitle="к настройкам"
                pathToList="/admin/settings"
                disabled={rqUpdateSecurity.isLoading}
                isLoading={rqUpdateSecurity.isLoading}
            />
        </React.Fragment>
    );
};

export default SecurityForm;
