import { makeAutoObservable } from 'mobx';

import { tagApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface ITag {
    id: number;
    title: string;
    create: string;
}

export interface ITagUpdate {
    title: string;
}

export interface ITagCreate {
    title: string;
}

class TagStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    readonly item = new ItemStore<ITag, ITagUpdate, ITagCreate>(tagApi);

    readonly list = new ListStore<ITag>(tagApi);
}

export default new TagStore();
