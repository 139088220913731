import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Collapse } from 'reactstrap';
import { v4 as uuid } from 'uuid';

import FormTextInput from 'components/inputs/FormTextInput';
import { IQuiz, IQuizUpdate } from 'store/quiz';
import { useStore } from 'store';

interface IQuizProps {
    handleChange: <T>(arg: T) => void;
    errors: Partial<IQuiz> | undefined;
    value: IQuizUpdate;
}

//************************************************************************************************************
//Компонент Explanation
export const Explanation: React.FC<IQuizProps> = ({ errors, handleChange, value }) => {
    const quizStore = useStore('quiz');
    //массив keys держит ключи для рендеринга списка вопросов
    //и подмассивы ключей subKeys для рендеринга ответов
    const [keys] = useState(() => {
        if (!value.questions) return [];

        return value.questions.map((q, i) => {
            let subKeys: string[] = [];
            if (value.questions) subKeys = value.questions[i].answers.map(() => uuid());

            return {
                key: uuid(),
                subKeys,
            };
        });
    });

    const { questions } = value;

    //текущий вопрос по индексу последнего развернутого вопроса
    const [openedCollapses, setOpenedCollapses] = useState([quizStore.indexLastQuestion]);

    //переключатель collapse
    const collapsesToggle = (collapse: number) => {
        //свернуть все
        if (openedCollapses.includes(collapse)) {
            quizStore.indexLastQuestion = -1; //индекс последнего развернутого вопроса
            setOpenedCollapses([]);
        }
        //развернуть заданный
        else {
            quizStore.indexLastQuestion = collapse; //индекс последнего развернутого вопроса
            setOpenedCollapses([collapse]);
        }
    };

    return (
        <>
            <Row
                className="d-flex align-items-center mb-3 ml-3 mr-3 p-3 border border-2 rounded"
                style={{ backgroundColor: '#FCEEE4' }}
            >
                <Col className="d-flex col-1 justify-content-end">
                    <i className="fas fa-exclamation text-success" />
                </Col>
                <Col className="col-11">
                    <span className="h5">
                        Добавьте пояснения к ответам на вопросы. Пояснения будут показаны пользователю после ответа на
                        вопрос.
                    </span>
                </Col>
            </Row>

            <div className="accordion">
                {questions && (
                    <>
                        {/* Список вопросов */}
                        {questions.map((question, indexQuestion) => (
                            // карточка вопроса
                            <Card
                                key={`${keys[indexQuestion].key}`}
                                className="card-plain"
                                style={{ backgroundColor: '#FCEEE4' }}
                            >
                                {/* заголовок вопроса с символом свертки */}
                                <CardHeader
                                    role="tab"
                                    onClick={() => collapsesToggle(indexQuestion)}
                                    aria-expanded={openedCollapses.includes(indexQuestion)}
                                    style={{ backgroundColor: '#FCEEE4' }}
                                >
                                    <Row>
                                        <span className="mb-0 h3">
                                            <strong>{`Вопрос ${indexQuestion + 1}`}</strong>
                                        </span>
                                    </Row>
                                    {!openedCollapses.includes(indexQuestion) && (
                                        <Row>
                                            <Col className="col-11 text-truncate">
                                                <span className="mb-0 h5">{questions[indexQuestion].text}</span>
                                            </Col>
                                        </Row>
                                    )}
                                </CardHeader>

                                <Collapse role="tabpanel" isOpen={openedCollapses.includes(indexQuestion)}>
                                    <CardBody>
                                        {/* текст вопроса */}
                                        <Row className="mb-2">
                                            <Col>
                                                <span className="h5">{questions[indexQuestion].text}</span>
                                            </Col>
                                        </Row>

                                        {/* пояснение к правильному ответу */}
                                        <Row>
                                            <span className="ml-3 mb-0 h4 text-success">Правильный ответ</span>
                                        </Row>
                                        <Row>
                                            <Col className="col-10 mb-3">
                                                <FormTextInput
                                                    type="textarea"
                                                    rows={3}
                                                    invalidText={
                                                        errors?.questions && errors.questions[indexQuestion].explanation
                                                    }
                                                    //placeholder="Пояснение к ответу"
                                                    name={`questions[${indexQuestion}].explanation`}
                                                    value={question.explanation}
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>

                                        {/* пояснение к неправильному ответу */}
                                        <Row>
                                            <span className="ml-3 mb-0 h4 text-danger">Неправильный ответ</span>
                                        </Row>
                                        <Row>
                                            <Col className="col-10 mb-0">
                                                <FormTextInput
                                                    type="textarea"
                                                    rows={3}
                                                    invalidText={
                                                        errors?.questions &&
                                                        errors.questions[indexQuestion].errorExplanation
                                                    }
                                                    //placeholder="Пояснение к ответу"
                                                    name={`questions[${indexQuestion}].errorExplanation`}
                                                    value={question.errorExplanation}
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};
