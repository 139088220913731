import React, { useEffect, useRef } from 'react';
import { Col, Form, Row, Button } from 'reactstrap';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { v4 as uuid } from 'uuid';

import * as appConst from 'app.const';
import InvalidLabel from 'components/labels/InvalidLabel';
import { eventApi } from 'api';
import { getDirtyValues } from 'lib/getEditedValues';
import { sysNotify } from 'lib/system';
import { setZeroTime, getLocalDateInfo } from 'lib/datetime';
import { useIsMounted } from 'lib/hooks';
import SaveAndPublishButtons from 'components/buttons/SaveAndPublishButtons';
import ChoicesSelect from 'components/inputs.uncontrolled/dropdown-selects/ChoicesSelectFromRemoteRhf';
import ReactDropzone from 'components/inputs.uncontrolled/ReactDropzoneRhf';
import StringSelect from 'components/inputs.uncontrolled/dropdown-selects/StringSelectRhf';
import FormDateSelect from 'components/inputs.uncontrolled/FormDateSelectRhf';
import FormCheckBox from 'components/inputs.uncontrolled/FormCheckBoxRhf';
import FormCheckBoxControlled from 'components/inputs/FormCheckBox';
import FormTextInput from 'components/inputs.uncontrolled/FormTextInputRhf';
import HTMLEditField from 'components/edit.uncontrolled/HTMLEditor';
import { IItem, IItemContent } from '../types';
import { useRqItemUpdate, useRqItemCreate } from '../queries';

const maxTitle = 256;
const maxSubtitle = 50;
const maxPlace = 512;

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup
    .object({
        title: yup.string().max(maxTitle, `Название максимум ${maxTitle} символов`).required(`Название отсутствует`),
        subtitle: yup.string().max(maxSubtitle, `Подзаголовок максимум ${maxSubtitle} символов`).nullable(),
        repeat: yup.boolean().required(`Не определен признак повторения`),
        dateBegin: yup.date().required(`Отсутствует дата/время начала`),
        dateEnd: yup.date().required(`Отсутствует дата/время окончания`),
        fullTime: yup.boolean().required(`Не определен признак полного дня`),
        themes: yup.array().min(1, 'Нет привязки к темам').required(`Нет привязки к темам`),
        place: yup
            .string()
            //.max(1, `Место проведения отсутствует`)
            .max(maxPlace, `Место проведения максимум ${maxPlace} символов`),
        //.required(`Место проведения отсутствует`)
        editorData: yup.array().min(1, 'Отсутствует содержание').required(`Отсутствует содержание`),
        ageLimit: yup
            .string()
            .min(1, `Ограничение по возрасту отсутствует`)
            .max(3, `Ограничение по возрасту максимум 3 символа`)
            .required(`Ограничение по возрасту отсутствует`),
        isOnline: yup.boolean().required(`Не определен признак online`),
        // isPublished: yup.boolean(),
    })
    .test('dateTest', '', (obj) => {
        if (obj.dateBegin && obj.dateEnd && obj.dateBegin.getTime() <= obj.dateEnd.getTime()) {
            return true;
        }
        return new yup.ValidationError('Некорректная дата окончания', null, 'dateEnd');
    });
// .test('publishTest', '', (obj) => {
//     if (obj.isPublished && obj.themes instanceof Array && obj.themes.length > 0) {
//         return true;
//     }
//     return new yup.ValidationError('В опубликованном событии должны быть темы', null, 'themes');
// });

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const navigate = useNavigate();
    //ключи для контролов ввода ReactDropzone, используются для принудительной перерисовки контрола
    const keyReactDropzone = React.useRef(uuid());
    //ключи для контролов ввода datetime, используются для принудительной перерисовки контрола
    const keyDateBegin = useRef(uuid());
    const keyDateEnd = useRef(uuid());
    //включение подзаголовка
    const [allowSubtitle, setAllowSubtitle] = React.useState(mode !== 'create' && !!content?.subtitle);
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            title: '',
            isPublished: false,
            repeat: false,
            dateBegin: new Date(),
            dateEnd: new Date(),
            fullTime: true,
            cover: '',
            coverId: 0,
            themes: [],
            place: '',
            editorData: [],
            subtitle: '',
            ageLimit: appConst.ageLimitList[0],
            isOnline: false,
        };
    } else {
        const { id: ignoreItemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
        register,
        setValue,
        watch,
        trigger,
        getValues,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });
    const [watchFullTime, watchDateBegin /*, watchDateEnd*/] = watch(['fullTime', 'dateBegin' /*, 'dateEnd'*/]);

    //обеспечить для onSubmit актуальные значения dirtyFields
    //это особенность использования кнопки Опубликовать
    //в одном обработчике кнопки будет установлен признак isPublished и вызван handleSubmit
    const actualDirtyFields = useRef(dirtyFields);
    useEffect(() => {
        actualDirtyFields.current = dirtyFields;
    }, [dirtyFields]);

    //выровнять dateEnd при изменении dateBegin
    useEffect(() => {
        const d2 = new Date(getValues('dateEnd'));
        const d1 = new Date(watchDateBegin);
        if (d2 && d1 && d2.getTime() < d1.getTime()) {
            setValue('dateEnd', d1, {
                shouldDirty: true,
                shouldTouch: true, //для rerender формы
                shouldValidate: true, //для rerender формы
            });
            keyDateEnd.current = uuid(); //принудительно перерисовать контрол
        }
    }, [setValue, getValues, watchDateBegin /*, watchDateEnd*/]);

    //при изменении fullTime сбросить время в датах
    const handleChangeFullTime = () => {
        const dateBegin = setZeroTime(new Date(getValues('dateBegin')));
        setValue('dateBegin', dateBegin, { shouldDirty: true, shouldTouch: true, shouldValidate: true }); //для rerender формы

        const dateEnd = setZeroTime(new Date(getValues('dateEnd')));
        setValue('dateEnd', dateEnd, { shouldDirty: true, shouldTouch: true, shouldValidate: true }); //для rerender формы

        keyDateBegin.current = uuid(); //принудительно перерисовать контрол
        keyDateEnd.current = uuid(); //принудительно перерисовать контрол
    };

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            //при снятии с публикации не сохранять другие свойства
            let newContent: Partial<IItemContent>;
            const { isPublished: newIsPublishedValue, ...editedValues } = getDirtyValues(
                actualDirtyFields.current,
                values
            ) as Partial<IItemContent>;

            //определить имя события для статистики
            let statEventName = '';
            if (!isUndefined(newIsPublishedValue))
                statEventName = newIsPublishedValue ? 'event_publish' : 'event_delete';
            else if (values.isPublished) statEventName = 'event_update';

            //если снять с публикации
            if (newIsPublishedValue === false) newContent = { isPublished: false };
            //в остальных случаях
            else
                newContent = {
                    ...editedValues,
                    ...(!isUndefined(newIsPublishedValue) && { isPublished: newIsPublishedValue }),
                    fullTime: values.fullTime, //fullTime, dateBegin и dateEnd всегда передаем для нормализации дат в queries
                    dateBegin: values.dateBegin,
                    dateEnd: values.dateEnd,
                };

            rqItemUpdate.mutate(
                { id, content: newContent },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //описание события: соответствие между именем свойства в событии и именем поля в БД
                            const eventDescriptor = {
                                name: statEventName,
                                props: {
                                    event_id: 'id',
                                    event_title: 'title',
                                    themes: 'themesList', //полное описание списка тем (readonly)
                                    isPublished: 'isPublished',
                                },
                            };
                            //notify + событие countly
                            sysNotify('update', id, data, statEventName ? eventDescriptor : undefined);
                        }
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //описание события: соответствие между именем свойства в событии и именем поля в БД
                            const eventDescriptor = {
                                name: 'event_new',
                                props: {
                                    event_id: 'id',
                                    event_title: 'title',
                                    themes: 'themesList', //полное описание списка тем (readonly)
                                    isPublished: 'isPublished',
                                },
                            };
                            //notify + событие countly
                            sysNotify('create', data.id, data, eventDescriptor);
                            //после создания переход на редактирование
                            navigate(`/admin/event/${data.id}/edit`);
                        }
                    },
                }
            );
        }
    };

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        const v = await trigger();
        if (!v) return;
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Опубликовать
    const handlePublish = async () => {
        const v = await trigger();
        if (!v) return;
        setValue('isPublished', true, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    //*************************************************************************************************************
    //Снять с публикации
    const handleUnpublish = async () => {
        //здесь нет проверки свойств, т.к. при снятии с публикации другие свойства не сохраняются
        setValue('isPublished', false, { shouldDirty: true });
        handleSubmit(onSubmit)();
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <Form>
            {/* если показывать обложку */}
            <Row className="justify-content-end">
                <Col className="mb-3">
                    <ReactDropzone
                        // key используются для принудительной перерисовки контрола, иначе не рендерится компонент при изменении значения через setValue
                        key={`ReactDropzone-${keyReactDropzone.current}`}
                        buttonText="Загрузите обложку здесь"
                        setFileName={(name) => setValue('cover', name, { shouldDirty: true })}
                        setFileId={(fileId) => setValue('coverId', fileId, { shouldDirty: true })}
                        label="Обложка"
                        preloadedImageUrl={getValues('cover')}
                        invalidText={errors.cover?.message}
                        clearValueOnError={false}
                    />
                </Col>
                {/* удалить обложку */}
                <Col className="d-flex flex-column justify-content-center align-items-center mb-3" xs="1">
                    <Button
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        outline={true}
                        type="button"
                        onClick={() => {
                            setValue('cover', '', {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                            });
                            setValue('coverId', 0, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                            });
                            keyReactDropzone.current = uuid(); //принудительно перерисовать контрол
                        }}
                    >
                        <span className="btn-inner--icon">
                            <i className="fas fa-times" />
                        </span>
                    </Button>
                </Col>
            </Row>

            {/* Заголовок */}
            <Row>
                <Col className="mb-4" md="10">
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        title={`Заголовок (max ${maxTitle})`}
                    />
                </Col>
            </Row>

            {/* подзаголовок */}
            <Row>
                <Col className="mb-3" md="8">
                    <FormTextInput
                        control={control}
                        name="subtitle"
                        invalidText={errors.subtitle?.message}
                        title={`Подзаголовок (max ${maxSubtitle})`}
                        disabled={!allowSubtitle}
                    />
                </Col>
            </Row>

            {/* Включить подзаголовок для ленты */}
            <Row>
                <Col className="mb-3">
                    <FormCheckBoxControlled
                        name="allowSubtitle"
                        title="Добавить подзаголовок для ленты"
                        value={allowSubtitle}
                        handleChange={() => {
                            if (allowSubtitle) setValue('subtitle', '', { shouldDirty: true });
                            setAllowSubtitle((prev) => !prev);
                        }}
                    />
                </Col>
            </Row>

            {/* Весь день */}
            <Row>
                <Col className="mb-3">
                    <FormCheckBox
                        register={register}
                        name="fullTime"
                        title="Весь день"
                        onChangeHandler={handleChangeFullTime}
                    />
                </Col>
            </Row>

            {/* даты начала/окончания */}
            <Row className="mb-3">
                <Col md="4">
                    <FormDateSelect
                        // key используются для принудительной перерисовки контрола, иначе не рендерится компонент при изменении значения через setValue
                        key={`FormDateSelect-dateBegin-${keyDateBegin.current}`}
                        control={control}
                        name="dateBegin"
                        //label={watchFullTime ? 'Дата начала' : 'Дата/время начала'}
                        label="Начало"
                        placeholder={watchFullTime ? 'Введите дату начала' : 'Введите дату/время начала'}
                        invalidText={errors.dateBegin?.message}
                        {...(watchFullTime && { timeFormat: false })}
                    />
                </Col>
                <Col md="4">
                    <FormDateSelect
                        // key используются для принудительной перерисовки контрола, иначе не рендерится компонент при изменении значения через setValue
                        key={`FormDateSelect-dateEnd-${keyDateEnd.current}`}
                        control={control}
                        name="dateEnd"
                        //label={watchFullTime ? 'Дата окончания' : 'Дата/время окончания'}
                        label="Окончание"
                        placeholder={watchFullTime ? 'Введите дату окончания' : 'Введите дату/время окончания'}
                        invalidText={errors.dateEnd?.message}
                        {...(watchFullTime && { timeFormat: false })}
                    />
                </Col>
                <Col>
                    <span className="h4 form-control-label">{`Время - местное (${getLocalDateInfo().timezone})`}</span>
                </Col>
            </Row>

            {/* признак повторения */}
            <Row>
                <Col className="mb-3">
                    <FormCheckBox register={register} name="repeat" title="Повторять ежегодно" />
                </Col>
            </Row>

            {/* информация о мероприятии */}
            <Row>
                <Col className="mb-4">
                    <HTMLEditField
                        control={control}
                        name="editorData"
                        label="Информация о мероприятии"
                        invalidText={(errors.editorData as unknown as FieldError)?.message as string}
                    />
                </Col>
            </Row>

            {/* online */}
            <Row>
                <Col className="mb-3">
                    <FormCheckBox register={register} name="isOnline" title="Online" />
                </Col>
            </Row>

            {/* место проведения */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        control={control}
                        name="place"
                        invalidText={errors.place?.message}
                        title={`Место проведения (max ${maxPlace})`}
                        type="textarea"
                        rows={2}
                        placeholder="Введите описание"
                    />
                </Col>
            </Row>

            {/* ограничение по возрасту */}
            <Row>
                <Col className="mb-3" md="3">
                    <StringSelect
                        name="ageLimit"
                        control={control}
                        label="Ограничение по возрасту"
                        list={appConst.ageLimitList}
                    />
                </Col>
            </Row>

            {/* Выбор тем */}
            <Row>
                <Col className="mb-0" md="6">
                    <ChoicesSelect
                        name="themes"
                        control={control}
                        maxItemCount={50}
                        getSelect={eventApi.getSelect}
                        placeholder="Начните вводить название темы"
                        label="Темы"
                        shouldSort={false}
                    />
                </Col>
            </Row>
            {(errors.themes as unknown as FieldError) && (
                <Row>
                    <Col>
                        <InvalidLabel>{(errors.themes as unknown as FieldError)?.message}</InvalidLabel>
                    </Col>
                </Row>
            )}

            {/* кнопки */}
            <div className="mt-5" />
            <SaveAndPublishButtons
                isPublished={getValues('isPublished') || false}
                isValid={true}
                isDirty={isDirty}
                pathToList="/admin/event"
                onSave={handleSave}
                onPublish={handlePublish}
                onUnpublish={handleUnpublish}
                isLoading={isLoading}
            />
        </Form>
    );
};

export default ItemForm;
