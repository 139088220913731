//статус обработки
export enum FcmStatus {
    EMPTY = 0,
    INPROCESS = 1,
    DONE = 2,
    ERROR = 3,
}
export const getFcmStatus = (status: FcmStatus) => {
    switch (status) {
        case FcmStatus.EMPTY:
            return '-';
        case FcmStatus.INPROCESS:
            return 'в обработке';
        case FcmStatus.DONE:
            return 'выполнено';
        case FcmStatus.ERROR:
            return 'ошибка';
        default:
            return '';
    }
};

//тип уведомления
export enum FcmType {
    SIMPLE = 1,
    ARTICLE = 2,
    QUIZ = 3,
}
export const getFcmType = (type: FcmType | undefined) => {
    switch (type) {
        case FcmType.ARTICLE:
            return 'Статья';
        case FcmType.QUIZ:
            return 'Викторина';
        default:
            return '';
    }
};

//тип планировщика
export enum FcmSchedulerType {
    NOW = 1,
    TIME = 2,
}

//единицы TTL
export enum FcmTtlUnit {
    WEEK = 1,
    DAY = 2,
    HOUR = 3,
    MINUTE = 4,
}
export const getFcmTtlUnit = (type: FcmTtlUnit | undefined) => {
    switch (type) {
        case FcmTtlUnit.WEEK:
            return 'Неделя';
        case FcmTtlUnit.DAY:
            return 'День';
        case FcmTtlUnit.HOUR:
            return 'Час';
        case FcmTtlUnit.MINUTE:
            return 'Минута';
        default:
            return '';
    }
};
