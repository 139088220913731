import React, { useEffect, useRef } from 'react';
import Choices from 'choices.js';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
    list: string[]; //список строковых констант
}
const StringSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label,
    list,
}: Props<T>) => {
    const { field } = useController({
        name,
        control,
    });
    //ссылка на объект Choices
    const ch = useRef<Choices | null>(null);
    //константный список:
    //компонент Choices отказывается работать со значением number, поэтому все преобразуем в string
    // value - строковое значение элемента = отображаемое в списке значение
    // label - отображаемое в списке значение
    type TListItem = { value: string; label: string };
    const choicesList = useRef<TListItem[]>([]);

    useEffect(() => {
        //сформировать элемент списка
        const choicesListItem = (v: string) => ({
            value: v, //строковое значение элемента
            label: v, //отображаемое в списке значение
        });
        //заполнить константный список
        list.forEach((v) => choicesList.current.push(choicesListItem(v)));

        const choices = new Choices(`#choices-select-${name}`, {
            searchEnabled: false,
            delimiter: ',',
            removeItemButton: false,
            maxItemCount: 1,
            paste: false,
            placeholder: true,
            resetScrollPosition: false,
            placeholderValue: 'Выберите значение',
            noResultsText: 'Ничего не найдено',
            noChoicesText: 'Нет элементов для выбора',
            itemSelectText: 'Нажмите для выбора',
            loadingText: 'Загрузка...',
            maxItemText: `Только 1 элемент может быть выбран`,
            choices: choicesList.current.map((item) =>
                item.value === field.value ? { value: item.value, label: item.label, selected: true } : item
            ),
            shouldSort: false,
        });
        ch.current = choices;

        return () => {
            if (ch.current) {
                ch.current.destroy();
                ch.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {label && (
                <label className="form-control-label" htmlFor={`choices-select-${name}`}>
                    {label}
                </label>
            )}
            <select
                value={field.value}
                onChange={() => {
                    if (ch.current) {
                        //при выборе элемента возвращаем его
                        field.onChange(ch.current.getValue(true));
                    }
                }}
                aria-label={name}
                className="form-control"
                id={`choices-select-${name}`}
            />
        </React.Fragment>
    );
};

export default StringSelect;
