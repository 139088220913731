import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';

import FormTextInput from 'components/inputs/FormTextInput';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { useNavigate } from 'react-router-dom';

const RoleCreateForm: React.FC = observer(() => {
    const { item } = useStore('role');
    const { dirty, values, handleChange, handleSubmit } = useFormik({
        initialValues: { title: '' },
        onSubmit: (data) => {
            if (dirty) {
                item.createOne(data);
            }
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (item.data?.id) {
            navigate(`/admin/roles/${item.data.id}/edit`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.data?.id]);

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Название роли"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <div className="mt-4" />
            <FormBackOrSubmitButtons backTitle="К списку" backPath="/admin/roles" disabled={!dirty} />
        </Form>
    );
});

export default RoleCreateForm;
