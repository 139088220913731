import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import routes, { isCollapsedRoute, isSimpleRoute } from 'routes';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs: React.FC = () => {
    const location = useLocation();

    const [, layout, path] = location.pathname.split('/');

    const currentRoute: { pathName?: string; rootName?: string } = routes.reduce((acc, route) => {
        if (isCollapsedRoute(route) && route.collapse && route.views?.length > 0) {
            const collapsedRoute = route.views.find((item) => item.layout === `/${layout}` && item.path === `/${path}`);

            if (collapsedRoute) {
                return {
                    pathName: collapsedRoute.name,
                    rootName: route.name,
                };
            }
        }

        if (isSimpleRoute(route) && route.layout === `/${layout}` && route.path === `/${path}`) {
            return { pathName: route.name };
        }

        return acc;
    }, {});

    if (isEmpty(currentRoute)) {
        return null;
    }

    return (
        <Breadcrumb className="d-none d-md-inline-block ml-md-4" listClassName="breadcrumb-links">
            <BreadcrumbItem>
                <Link to={`/${layout}`}>
                    <i className="fas fa-home" />
                </Link>
            </BreadcrumbItem>
            {!isUndefined(currentRoute.rootName) && <BreadcrumbItem>{currentRoute.rootName}</BreadcrumbItem>}
            {!isUndefined(currentRoute.pathName) && (
                <BreadcrumbItem>
                    <Link to={`/${layout}/${path}`}>{currentRoute.pathName}</Link>
                </BreadcrumbItem>
            )}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
