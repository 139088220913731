import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';

import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import FormTextInput from 'components/inputs/FormTextInput';
import adviceStore, { AdviceStatus } from 'store/advice';
import FormBackButton from 'components/buttons/FormBackButton';
import { adviceApi } from '../../../api';

interface ITagEditForm {
    store: typeof adviceStore.item;
}

//const AdviceEditForm: React.FC<ITagEditForm> = ({ store }) => {
const AdviceEditForm = ({ store }: ITagEditForm) => {
    useEffect(() => {
        //для установки отметки о прочтении напрямую вызываем api,
        //чтобы избежать перерисовки компонента при возникновении ошибки (например, при отсутствии прав)
        const stubCheckAdvice = async () => {
            if (store.data?.id && store.data?.status === AdviceStatus.NEW)
                try {
                    await adviceApi.updateOne(store.data.id, {
                        status: AdviceStatus.IN_PROCESS,
                    });
                    notifyStore.showNotify({
                        place: 'br',
                        message: `Предложение прочитано`,
                        type: 'success',
                    } as AlertOptions);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
        };
        stubCheckAdvice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = store.data ?? {
        email: '',
        text: '',
        status: 'NEW',
        createdAt: new Date(),
        userCommonName: '',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: () => {},
    });

    return (
        <Form onSubmit={formik.handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput title="Email" name="email" value={initialValues.email} disabled={true} />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        type="textarea"
                        title="Содержание"
                        name="text"
                        value={initialValues.text}
                        disabled={true}
                        rows={7}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="3">
                    <FormTextInput title="Статус" name="status" value={initialValues.status} disabled={true} />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Дата создания"
                        name="createdAt"
                        value={formatDate(initialValues.createdAt, config.formats.time)}
                        disabled={true}
                    />
                </Col>
            </Row>
            <FormBackButton backPath="/admin/advices" />
        </Form>
    );
};

export default AdviceEditForm;
