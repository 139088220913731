import React from 'react';
import { v4 as uuid } from 'uuid';
import { FormGroup, Input, Label, Row, Col } from 'reactstrap';

import CloseButton from 'components/buttons/CloseButton';

interface IData {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClose: () => void;
    placeholder?: string;
    label?: string;
    value?: number;
}

const ItemCheckbox: React.FC<IData> = ({ handleClose, placeholder, label, onChange, value }) => {
    const v = uuid();

    return (
        <Row>
            <Col className="px-0">
                <FormGroup check={true} className="mb-0 border border-2 rounded">
                    <Input
                        type="checkbox"
                        checked={value !== 0}
                        onChange={onChange}
                        placeholder={placeholder}
                        id={`idCheck${v}`}
                    />
                    <Label for={`idCheck${v}`} check={true} style={{ fontSize: '0.9em' }}>
                        {label}
                    </Label>
                </FormGroup>
            </Col>
            <CloseButton handleClose={handleClose} />
        </Row>
    );
};

export default ItemCheckbox;
