import React, { useEffect, useRef } from 'react';
import Choices from 'choices.js';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { FcmTtlUnit, getFcmTtlUnit } from 'types';

//константный список:
//компонент Choices отказывается работать со значением number, поэтому все преобразуем в string
const choicesListItem = (v: FcmTtlUnit) => ({
    value: String(v), //строковое значение элемента
    label: getFcmTtlUnit(v), //отображаемое в списке значение
    valueId: v, //внутренний id элемента
});
const choicesList = [
    choicesListItem(FcmTtlUnit.WEEK),
    choicesListItem(FcmTtlUnit.DAY),
    choicesListItem(FcmTtlUnit.HOUR),
    choicesListItem(FcmTtlUnit.MINUTE),
];

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    label?: string;
}
const TtlUnitSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    label,
}: Props<T>) => {
    const { field } = useController({
        name,
        control,
    });
    //ссылка на объект Choices
    const ch = useRef<Choices | null>(null);

    useEffect(() => {
        const choices = new Choices(`#choices-select-${name}`, {
            searchEnabled: false,
            delimiter: ',',
            removeItemButton: false,
            maxItemCount: 1,
            paste: false,
            placeholder: true,
            resetScrollPosition: false,
            placeholderValue: 'Выберите значение',
            noResultsText: 'Ничего не найдено',
            noChoicesText: 'Нет элементов для выбора',
            itemSelectText: 'Нажмите для выбора',
            loadingText: 'Загрузка...',
            maxItemText: `Только 1 элемент может быть выбран`,
            choices: choicesList.map((item) =>
                item.valueId === field.value ? { value: item.value, label: item.label, selected: true } : item
            ),
            shouldSort: false,
        });
        ch.current = choices;

        return () => {
            if (ch.current) {
                ch.current.destroy();
                ch.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {label && (
                <label className="form-control-label" htmlFor={`choices-select-${name}`}>
                    {label}
                </label>
            )}
            <select
                value={field.value}
                onChange={() => {
                    if (ch.current) {
                        //при выборе элемента находим его в константном списке по строковому значению и возвращаем внутренний id
                        const value = ch.current.getValue(true) as string;
                        const i = choicesList.findIndex((item) => item.value === value);
                        if (i !== -1) field.onChange(choicesList[i].valueId);
                    }
                }}
                aria-label={name}
                className="form-control"
                id={`choices-select-${name}`}
            />
        </React.Fragment>
    );
};

export default TtlUnitSelect;
