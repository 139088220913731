import React from 'react';
import { Row, Col } from 'reactstrap';

import { IGetSelectParams, TSelectResponse } from 'api';
import CloseButton from 'components/buttons/CloseButton';
import ChoicesSelectFromRemote2 from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote2';

interface IProps {
    name: string;
    value: number[];
    label: string;
    onChange: (ids: number[]) => void;
    handleClose: () => void;
    getSelect?: (params: IGetSelectParams) => TSelectResponse;
    shouldSort?: boolean;
}

const ItemChoiceRemote: React.FC<IProps> = ({ name, value, label, handleClose, onChange, getSelect, shouldSort }) => {
    if (!getSelect) throw new Error('ItemChoiceRemote: не определен интерфейс getSelect');

    return (
        <Row>
            <Col className="px-0">
                <ChoicesSelectFromRemote2
                    name={name}
                    label={label}
                    value={value}
                    onChange={onChange}
                    getSelect={getSelect}
                    shouldSort={shouldSort}
                />
            </Col>
            <CloseButton handleClose={handleClose} />
        </Row>
    );
};

export default ItemChoiceRemote;
