import React from 'react';

import { Container, Row, Col } from 'reactstrap';

interface IAdminViewHeader {
    title: string;
}

const AdminViewHeader: React.FC<IAdminViewHeader> = ({ title, children }) => {
    return (
        <React.Fragment>
            <div className="header pb-6">
                <Container fluid={true}>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 d-inline-block mb-0">{title}</h6>
                            </Col>
                            <Col className="text-right" lg="6" xs="5">
                                {children}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AdminViewHeader;
