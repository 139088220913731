import React from 'react';

import ItemCreate from 'components/create/ItemCreate';
import TagCreateForm from 'views/tags/TagCreate/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const TagCreate: React.FC = () => {
    useResetListIfGoOut('tag', 'tags');

    return (
        <ItemCreate title="Добавить тэг" headerTitle="Новый тэг">
            <TagCreateForm />
        </ItemCreate>
    );
};

export default TagCreate;
