import { ListItemsStores } from 'lib/types';
import { useStore } from 'store';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useResetListIfGoOut = (storeName: ListItemsStores, pathName: string) => {
    const { list: listStore } = useStore(storeName);
    const location = useLocation();

    useEffect(() => {
        return () => {
            const [, , pathToGo] = location.pathname.split('/');
            if (pathToGo !== pathName) {
                listStore.resetState();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
};

export default useResetListIfGoOut;
