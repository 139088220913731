import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';

import { useStore } from 'store';
import AdminViewHeader from 'components/headers/AdminViewHeader';
import StationEditForm from 'views/stations/Components/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const StationCreate: React.FC = () => {
    const { item } = useStore('station');
    useResetListIfGoOut('station', 'stations');

    return (
        <React.Fragment>
            <AdminViewHeader title="Создать филиал" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="pb-0">
                                <h3 className="mb-0">Новый филиал</h3>
                            </CardHeader>
                            <CardBody>
                                <StationEditForm item={item} mode="create" isAllowEditExternalId={false} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default StationCreate;
