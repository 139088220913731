import React, { useRef } from 'react';
import { CardHeader, Card, CardBody, TabContent, TabPane } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import { getSearchValue } from 'lib/tools';
import Tabs from 'components/navs/Tabs';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { getMonthList, getYearList, getLocalDateInfo } from 'lib/datetime';
import { useTabs, useUnsavedTabs } from './tabs';
import Params from './Params';
import EventHtmlEditor from './EventHtmlEditor';
import { IEditorData, IParamsContent } from '../types';

const monthList = getMonthList();
const yearList = getYearList(3);

const EventHtml: React.FC = () => {
    const location = useLocation();
    const { tabList, activeTab, setTab, setTabState } = useTabs(Number(getSearchValue(location.search, 'tabId')) || 1);
    const { setTabWithCheck, setUnsavedTab, changeTabRequest, setChangeTabRequest } = useUnsavedTabs(activeTab, setTab);
    //метаданные
    const editorData = useRef<IEditorData>({ pattern: '', style: '', fonts: '', content: '' });
    const setEditorData = (v: IEditorData) => {
        editorData.current = v;
    };
    const getEditorData = () => editorData.current;

    //default данные
    const defaultParams = useRef<IParamsContent>({
        cover: '',
        coverId: 0,
        month: monthList[getLocalDateInfo().month],
        year: yearList[0],
        themes: [1, 2, 3, 4, 21],
        showImages: true,
    });
    const getDefaultParams = () => defaultParams.current;
    const setDefaultParams = (params: IParamsContent) => {
        defaultParams.current = params;
    };

    //значение этой функции будет установлено непосредственно в закладке, которая редактирует данные
    const savingFunction = useRef<() => Promise<boolean>>(async () => false);
    const setSavingFunction = useRef((f: () => Promise<boolean>) => {
        savingFunction.current = f;
    }).current;

    return (
        <Card className="mx-2">
            <CardHeader className="pb-0">
                <Tabs tabs={tabList} activeTab={activeTab} setTab={(tabId: number) => setTabWithCheck(true, tabId)} />
            </CardHeader>
            <CardBody>
                <TabContent activeTab={`tabs${activeTab}`}>
                    {activeTab === 1 && ( //страница Параметры
                        <TabPane tabId={`tabs${activeTab}`}>
                            <Params
                                setTabState={setTabState}
                                setTab={setTab}
                                setEditorData={setEditorData}
                                getDefaultParams={getDefaultParams}
                                setDefaultParams={setDefaultParams}
                            />
                        </TabPane>
                    )}

                    {activeTab === 2 && ( //страница Редактор
                        <TabPane tabId={`tabs${activeTab}`}>
                            <EventHtmlEditor
                                getEditorData={getEditorData}
                                setUnsavedTab={(isSaved: boolean) => setUnsavedTab(activeTab, isSaved)}
                                setSavingFunction={setSavingFunction}
                            />
                        </TabPane>
                    )}
                </TabContent>
            </CardBody>

            {/* модальное окно запроса на смену закладки */}
            {changeTabRequest && (
                <ConfirmationModal
                    isOpen={changeTabRequest}
                    toggleOpen={setChangeTabRequest}
                    title="Переход на другую закладку"
                    text="Остались несохраненные данные. Возможно, их следует сохранить перед переходом?"
                    buttonTitle="Сохранить и перейти"
                    callback={async () => {
                        let success = true;
                        if (savingFunction.current) success = await savingFunction.current();
                        //await sleep(0);
                        if (success) setTabWithCheck(false);
                    }}
                    buttonTitle2="Не сохранять и перейти"
                    callback2={() => setTabWithCheck(false)}
                    modalSize="modal-lg"
                />
            )}
        </Card>
    );
};

export default EventHtml;
