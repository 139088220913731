import { useQuery, useMutation, useQueryClient } from 'react-query';

import { noticeApi as itemApi } from 'api';

export interface IItem {
    content: Array<{
        noticeId: number;
        active: boolean;
        email: string;
        title: string;
    }>;
}

//ключ кеша
const queryKey = 'noticeArray';

//*************************************************************************************************************
//чтение
async function itemRead(): Promise<IItem> {
    const response = await itemApi.get();
    return response.data;
}
export function useRqItemRead() {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
async function itemUpdate(item: IItem): Promise<IItem> {
    const response = await itemApi.update(item);
    return response.data;
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (item: IItem) => itemUpdate(item),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
