import { makeAutoObservable } from 'mobx';

import { loggerApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface ILoggerInList {
    id: number;
    context: string;
}

export interface ILogger {
    id: number;
    context: string;
    message: string;
    instance: number;
    level: string;
    createdAt: Date;
    parameters: string;
}

export interface ILoggerUpdate {
    context?: string;
    message?: string;
    instance?: number;
    level?: string;
    createdAt?: Date;
    parameters?: string;
}

export interface ILoggerCreate {
    context?: string;
    message?: string;
    instance?: number;
    level?: string;
    createdAt?: Date;
    parameters?: string;
}

class LoggerStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<ILogger, ILoggerUpdate, ILoggerCreate>(loggerApi);

    readonly list = new ListStore<ILoggerInList>(loggerApi);
}

export default new LoggerStore();
