import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { useLocation } from 'react-router-dom';

import ErrorWarning from 'components/errors/ErrorWarning';
import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import ItemForm from '../Components/Form';
import { useRqItemRead } from '../queries';

const ItemEdit: React.FC = () => {
    const id = Number(useLocation().pathname.split('/').slice(-2)[0]);
    //чтение
    const rqItem = useRqItemRead(id);

    //isLoading из useQuery срабатывает, когда идет первоначальная загрузка данных
    if (rqItem.isLoading || rqItem.isFetching) return <Loader />;
    if (rqItem.isError) return <ErrorWarning error={rqItem.error as Error} />;
    if (isUndefined(rqItem.data)) return null;

    return (
        <React.Fragment>
            <AdminViewHeader title="Редактировать канал">
                <span className="h1 btn-inner--icon text-green">
                    <i className="fas fa-podcast" />
                </span>
            </AdminViewHeader>
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Канал ID: {id}</h3>
                            </CardHeader>
                            <CardBody>
                                <ItemForm id={id} content={rqItem.data} mode="edit" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ItemEdit;
