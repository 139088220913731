import { useQuery, useMutation } from 'react-query';

import { serviceApi } from 'api';

export type TResult = { data: { result: string } };

//*************************************************************************************************************
//чтение списка операций
export function useOperList() {
    return useQuery(['operList'], async () => {
        const { data } = await serviceApi.getServiceOperations();

        return data;
    });
}

//*************************************************************************************************************
//выполнение операции
//getPID - исполняемая функция, если endpoint === 'getPID'
export const useOperExec = (getPID: () => Promise<TResult>) => {
    return useMutation((endpoint: string) => {
        let retval: Promise<TResult>;
        if (endpoint === 'getPID') retval = getPID();
        else retval = serviceApi.runServiceOperation(endpoint);

        return retval;
    });
};
