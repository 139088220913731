import { useQuery } from 'react-query';

import { fcmApi as itemApi } from 'api';
import { FcmStatus } from 'types';
import { IItem } from '../types';

//ключ кеша
const queryKeys = {
    detail: (id: number) => ['fcmContent', 'detail', id] as const,
};

//*************************************************************************************************************
//описание уведомления
async function itemRead(id: number): Promise<IItem> {
    return (await itemApi.getOne(id)).data;
}
export function useRqFcmStatus(id: number) {
    return useQuery({
        queryKey: queryKeys.detail(id),
        queryFn: () => itemRead(id),
        staleTime: 0,
        //если уведомление в процессе обработки, то refetch, иначе однократное чтение
        refetchInterval: (data /*, query*/) => {
            if (data?.status === FcmStatus.INPROCESS) return 3000;
            return false;
        },
    });
}
