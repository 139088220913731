import React from 'react';
import { Col, Row } from 'reactstrap';
import { isUndefined } from 'lodash';

import { IAccess, TPermission } from 'store/role';
import FormSelect from 'components/inputs/FormSelect';
import items, { TItemName } from 'lib/items';

interface ISectionPermissions {
    permissions: TPermission;
    handleChange: <T>(arg: T) => void;
    disabled?: boolean;
}

const SectionPermissions: React.FC<ISectionPermissions> = ({ permissions, handleChange, disabled }) => {
    const defaultAccessObject = {
        read: 'Чтение',
        update: 'Изменение',
        create: 'Создание',
        delete: 'Удаление',
    };

    const getSortedAccessCheckFields = (access: IAccess, sectionName: string) =>
        Object.keys(defaultAccessObject).map((accessName) => (
            <Col key={`${sectionName}.${accessName}`}>
                <FormSelect
                    disabled={disabled}
                    name={`permissions.${sectionName}.${accessName}`}
                    value={access[accessName as keyof IAccess]}
                    handleChange={handleChange}
                />
            </Col>
        ));

    //в строках не показываем права для mobilers (используется .filter)
    //эта секция вторична. все права едины с секцией users
    return (
        <React.Fragment>
            {Object.entries(items)
                .filter(([sectionName]) => sectionName !== 'mobilers')
                .map(([sectionName, sectionTitle]) => {
                    return (
                        <Row key={sectionName}>
                            <Col>{sectionName === 'users' ? 'Пользователи' : sectionTitle}</Col>
                            {!isUndefined(permissions[sectionName as TItemName]) &&
                                getSortedAccessCheckFields(permissions[sectionName as TItemName], sectionName)}
                        </Row>
                    );
                })}
        </React.Fragment>
    );
};

export default SectionPermissions;
