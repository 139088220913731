import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

import { smiApi } from 'api';
// import notifyStore from 'store/notification';
// import { AlertOptions } from 'react-notification-alert';
import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Header, { TAction } from 'components/headers/ItemListHeaderEx';
// import { useRqParser } from './queries';

// const notify = (message: string, type: string) =>
//     notifyStore.showNotify({
//         place: 'br',
//         message,
//         type,
//     } as AlertOptions);

const JournalList: React.FC = observer(() => {
    const storeName = 'journal';
    const navigate = useNavigate();
    //парсер
    // const rqParser = useRqParser();

    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.journals;
    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            //formatter: (text, row) => <TableTextTooltipField id={`${row.id}-title`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '50em',
                maxWidth: '50em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'isPublished',
            text: 'Опубликовано',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (isPublished) => (isPublished ? 'Да' : 'Нет'),
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'publishedAt',
            text: 'Дата публикации',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            style: {
                width: '12em',
            },
        },
    ];

    //*************************************************************************************************************
    //Выполнить парсинг сайта
    // const handleParser = async () => {
    //     rqParser.mutate(undefined, {
    //         onSuccess: (data) => {
    //             //первое свойство 'data' - от reactquery, второе - от axios
    //             const { count } = data.data;
    //             notify(
    //                 count > 0 ? `Добавлено выпусков: ${count}` : 'Новых выпусков нет',
    //                 count > 0 ? 'success' : 'info'
    //             );
    //         },
    //     });
    // };

    //*************************************************************************************************************
    //Добавить действия
    const buttons: TAction[] = [];
    if (userAccess?.create === true) {
        buttons.push({ name: 'Добавить выпуск', handle: () => navigate('/admin/journals/create') });
        //buttons.push({ name: 'Скопировать с сайта', handle: handleParser });
    }

    useResetListIfGoOut(storeName, 'journals');

    return (
        <ItemsListTable
            filters={[
                {
                    type: 'multiselect',
                    name: 'smi',
                    filterTitle: 'Список СМИ',
                    dropdownItemTitle: 'Список СМИ',
                    getSelect: smiApi.getSelect,
                    shouldSort: false,
                },
                {
                    type: 'text',
                    name: 'textFilter',
                    filterTitle: 'Поиск по тексту',
                    dropdownItemTitle: 'Поиск по тексту',
                },
            ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                // sortField: 'title',
                // sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Журналы" buttons={buttons} />
        </ItemsListTable>
    );
});

export default JournalList;
