import { set, getDay } from 'date-fns';

import { padLeft } from './str';

export function toHMMSS(value: number) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

//****************************************************************************************
//Составить дату из строки типа 'Июнь 1, 2022'
export const toDateFromStr = (s: string) => {
    const a = s.toUpperCase().split(' ');

    //месяц
    const month: { [key: string]: number } = {
        ЯНВАРЬ: 0,
        ФЕВРАЛЬ: 1,
        МАРТ: 2,
        АПРЕЛЬ: 3,
        МАЙ: 4,
        ИЮНЬ: 5,
        ИЮЛЬ: 6,
        АВГУСТ: 7,
        СЕНТЯБРЬ: 8,
        ОКТЯБРЬ: 9,
        НОЯБРЬ: 10,
        ДЕКАБРЬ: 11,
    };

    return new Date(Number(a[2]), Number(month[a[0]]), Number(a[1].substring(0, a[1].length - 1)));
};

//вернуть дату с нулевым временем
export const setZeroTime = (date: Date) => {
    return set(date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
};

//какой день недели d, от 1 до 7
export const getDayInWeek = (d: Date) => Number(getDay(d)) || 7;

//список месяцев
export const getMonthList = () => [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
];

//список ближайших N лет
export const getYearList = (n: number) => {
    const a: string[] = [];
    const d = new Date();
    let year = d.getFullYear();
    for (let i = 1; i <= n; i += 1) {
        a.push(String(year));
        year += 1;
    }

    return a;
};

//инфо о текущей локальной дате
export const getLocalDateInfo = () => {
    const d = new Date();
    return {
        timezoneOffset: d.getTimezoneOffset(),
        month: d.getMonth(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
};

//по исходной локальной дате получить новую локальную дату, по которой автоматом будет сформирована правильная utc строка при обращении к БД
//  !!! - автоформирование utc строки выполняет среда Typeorm в соответствии с таймзоной приложения
//пример для сервера в таймзоне +3 Москва (-180 минут) и целевой зоны Прага (+2 зима) (-120 минут)
//  local 2023-09-01   00:00           - исходная локальная дата, но считаем, что она относится к целевой таймзоне +2
//  utc   2023-08-31 T 22:00:00.000 Z  - такая UTC строка должна быть автоматом сформирована при обращении к базе
//  local 2023-09-01   01:00           - для этого в такую локальную дату в таймзоне сервера (+3) надо перевести исходную локальную дату перед обращением к БД
//используем zonedTimeToUtc(d, 'Europe/Budapest');

//по локальной дате, прочитанной из БД, получить дату в нужной таймзоне
//пример для сервера в таймзоне +3 Москва (-180 минут) и целевой зоны Прага (+2 зима) (-120 минут)
//  utc   2023-08-27 T 22:00:00.000 Z  - в базе хранится UTC
//  local 2023-08-28   01:00           - при чтении из базы автоматом сконвертировано в локальную таймзону +3
//  local 2023-08-28   00:00           - в такую дату надо перевести, соответствует таймзоне +2
//используем utcToZonedTime(d, 'Europe/Budapest');

//специфично для событий fullTime
//по исходной дате получить новую дату для автоформирования utc строки с заданной датой и нулевым временем при обращении к БД
//дата не зависит от таймзоны и равна конкретному указанному значению
//  !!! - автоформирование utc строки выполняет среда Typeorm в соответствии с таймзоной приложения
//пример для приложения (сервера) в таймзоне +3 Москва (-180 минут) и целевой зоны +1 Прага (-60 минут)
//  local 2023-09-01   04:44           - исходная дата (не UTC), считаем, что из нее надо только забрать дату
//  utc   2023-09-01 T 00:00:00.000 Z  - такая UTC строка должна быть автоматом сформирована при обращении к базе
export const toFulltimeStringDB = (date: Date) => {
    return `${date.getFullYear()}-${padLeft(date.getMonth() + 1, 2)}-${padLeft(date.getDate(), 2)}T00:00:00.000Z`;
};
export const toFulltimeDB = (date: Date) => {
    return new Date(toFulltimeStringDB(date));
};

//специфично для событий fullTime
//по дате, прочитанной из БД, получить UTC дату в локальной таймзоне
//пример для приложения (сервера) в таймзоне +3 Москва (-180 минут)
//  utc   2023-08-28 T 22:00:00.000 Z  - в базе хранится UTC, время = 0
//  local 2023-08-29   01:00           - при чтении из базы автоматом сохранено в локальную таймзону +3 приложения
//  local 2023-08-28   00:00           - надо вернуть локальную дату, созданную из UTC значений
export const toFulltime = (date: Date) => {
    const d = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return d;
};

//равно toFulltime, но на вход принимает UTC строку
export const isoToFulltime = (iso: string | undefined) => {
    if (!iso) return undefined;
    return toFulltime(new Date(Date.parse(iso)));
};

//преобразовать UTC строку в локальную дату с учетом локальной таймзоны
//например, строка 2023-09-11T00:00:00.000Z для Москвы преобразуется в локальную дату 2023-09-11 03:00:00.000
export const isoToLocal = (iso: string | undefined) => {
    if (!iso) return undefined;
    return new Date(Date.parse(iso));
};

//разобрать строку ISO8601 - извлечь год, месяц, день
//например, строка 2023-08-31T23:00:00.000+01:00
//возвращает {year:2023, month:8, day:31}
export const parseISO8601 = (iso: string) => {
    return {
        year: Number(iso.substring(0, 4)),
        month: Number(iso.substring(5, 7)) - 1,
        day: Number(iso.substring(8, 10)),
    };
};
