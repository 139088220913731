import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toJS } from 'mobx';
import { isUndefined } from 'lodash';

import FormCheckBox from 'components/inputs/FormCheckBox';
import ReactDropzone from 'components/inputs/ReactDropzone';
import HTMLEditField from 'components/edit/HTMLEditor';
import FormTextInput from 'components/inputs/FormTextInput';
import ChoicesSelect from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import stationStore, { IStationUpdate, IStationCreate } from 'store/station';
import getEditedValues from 'lib/getEditedValues';
import { quizApi, channelApi } from 'api';

interface IStationEditForm {
    item: typeof stationStore.item;
    mode: string;
    isAllowEditExternalId: boolean;
}

const StationEditForm: React.FC<IStationEditForm> = observer(({ item, mode, isAllowEditExternalId }) => {
    const plainItem = toJS(item);

    //заполнить начальные значения
    let initialValues: IStationUpdate | IStationCreate = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initialValues = {
            title: plainItem.data?.title,
            icon: plainItem.data?.icon,
            cover: plainItem.data?.cover,
            editorData: plainItem.data?.editorData,
            quizId: plainItem.data?.quizId,
            channelId: plainItem.data?.channelId,
            externalId: plainItem.data?.externalId,
            isStation: plainItem.data?.isStation,
        };
    } else {
        //если создание, то занести пустые данные
        initialValues = {
            title: '',
            icon: '',
            cover: '',
            editorData: [],
            quizId: 0,
            channelId: 0,
            externalId: '',
            isStation: false,
        };
    }

    const validationSchema = yup.object().shape({
        title: yup.string().max(128, `Должно быть максимум 128 символов`).required(`Название не должно быть пустым`),
        editorData: yup.array().min(1, 'Необходим контент'),
        externalId: yup.string().notRequired(),
    });

    const { dirty, values, handleChange, handleSubmit, setFieldValue, /*, isValid, validateForm */ errors } = useFormik(
        {
            initialValues,
            validationSchema,
            validateOnMount: false,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (data) => {
                if (mode === 'edit') {
                    if (dirty) {
                        const editedValues = getEditedValues(initialValues, data);
                        if (!isUndefined(editedValues.quizId)) editedValues.quizId = editedValues.quizId || 0;
                        if (!isUndefined(editedValues.channelId)) editedValues.channelId = editedValues.channelId || 0;
                        item.updateOne(editedValues);
                    }
                } else if (dirty) {
                    item.createOne(data as IStationCreate);
                }
            },
        }
    );

    //после создания переход в список
    const navigate = useNavigate();
    useEffect(() => {
        if (mode !== 'edit' && plainItem.isCreated) {
            navigate(`/admin/stations`);
        }

        return () => item.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated, mode]);

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            {/* переключатель типа викторины */}
            <Row>
                <Col className="col-10 mt-3 mb-3">
                    <FormCheckBox
                        name="isStation"
                        title="Признак АЭС"
                        value={values.isStation || false}
                        handleChange={handleChange}
                        disabled={true} // чтобы запретить менять для станций
                    />
                </Col>
            </Row>
            {/* Название филиала */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        invalidText={errors.title}
                        title="Название филиала"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            {/* иконка */}
            <Row>
                <Col md="5" className="mb-3">
                    <ReactDropzone
                        buttonText="Загрузите иконку здесь"
                        name="icon"
                        label="Иконка"
                        setFieldValue={setFieldValue}
                        preloadedImageUrl={values.icon}
                        invalidText={errors.icon}
                        clearValueOnError={false}
                    />
                </Col>
            </Row>
            {/* обложка */}
            <Row>
                <Col md="8" className="mb-3">
                    <ReactDropzone
                        buttonText="Загрузите обложку здесь"
                        name="cover"
                        label="Обложка"
                        setFieldValue={setFieldValue}
                        preloadedImageUrl={values.cover}
                        invalidText={errors.cover}
                        clearValueOnError={false}
                    />
                </Col>
            </Row>
            {/* контент */}
            <Row>
                <Col className="mb-3">
                    <HTMLEditField
                        name="editorData"
                        label="Контент"
                        initialValue={values.editorData}
                        invalidText={errors.editorData as string}
                        setFieldValue={setFieldValue}
                    />
                </Col>
            </Row>
            {/* викторина */}
            <Row>
                <Col md="4" className="mb-3">
                    <ChoicesSelect
                        getSelect={quizApi.getSelect}
                        defaultSelect={values.quizId}
                        isRemoveButton={true}
                        placeholder="Укажите викторину"
                        name="quizId"
                        setFieldValue={setFieldValue}
                        label="Викторина"
                    />
                </Col>
            </Row>
            {/* канал */}
            <Row>
                <Col md="4" className="mb-3">
                    <ChoicesSelect
                        getSelect={channelApi.getSelect}
                        defaultSelect={values.channelId}
                        isRemoveButton={true}
                        placeholder="Укажите канал"
                        name="channelId"
                        setFieldValue={setFieldValue}
                        label="Канал"
                    />
                </Col>
            </Row>

            {/* uuid во внешней системе */}
            {isAllowEditExternalId && (
                <Row>
                    <Col className="mb-3" md="5">
                        <FormTextInput
                            invalidText={errors.externalId}
                            title="uuid во внешней системе"
                            name="externalId"
                            value={values.externalId}
                            handleChange={handleChange}
                            disabled={!isAllowEditExternalId}
                        />
                    </Col>
                </Row>
            )}

            <div className="mt-4" />
            <FormBackOrSubmitButtons backTitle="К списку" backPath="/admin/stations" disabled={!dirty} />
        </Form>
    );
});

export default StationEditForm;
