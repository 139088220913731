import React from 'react';

import ItemCreate from 'components/create/ItemCreate';
import UserCreateForm from 'views/users/UserCreate/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const UserCreate: React.FC = () => {
    useResetListIfGoOut('user', 'users');

    return (
        <ItemCreate title="Добавить пользователя" headerTitle="Новый пользователь">
            <UserCreateForm />
        </ItemCreate>
    );
};

export default UserCreate;
