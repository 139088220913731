import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import ErrorWarning from 'components/errors/ErrorWarning';
import SecurityForm from './Form';
import { useRqSecurityData } from './queries';

const Security: React.FC = () => {
    //чтение
    const securityData = useRqSecurityData();

    //isLoading из useQuery срабатывает, когда идет первоначальная загрузка данных
    if (securityData.isLoading || securityData.isFetching) return <Loader />;

    if (securityData.isError) return <ErrorWarning error={securityData.error as Error} />;
    if (isUndefined(securityData.data)) return null;

    return (
        <React.Fragment>
            <AdminViewHeader title="Изменить параметры безопасности">
                <span className="h1 btn-inner--icon text-red">
                    <i className="fas fa-shield-alt" />
                </span>
            </AdminViewHeader>
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <SecurityForm securityArray={securityData.data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Security;
