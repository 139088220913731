import React from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { isEmpty, pickBy, isUndefined } from 'lodash';
import 'moment/locale/ru';

import { IGetSelectParams, TSelectResponse } from 'api';
import { stat } from 'lib/stat';
import ItemText from 'components/filters/ItemText';
import ItemCheckbox from 'components/filters/ItemCheckbox';
import ItemChoiceRemote from 'components/filters/ItemChoiceRemote';
import CloseButton from 'components/buttons/CloseButton';
import { stores } from 'store';
import { ListItemsStores } from 'lib/types';
import { observer } from 'mobx-react-lite';

interface IGetFieldProps {
    filterName: string;
    filterTitle: string;
    listStore: typeof stores[ListItemsStores]['list'];
    eventDescriptor?: string;
    getSelect?: (params: IGetSelectParams) => TSelectResponse;
    shouldSort?: boolean;
}

//****************************************************************************************
//Список разрешенных типов фильров
const filterTypes = {
    //multiselect
    multiselect: {
        getField: ({ filterName, filterTitle, listStore, getSelect, shouldSort }: IGetFieldProps) => (
            <ItemChoiceRemote
                key={`filter-ItemChoiceRemote-${filterName}`}
                name={filterName}
                value={(listStore.queryParams.filters?.[filterName] as number[]) || []}
                label={filterTitle}
                onChange={(ids: number[]) => listStore.changeFilters({ [filterName]: ids })}
                handleClose={() => listStore.disableFilter(filterName)}
                getSelect={getSelect}
                shouldSort={shouldSort}
            />
        ),
    },
    //ввод текстовой строки
    text: {
        getField: ({ filterName, filterTitle, listStore }: IGetFieldProps) => (
            <ItemText
                key={`filter-ItemText-${filterName}`}
                value={(listStore.queryParams.filters?.[filterName] as string) || ''}
                onChange={(e) => listStore.changeFilters({ [filterName]: e.target.value })}
                label={filterTitle}
                handleClose={() => listStore.disableFilter(filterName)}
            />
        ),
    },
    //чекбокс
    checkbox: {
        getField: ({ filterName, filterTitle, listStore, eventDescriptor }: IGetFieldProps) => {
            return (
                //контрол работает с 0 и 1, а не с boolean
                //плюс неопределенное значение считаем за 0
                <ItemCheckbox
                    key={`filter-ItemCheckbox-${filterName}`}
                    value={
                        (!isUndefined(listStore.queryParams.filters?.[filterName]) &&
                        listStore.queryParams.filters?.[filterName] !== 0
                            ? 1
                            : 0) || 0
                    }
                    onChange={() => {
                        //событие
                        if (eventDescriptor) stat.addEvent({ name: eventDescriptor });
                        //применить фильтр
                        listStore.changeFilters({
                            [filterName]:
                                !isUndefined(listStore.queryParams.filters?.[filterName]) &&
                                listStore.queryParams.filters?.[filterName] !== 0
                                    ? 0
                                    : 1,
                        });
                    }}
                    label={filterTitle}
                    handleClose={() => listStore.disableFilter(filterName)}
                />
            );
        },
    },
};

//****************************************************************************************
//Отобразить элемент выпадающего списка фильров
const getDropdownItem = ({
    filterName,
    dropdownItemTitle,
    listStore,
}: {
    filterName: string;
    dropdownItemTitle: string;
    listStore: typeof stores[ListItemsStores]['list'];
}) => (
    <DropdownItem
        key={`filter-${dropdownItemTitle}`}
        disabled={listStore.filtersEnabled[filterName]}
        onClick={() => listStore.enableFilter(filterName)}
    >
        {dropdownItemTitle}
    </DropdownItem>
);

export interface IFiltersToolKit {
    listStore: typeof stores[ListItemsStores]['list'];
    filters: Array<{
        name: string;
        type: keyof typeof filterTypes;
        filterTitle: string;
        dropdownItemTitle: string;
        eventDescriptor?: string;
        getSelect?: (params: IGetSelectParams) => TSelectResponse;
        shouldSort?: boolean;
    }>;
}

const FiltersToolkit: React.FC<IFiltersToolKit> = observer(({ listStore, filters }) => {
    const isAllFiltersDisabled = isEmpty(pickBy(listStore.filtersEnabled, (value) => value));

    return (
        <Row>
            <Col>
                <Row className="mb-3 ml-3">
                    {filters.map((filter) => {
                        if (listStore.filtersEnabled[filter.name])
                            return (
                                <Col
                                    key={`FiltersToolkit-item-${filter.name}`}
                                    xs={12}
                                    md={6}
                                    className="d-flex flex-column align-items-stretch"
                                >
                                    {filterTypes[filter.type].getField({
                                        filterName: filter.name,
                                        filterTitle: filter.filterTitle,
                                        listStore,
                                        eventDescriptor: filter.eventDescriptor,
                                        getSelect: filter.getSelect,
                                        shouldSort: filter.shouldSort,
                                    })}
                                </Col>
                            );
                        return null;
                    })}
                </Row>
            </Col>
            <Col sm={12} md={12} lg={3} className="align-self-center">
                <div id="datatable-basic_filter" className="d-flex dataTables_filter px-4 pb-1 float-right mb-4 mt-4">
                    <CloseButton
                        title="Очистить все фильтры"
                        isHidden={isAllFiltersDisabled}
                        handleClose={() => listStore.disableAllFilters()}
                    />
                    <UncontrolledDropdown group={true}>
                        <DropdownToggle size="sm" caret={true} color="secondary">
                            Добавить фильтр
                        </DropdownToggle>
                        <DropdownMenu>
                            {filters.map((filter) =>
                                getDropdownItem({
                                    filterName: filter.name,
                                    dropdownItemTitle: filter.dropdownItemTitle,
                                    listStore,
                                })
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </Col>
        </Row>
    );
});

export default FiltersToolkit;
