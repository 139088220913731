import React from 'react';
import { FormGroup } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/ru';
import classnames from 'classnames';
import { FieldValues, UseControllerProps, Controller } from 'react-hook-form';

import InvalidLabel from 'components/labels/InvalidLabel';
import 'assets/css/date-select.css';
import { config } from 'lib/config';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    placeholder?: string;
    dateFormat?: string;
    timeFormat?: string | boolean;
    label?: string;
    invalidText?: string;
    disabled?: boolean;
}

const DateSelect = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    placeholder = 'Укажите дату',
    label,
    invalidText,
    dateFormat = config.formats.onlyDate,
    timeFormat = config.formats.onlyTime,
    disabled = false,
}: Props<T>) => {
    let pattern = config.formats.time;
    if (!timeFormat) pattern = config.formats.onlyDate;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormGroup className="mb-1">
                    {label && <label className="form-control-label">{label}</label>}
                    <ReactDatetime
                        initialValue={value ? moment(value).format(pattern) : undefined}
                        className={classnames({
                            'invalid-border': Boolean(invalidText),
                        })}
                        onChange={onChange}
                        locale="ru"
                        closeOnSelect={true}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        closeOnClickOutside={true}
                        inputProps={{
                            placeholder,
                            disabled,
                        }}
                    />
                    {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
                </FormGroup>
            )}
        />
    );
};

export default DateSelect;
