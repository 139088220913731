import React from 'react';
import { SizePerPageRendererOptions } from 'react-bootstrap-table-next';
import { PickPartialAndAsIs } from 'lib/types';

interface IPageSizeSelect extends PickPartialAndAsIs<SizePerPageRendererOptions, 'onSizePerPageChange'> {
    defaultValue: number;
}

const PageSizeSelect: React.FC<IPageSizeSelect> = ({ defaultValue, onSizePerPageChange }) => {
    return (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Показать по{' '}
                {
                    <select
                        defaultValue={defaultValue}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{' '}
                записей.
            </label>
        </div>
    );
};

export default PageSizeSelect;
