import { makeAutoObservable } from 'mobx';

import { TEditorBlockData } from 'components/edit/HTMLEditor/types';
import { stationApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IStationInList {
    id: number;
    title: string;
}

export interface IStation {
    id: number;
    title: string;
    icon: string;
    cover: string;
    editorData: TEditorBlockData[];
    quizId: number;
    channelId: number;
    externalId: string;
    isStation: boolean;
}

export interface IStationUpdate {
    title?: string;
    icon?: string;
    cover?: string;
    editorData?: TEditorBlockData[];
    quizId?: number;
    channelId?: number;
    externalId?: string;
    isStation?: boolean;
}

export interface IStationCreate {
    title: string;
    icon: string;
    cover: string;
    editorData: TEditorBlockData[];
    quizId?: number;
    channelId?: number;
    externalId?: string;
    isStation: boolean;
}

class StationStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IStation, IStationUpdate, IStationCreate>(stationApi);

    readonly list = new ListStore<IStationInList>(stationApi);
}

export default new StationStore();
