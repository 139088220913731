import { makeAutoObservable } from 'mobx';
import { mobilerApi } from 'api';

import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IMobilerInList {
    id: number;
    email: string;
    commonName: string;
    create: Date;
    status: number;
    profile: string;
}

export interface IMobiler {
    id: number;
    email: string;
    commonName: string;
    status: number;
}

export interface IMobilerSave {
    email?: string;
    commonName?: string;
    status?: number;
}

class MobilerStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IMobiler, IMobilerSave, IMobilerSave>(mobilerApi);

    list = new ListStore<IMobilerInList>(mobilerApi);
}

export default new MobilerStore();
