import moment from 'moment';

const formatDate = (date: Date, dateFormat: string): string => {
    const momentDate = moment(date);

    return momentDate.isValid() ? momentDate.format(dateFormat) : '';
};

// 2021-09-13 09:11:07.509 => '11:07'
export function dateToHHMMSS(date: Date) {
    if (!(date instanceof Date)) return '';
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
}

export default formatDate;
