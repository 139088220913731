import React, { useEffect, useState, useRef, memo, Dispatch, SetStateAction } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row, TabContent, TabPane } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toJS } from 'mobx';
import { isUndefined } from 'lodash';

import ReactDropzone from 'components/inputs/ReactDropzone';
import Loader from 'components/Loader';
import FormTextInput from 'components/inputs/FormTextInput';
import QuizFormButtons from 'components/buttons/QuizFormButtons';
import { useStore } from 'store';
import { IQuizUpdate, IMeta } from 'store/quiz';
import getEditedValues from 'lib/getEditedValues';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import FormDateSelect from 'components/inputs/FormDateSelect';
import FormCheckBox from 'components/inputs/FormCheckBox';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';
import { ErrorsModal, IErrorsRequest } from './ErrorsModal';
import { Questions, createEmptyQuestion } from './Questions';
import { Publication } from './Publication';
import { Explanation } from './Explanation';
import { Ratings, createEmptyRating } from './Ratings';
import { ITabs } from './Const';
import { createMetaByQuestion, createMetaByRating } from './tools';

interface IQuizEditForm {
    tabs: ITabs;
    setTabs: Dispatch<SetStateAction<ITabs>>;
    mode: string;
}

const QuizEditForm: React.FC<IQuizEditForm> = observer(({ tabs, setTabs, mode }) => {
    //номер закладки для перехода по нажатию кнопки
    const newTabNumberToGo = useRef(0);
    //метаданные
    const meta = useRef<IMeta>({ isFilled: false, isCover: false, questions: [], ratings: [] }).current;

    const itemStore = useStore('quiz');
    const { item } = itemStore;
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const plainItem = toJS(item);
    //показать/скрыть протокол ошибок
    const [errorsRequest, setErrorsRequest] = useState<IErrorsRequest>({ isOpen: false, data: [] });
    //показать/скрыть запрос на публикацию
    const [publishRequest, setPublishRequest] = useState(false);
    //показать/скрыть запрос на снятие с публикации
    const [unpublishRequest, setUnpublishRequest] = useState(false);

    if (mode === 'edit' && item.isLoading) return <Loader />;
    if (mode === 'edit' && isUndefined(item.data)) return null;

    //заполнить начальные значения
    const initialValues: IQuizUpdate = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initialValues.id = plainItem.data!.id; //всегда будет в прочитанной записи
        initialValues.title = plainItem.data?.title;
        initialValues.text = plainItem.data?.text;
        initialValues.cover = plainItem.data?.cover;
        initialValues.isCompetition = plainItem.data?.isCompetition;
        initialValues.isPublished = plainItem.data?.isPublished;
        initialValues.periodStart = plainItem.data?.periodStart;
        initialValues.periodStop = plainItem.data?.periodStop;
        initialValues.questions = plainItem.data?.questions;
        initialValues.ratings = plainItem.data?.ratings;
    } else {
        //если создание новой викторины, то занести пустые данные
        initialValues.title = '';
        initialValues.text = '';
        initialValues.cover = '';
        initialValues.isCompetition = false;
        initialValues.isPublished = false;
        initialValues.periodStart = undefined;
        initialValues.periodStop = undefined;
        //должен быть хотя бы один вопрос + 2 ответа
        initialValues.questions = [createEmptyQuestion()];
        //должно быть хотя бы два критерия
        const newRating1 = createEmptyRating();
        newRating1.maxCount = 1;
        const newRating2 = createEmptyRating();
        newRating2.maxCount = 2;
        initialValues.ratings = [newRating1, newRating2];
    }

    const validationSchema = yup.object().shape({
        title: yup.string().max(128, `Должно быть максимум 128 символов`).required(`Название не должно быть пустым`),
    });

    //заполнить meta по данным из initialValues
    if (!meta.isFilled) {
        meta.isFilled = true;
        meta.isCover = !!initialValues.cover;
        if (initialValues.questions) meta.questions = initialValues.questions.map((q) => createMetaByQuestion(q));
        if (initialValues.ratings) meta.ratings = initialValues.ratings.map((r) => createMetaByRating(r));
    }

    //установить сортировку вопросов и ответов numOrder
    //заполнить поле numOrder в каждом вопросе и ответе в соответствии с порядком в массиве значений
    /*eslint no-param-reassign: ["error", { "props": false }]*/
    const setNumOrder = (v: IQuizUpdate) => {
        if (!v || !v.questions) return;
        for (let i = 0; i < v.questions.length; i++) {
            v.questions[i].numOrder = i + 1;
            for (let j = 0; j < v.questions[i].answers.length; j++) {
                v.questions[i].answers[j].numOrder = j + 1;
            }
        }
    };

    const { dirty, values, handleChange, handleSubmit, setFieldValue, isValid, errors, validateForm } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            if (mode === 'edit') {
                if (dirty) {
                    const editedValues = getEditedValues(initialValues, data);
                    setNumOrder(editedValues); //установить сортировку вопросов и ответов numOrder
                    itemStore.indexLastTab = newTabNumberToGo.current;
                    item.updateOne(editedValues);
                }
                // //перейти на заданную закладку
                // if (newTabNumberToGo.current !== 0)
                //     setTabs((prev) => ({ ...prev, activeTab: newTabNumberToGo.current }));
            } else if (dirty) {
                setNumOrder(data); //установить сортировку вопросов и ответов numOrder
                item.createOne(data);
            }
        },
    });

    useEffect(() => {
        if (isFormSubmitted) {
            validateForm().then(() => {});
        }
    }, [values, isFormSubmitted, validateForm]);

    //проверить данные
    //возвращает массив ошибок
    /* eslint-disable complexity */
    const checkData = () => {
        const data: string[] = [];
        const questions = values.questions || [];
        const countQuestions = questions.length;
        const ratings = values.ratings || [];
        const countRatings = ratings.length;

        let message = 'должен быть хотя бы один вопрос';
        if (questions.length === 0) data.push(message);

        //цикл по всем вопросам
        for (let i = 0; i < questions.length; i++) {
            const answers = questions[i].answers || [];

            message = 'отсутствует описание';
            if (!questions[i].text && !questions[i].cover) data.push(`Вопрос ${i + 1}: ${message}`);

            message = 'в каждом вопросе должно быть хотя бы 2 ответа';
            if (answers.length < 2) data.push(`Вопрос ${i + 1}: ${message}`);

            message = 'отсутствует правильный ответ';
            if (answers.length > 1) {
                const index = answers.findIndex((a) => a.isCorrect === true);
                if (index === -1) data.push(`Вопрос ${i + 1}: ${message}`);
            }

            message = 'отсутствует неправильный ответ';
            if (answers.length > 1) {
                const index = answers.findIndex((a) => a.isCorrect === false);
                if (index === -1) data.push(`Вопрос ${i + 1}: ${message}`);
            }

            // message = 'отсутствует пояснение ответа';
            // if (!questions[i].explanation && !questions[i].errorExplanation) data.push(`Вопрос ${i + 1}: ${message}`);

            //цикл по всем ответам для данного вопроса
            for (let j = 0; j < answers.length; j++) {
                message = 'отсутствует описание';
                if (!answers[j].text && !answers[j].cover) data.push(`Вопрос ${i + 1}, ответ ${j + 1}: ${message}`);
            }
        }

        message = 'должен быть хотя бы один критерий оценки';
        if (countRatings === 0) data.push(message);

        message = 'значение последнего критерия оценки должно совпадать с количеством вопросов';
        if (countRatings > 0) {
            if (ratings[countRatings - 1].maxCount !== countQuestions) data.push(message);
        }

        //цикл по всем критериям
        for (let i = 0; i < countRatings; i++) {
            message = 'отсутствует описание';
            if (!ratings[i].text && !ratings[i].cover) data.push(`Критерий оценки ${i + 1}: ${message}`);

            message = 'значение критерия не может превышать числа вопросов';
            if (ratings[i].maxCount > countQuestions) data.push(`Критерий оценки ${i + 1}: ${message}`);
        }

        return data;
    };
    /* eslint-enable complexity */

    //перейти на заданную закладку
    const handleGoToTab = (newTabId: number) => {
        // if (newTabNumberToGo.current !== newTabId) {
        newTabNumberToGo.current = newTabId;
        setTabs((prev) => ({ ...prev, activeTab: newTabNumberToGo.current }));
        // }
    };

    //сохранить и остаться на прежней закладке
    const handleSave = () => {
        newTabNumberToGo.current = 0;
        //если сохранять нечего, то сообщаем об этом и возврат
        if (!dirty) {
            notifyStore.showNotify({
                place: 'br',
                message: `Викторина уже сохранена`,
                type: 'success',
            } as AlertOptions);
        }
        //если сохранение с проверкой
        if (values.isPublished) {
            const data = checkData();
            if (data.length) return setErrorsRequest({ isOpen: true, data });
        }
        //сохранение
        setFormSubmitted(true);
        handleSubmit();

        return undefined;
    };

    //сохранить викторину и перейти на следующую закладку
    //определить номер следующей закладки с учетом disabled
    const handleNext = () => {
        let newTabId = 0;
        //сначала находим в массиве индекс с текущей закладкой
        const indexActive = tabs.tabArray.findIndex((arrayItem) => arrayItem.tabId === tabs.activeTab);
        //потом смотрим следующую not disabled
        if (indexActive !== -1) {
            for (let i = indexActive + 1; i < tabs.tabArray.length; i++)
                if (!tabs.tabArray[i].disabled) {
                    newTabId = tabs.tabArray[i].tabId;
                    break;
                }
        }
        //если сохранение с проверкой
        if (values.isPublished) {
            const data = checkData();
            if (data.length) return setErrorsRequest({ isOpen: true, data });
        }
        //перейти на заданную закладку
        newTabNumberToGo.current = newTabId;
        if (newTabNumberToGo.current !== 0) setTabs((prev) => ({ ...prev, activeTab: newTabNumberToGo.current }));
        //сохранение
        setFormSubmitted(true);
        handleSubmit();

        return undefined;
    };

    //сохранить викторину и перейти на предыдущую закладку
    //определить номер прдыдущей закладки с учетом disabled
    const handlePrev = () => {
        let newTabId = 0;
        //сначала находим в массиве индекс с текущей закладкой
        const indexActive = tabs.tabArray.findIndex((arrayItem) => arrayItem.tabId === tabs.activeTab);
        //потом смотрим предыдущую not disabled
        if (indexActive !== -1) {
            for (let i = indexActive - 1; i >= 0; i--)
                if (!tabs.tabArray[i].disabled) {
                    newTabId = tabs.tabArray[i].tabId;
                    break;
                }
        }
        //если сохранение с проверкой
        if (values.isPublished) {
            const data = checkData();
            if (data.length) return setErrorsRequest({ isOpen: true, data });
        }
        //перейти на заданную закладку
        newTabNumberToGo.current = newTabId;
        if (newTabNumberToGo.current !== 0) setTabs((prev) => ({ ...prev, activeTab: newTabNumberToGo.current }));
        //сохранение
        setFormSubmitted(true);
        handleSubmit();

        return undefined;
    };

    //проверить и выдать запрос на опубликование
    const handleCheckAndPublishRequest = () => {
        //проверка
        const data = checkData();
        if (data.length) return setErrorsRequest({ isOpen: true, data });
        //запрос на опубликование
        setPublishRequest(true);

        return undefined;
    };

    //опубликовать
    const handlePublish = async () => {
        await setFieldValue(`isPublished`, true);
        setFormSubmitted(true);
        handleSubmit();
    };

    //снять с публикации
    const handleUnpublish = async () => {
        //newTabNumberToGo.current = 0;
        await setFieldValue(`isPublished`, false);
        setFormSubmitted(true);
        handleSubmit();
    };

    //после сохранения первой закладки переход в режим редактирования (по маршруту)
    const navigate = useNavigate();
    useEffect(() => {
        //как только объект создан, выполняем переход в режим редактирования переход на вторую закладку
        if (mode !== 'edit' && plainItem.isCreated) {
            itemStore.indexLastTab = 2;
            navigate(`/admin/quizes/${plainItem.data?.id}/edit`);
        }

        return () => item.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated, mode]);

    //поменять значение isCover (checkbox) для викторины
    const handleIsCover = async () => {
        const newValue = !meta.isCover;
        meta.isCover = newValue;
        //всегда обновляем значение => обновляем экран
        await setFieldValue(`cover`, '');
    };

    //поменять значение isCompetition (checkbox)
    const handleIsCompetition = async () => {
        const newValue = !values.isCompetition;
        await setFieldValue(`isCompetition`, newValue);
        if (!newValue) {
            await setFieldValue(`periodStart`, undefined);
            await setFieldValue(`periodStop`, undefined);
        }
    };

    return (
        <React.Fragment>
            <TabContent activeTab={`tabs${tabs.activeTab}`}>
                {tabs.activeTab === 1 && ( //страница Викторина
                    <TabPane tabId="tabs1">
                        {/* <Form onSubmit={handleSave} className="needs-validation" noValidate={true}> */}
                        <Form className="needs-validation" noValidate={true}>
                            <Row>
                                <Col md="8" className="mb-3">
                                    <FormTextInput
                                        invalidText={errors.title}
                                        title="Название"
                                        placeholder="Введите название"
                                        name="title"
                                        value={values.title}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="10" className="mb-3">
                                    <FormTextInput
                                        type="textarea"
                                        rows={4}
                                        invalidText={errors.text}
                                        title="Описание"
                                        placeholder="Введите описание"
                                        name="text"
                                        value={values.text}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>

                            {/* переключатель обложки */}
                            <Row>
                                <Col className="col-10 mb-3">
                                    <FormCheckBox
                                        name="isCover"
                                        title="С обложкой"
                                        value={meta.isCover}
                                        handleChange={() => handleIsCover()}
                                    />
                                </Col>
                            </Row>

                            {/* обложка */}
                            {meta.isCover && (
                                <Row>
                                    <Col md="10" className="mb-3">
                                        <ReactDropzone
                                            buttonText="Загрузите обложку здесь"
                                            name="cover"
                                            // label="Обложка"
                                            setFieldValue={setFieldValue}
                                            preloadedImageUrl={values.cover}
                                            invalidText={errors.cover}
                                            clearValueOnError={false}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {/* переключатель типа викторины */}
                            <Row>
                                <Col className="col-10 mt-3 mb-3">
                                    <FormCheckBox
                                        name="isCompetition"
                                        title="Викторина на результат"
                                        value={values.isCompetition || false}
                                        handleChange={handleIsCompetition}
                                    />
                                </Col>
                            </Row>

                            {/* период проведения */}
                            {values.isCompetition && (
                                <>
                                    <Row>
                                        <Col className="col-11">
                                            <span className="h4 form-control-label">Период проведения викторины:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="3" className="mb-3">
                                            <FormDateSelect
                                                name="periodStart"
                                                initialValue={values.periodStart}
                                                label="с"
                                                placeholder="Введите дату"
                                                setFieldValue={setFieldValue}
                                                timeFormat={false}
                                                // dateFormat="DD-MM-YYYY"
                                            />
                                        </Col>
                                        <Col md="3" className="mb-3">
                                            <FormDateSelect
                                                name="periodStop"
                                                initialValue={values.periodStop}
                                                label="по (включительно)"
                                                placeholder="Введите дату"
                                                setFieldValue={setFieldValue}
                                                timeFormat={false}
                                                // dateFormat="DD-MM-YYYY"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-11">
                                            <span className="h6">
                                                Для викторины на результат регистрация будет обязательна
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <div className="mt-4" />
                            <QuizFormButtons
                                onSave={mode === 'edit' ? handleSave : undefined}
                                onNext={handleNext}
                                isValid={isValid}
                                isDirty={dirty}
                                isPublished={values.isPublished}
                            />
                        </Form>
                    </TabPane>
                )}

                {tabs.activeTab === 2 && ( //страница Вопросы
                    <TabPane tabId="tabs2">
                        <Form className="needs-validation" noValidate={true}>
                            <Row>
                                <Col className="mb-3">
                                    <Questions
                                        value={values}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        errors={errors as Partial<IQuizUpdate> | undefined}
                                        meta={meta}
                                    />
                                </Col>
                            </Row>
                            <div className="mt-4" />
                            <QuizFormButtons
                                onPrev={handlePrev}
                                onSave={handleSave}
                                onNext={handleNext}
                                isValid={isValid}
                                isDirty={dirty}
                                isPublished={values.isPublished}
                            />
                        </Form>
                    </TabPane>
                )}

                {tabs.activeTab === 3 && ( //страница Пояснения
                    <TabPane tabId="tabs3">
                        <Form className="needs-validation" noValidate={true}>
                            <Row>
                                <Col className="mb-3">
                                    <Explanation
                                        value={values}
                                        handleChange={handleChange}
                                        errors={errors as Partial<IQuizUpdate> | undefined}
                                    />
                                </Col>
                            </Row>
                            <div className="mt-4" />
                            <QuizFormButtons
                                onPrev={handlePrev}
                                onSave={handleSave}
                                onNext={handleNext}
                                isValid={isValid}
                                isDirty={dirty}
                                isPublished={values.isPublished}
                            />
                        </Form>
                    </TabPane>
                )}

                {tabs.activeTab === 4 && ( //страница Результаты
                    <TabPane tabId="tabs4">
                        <Form className="needs-validation" noValidate={true}>
                            <Row>
                                <Col className="mb-3">
                                    <Ratings
                                        value={values}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        errors={errors as Partial<IQuizUpdate> | undefined}
                                        meta={meta}
                                    />
                                </Col>
                            </Row>
                            <div className="mt-4" />
                            <QuizFormButtons
                                onPrev={handlePrev}
                                onSave={handleSave}
                                onNext={handleNext}
                                isValid={isValid}
                                isDirty={dirty}
                                isPublished={values.isPublished}
                            />
                        </Form>
                    </TabPane>
                )}

                {tabs.activeTab === 5 && ( //страница Публикация
                    <TabPane tabId="tabs5">
                        <Form className="needs-validation" noValidate={true}>
                            <Row>
                                <Col className="mb-3">
                                    <Publication value={values} onGoToTab={handleGoToTab} />
                                </Col>
                            </Row>
                            <div className="mt-4" />
                            <QuizFormButtons
                                onPrev={handlePrev}
                                onSave={handleSave}
                                onPublish={handleCheckAndPublishRequest}
                                onUnpublish={() => setUnpublishRequest(true)}
                                isValid={isValid}
                                isDirty={dirty}
                                isPublished={values.isPublished}
                            />
                        </Form>
                    </TabPane>
                )}

                {/* модальное окно для протокола ошибок */}
                {errorsRequest.isOpen && (
                    <ErrorsModal
                        isOpen={errorsRequest.isOpen}
                        data={errorsRequest.data}
                        setErrorsRequest={setErrorsRequest}
                    />
                )}

                {/* модальное окно для опубликования */}
                {publishRequest && (
                    <ConfirmationModal
                        isOpen={publishRequest}
                        toggleOpen={setPublishRequest}
                        title="Подтверждение публикации"
                        text={`Викторина с ID: ${values.id} '${values.title}'' будет опубликована`}
                        callback={handlePublish}
                    />
                )}

                {/* модальное окно для снятия с публикации */}
                {unpublishRequest && (
                    <ConfirmationModal
                        isOpen={unpublishRequest}
                        toggleOpen={setUnpublishRequest}
                        title="Подтверждение снятия с публикации"
                        text={`Викторина с ID: ${values.id} '${values.title}'' будет снята с публикации`}
                        callback={handleUnpublish}
                    />
                )}
            </TabContent>
        </React.Fragment>
    );
});

export default memo(QuizEditForm);
