import { useQuery, useMutation, useQueryClient } from 'react-query';
import { isUndefined } from 'lodash';

import { channelApi as itemApi } from 'api';
import { IItemContent, IItem } from './types';

//*************************************************************************************************************
//Для работы требуется описание tags как массива id
//      tags?: number[];
//С сервера при чтении и update приходит массив объектов
//      tags?: { id: number; title: string }[];
//Поэтому нужна функция конвертации массива объектов в массив id
//
/* eslint-disable @typescript-eslint/no-explicit-any */
const convertData = (data: any): IItem => {
    const { tags, ...rest } = data;
    return { ...rest, ...(!isUndefined(tags) && { tags: tags.map(({ id }: { id: number }) => id) }) };
};

//ключ кеша
const queryKey = 'channelContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    return convertData((await itemApi.getOne(id)).data);
}
export function useRqItemRead(channelId: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(channelId),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    return convertData((await itemApi.updateOne(id, content)).data);
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    return convertData((await itemApi.createOne(content)).data);
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
