// *************************************************************************************************************
// Подключить аналитику Countly

import Countly from 'countly-sdk-web';

//описание элемента данных в событии
type TEventData = string | number;
//описание события
export interface IEvent {
    name: string;
    value?: Record<string, TEventData | Record<string, TEventData> | Array<Record<string, TEventData>>>;
}

/* eslint-disable import/prefer-default-export */
class Stat {
    private countly = Countly;

    private user_id = 0;

    //сохранить идентификатор активного пользователя
    set userId(value: number) {
        this.user_id = value;
    }

    init(app_key: string, url: string) {
        this.countly.init({
            app_key,
            url,
            //device_id: 'yury-demo-id', //Set only if you want dont want to use countly generated device_id
        });
        this.countly.q.push(['track_sessions']); // отслеживание сеансов
        this.countly.q.push(['track_pageview']); // отслеживание просмотров страниц
    }

    //сформировать событие
    //если есть активный пользователь, то добавить его id в событие
    addEvent(event: IEvent) {
        this.countly.q.push([
            'add_event',
            {
                key: event.name,
                count: 1,
                ...((event.value || this.user_id) && {
                    segmentation: { ...event.value, ...(this.user_id && { id_current_user: this.user_id }) },
                }),
            },
        ]);
    }
}

export const stat = new Stat();
