import React, { useState } from 'react';
import { FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';

import * as appConst from 'app.const';
import { dateToHHMMSS } from 'lib/formatDate';
import { useIsMounted } from 'lib/hooks';
import { authApi } from 'api';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { LoginStatus } from 'store/auth';
import StyledI from 'components/icons/StyledI';
import LoginBackground from './LoginBackground';
import LoginButton from './LoginButton';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [username, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isRemember, setIsRemember] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const authStore = useStore('auth');
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные запроса
    const [content, setContent] = useState({ message: '' });

    //*************************************************************************************************************
    //выполнить логин по введенным параметрам
    const handleSubmit = () => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .login({ username, password })
            .then((res) => {
                const { retval, token, ttl, repeatPeriod, expiresAt } = res.data;
                if (isMounted.current) {
                    authStore.setIsRemember(isRemember);
                    if (retval === LoginStatus.SUCCESS) {
                        authStore.setSuccessLogin(res.data);
                    } else if (retval === LoginStatus.PASSWORD_EXPIRED) {
                        navigate('/auth/password-expired');
                    } else if (retval === LoginStatus.CODE_SEND) {
                        authStore.setFactor2(token, ttl, repeatPeriod);
                        navigate('/auth/login-by-factor2-code');
                    } else if (retval === LoginStatus.LOGIN_LOCKED) {
                        setContent((prev) => ({ ...prev, message: 'логин временно заблокирован' }));
                    } else if (retval === LoginStatus.FAILED_CREDENTIALS) {
                        const message = expiresAt
                            ? `нет авторизации, блокировка до ${dateToHHMMSS(new Date(expiresAt))}`
                            : 'нет авторизации';
                        setContent((prev) => ({ ...prev, message }));
                    } else if (retval === LoginStatus.NOT_AUTHORIZED) {
                        setContent((prev) => ({ ...prev, message: 'нет доступа' }));
                    }
                }
            })
            .catch(() => {
                authStore.setUnSuccessLogin();
            })
            .finally(() => {
                //authStore.setIsRemember(isRemember);
                if (isMounted.current) setIsLoading(false);
            });
    };

    //*************************************************************************************************************
    //показать/скрыть пароль
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };
    // eslint-disable-next-line
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <LoginBackground>
            <div className="h2 text-left mb-3">Войти</div>
            <Form role="form">
                <FormGroup className="mb-2">
                    <InputGroup className="input-group-merge input-group-alternative">
                        <Input placeholder="Логин" type="text" onChange={(e) => setLogin(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-merge input-group-alternative">
                        <Input
                            placeholder="Пароль"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputGroupAddon
                            addonType="append"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            <InputGroupText>
                                <StyledI className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>

                {/* eslint-disable-next-line */}
                <div
                    className="h3 text-muted"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/auth/start-reset-password')}
                >
                    <small
                        style={{
                            color: appConst.textPrimaryColor,
                        }}
                    >
                        Я забыл пароль или не могу войти
                    </small>
                </div>

                <div className="custom-control custom-control-alternative custom-checkbox mt-3">
                    <input
                        className="custom-control-input"
                        id="customCheckLogin"
                        type="checkbox"
                        defaultChecked={isRemember}
                        onClick={() => setIsRemember((value) => !value)}
                    />
                    <label className="custom-control-label" htmlFor="customCheckLogin">
                        <span>Запомнить</span>
                    </label>
                </div>

                <LoginButton
                    isDisabled={isLoading || username.length < 2 || password.length < 8}
                    onClick={handleSubmit}
                    actionName="ВОЙТИ"
                    type="button"
                    isLoading={isLoading}
                />
            </Form>

            {content.message && (
                <div className="h3 text-center mt-1 mb-1">
                    <small className="text-danger">{content.message}</small>
                </div>
            )}
        </LoginBackground>
    );
};

export default Login;
