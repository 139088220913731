import { action, makeAutoObservable, observable } from 'mobx';
import { isUndefined } from 'lodash';

export interface IDropdownSelectRef {
    props: { defaultValue?: object };
    el: { append: (o: HTMLOptionElement) => { trigger: (s: string) => void } };
}

export interface IDefaultValue {
    text: string;
    value: string | number;
}

class SelectStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable selectRefs: {
        [id: string]: IDropdownSelectRef;
    } = {};

    @observable initializedSelects: {
        [id: string]: boolean;
    } = {};

    @action setSelectRef(id: string, ref: IDropdownSelectRef) {
        this.selectRefs[id] = ref;
    }

    @action setSelectDefault(id: string, defaultValue: IDefaultValue) {
        if (isUndefined(this.initializedSelects[id])) {
            this.updateValue(id, defaultValue);
            this.initializedSelects[id] = true;
        }
    }

    @action setMultipleSelectDefault(id: string, values: IDefaultValue[]) {
        if (isUndefined(this.initializedSelects[id])) {
            values.forEach((value) => this.updateValue(id, value));
            this.initializedSelects[id] = true;
        }
    }

    private updateValue(id: string, value: IDefaultValue) {
        const option = new Option(value.text, String(value.value), true, true);
        this.selectRefs[id]?.el.append(option).trigger('change');
    }

    @action eraseSelect(id: string) {
        delete this.selectRefs[id];
        delete this.initializedSelects[id];
    }
}

export default new SelectStore();
