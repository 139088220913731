import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileApi } from 'api';
import { isEmpty, isUndefined } from 'lodash';
import { Card, CardImg, CardText } from 'reactstrap';
import styled from 'styled-components';
import InvalidLabel from 'components/labels/InvalidLabel';

interface IProps {
    label?: string;
    invalidText?: string;
    preloadedImageUrl?: string;
    buttonText: string;
    setFileName: (name: string) => void; //имя созданного файла
    setFileId?: (id: number) => void; //id созданного файла
    disabled?: boolean;
    clearValueOnError?: boolean;
    height?: number;
}

const UploadContainer = styled.div<{ isLoaded: boolean; isInvalid: boolean; height: number }>`
    height: ${(props) => `${props.height}em`};
    cursor: pointer;
    border: ${(props) => {
        if (props.isLoaded) {
            return 'none';
        }

        return props.isInvalid ? '1px solid #fb6340' : '1px dashed #e5e5e5';
    }};
    border-radius: 5px;

    margin-bottom: 0 !important;
`;

const StyledCard = styled(Card)`
    position: relative;
    overflow: hidden;

    &::after {
        display: block;
        padding-bottom: 100%;
    }
`;

const StyledCardImg = styled(CardImg)`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const StyledCardText = styled(CardText)`
    font-weight: 400;
    color: #adb5bd;
`;

const ReactDropzone: React.FC<IProps> = ({
    label,
    buttonText,
    invalidText,
    preloadedImageUrl,
    setFileName,
    setFileId,
    disabled = false,
    clearValueOnError = true,
    height = 30,
}) => {
    const [imageUrl, setImage] = useState<string>();

    useEffect(() => {
        if (!isUndefined(preloadedImageUrl)) {
            setImage(preloadedImageUrl);
        }
    }, [preloadedImageUrl]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: 'image/*',
        onDrop: async (file) => {
            const { data } = await fileApi.createImageFile(file[0]);
            if (!isUndefined(data.file)) {
                setImage(data.file.url);
                setFileName(data.file.url);
                if (setFileId) setFileId(data.file.id);
            }
        },
        disabled,
    });

    const handleImgError = () => {
        setImage(undefined);
        if (clearValueOnError) setFileName('');
    };

    return (
        <React.Fragment>
            {label && <label className="form-control-label">{label}</label>}
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadContainer isInvalid={Boolean(invalidText)} isLoaded={!isEmpty(imageUrl)} height={height}>
                    <StyledCard className="text-center h-100 justify-content-center shadow-none mb-0">
                        {!isUndefined(imageUrl) && !isEmpty(imageUrl) ? (
                            <StyledCardImg
                                style={{ textAlign: 'center' }}
                                alt="Картинка не доступна"
                                src={imageUrl}
                                onError={() => handleImgError()}
                            />
                        ) : (
                            <StyledCardText>{buttonText}</StyledCardText>
                        )}
                    </StyledCard>
                </UploadContainer>
                {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
            </div>
        </React.Fragment>
    );
};

export default ReactDropzone;
