import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

interface IFormTextInput {
    name: string;
    value: boolean;
    handleChange: <T>(arg: T) => void;
    placeholder?: string;
    validText?: string;
    invalidText?: string;
    disabled?: boolean;
    title?: string;
}

const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormSelect: React.FC<IFormTextInput> = ({
    name,
    value,
    handleChange,
    placeholder,
    validText,
    invalidText,
    disabled,
    title,
}) => {
    return (
        <SelectContainer>
            {!isUndefined(title) && (
                <label className="form-control-label" htmlFor={name}>
                    {title}
                </label>
            )}
            <label className="custom-toggle">
                <input
                    disabled={disabled}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    type="checkbox"
                    defaultChecked={value}
                    onChange={handleChange}
                />
                <span className="custom-toggle-slider rounded-circle" />
            </label>
            <div className="valid-feedback">{validText}</div>
            <div className="invalid-feedback">{invalidText}</div>
        </SelectContainer>
    );
};

export default FormSelect;
