import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import JournalEditForm from 'views/journals/Components/Form';
import useItemEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const JournalEdit: React.FC = observer(() => {
    const { item, id } = useItemEditHooks({ storeName: 'journal' });
    useResetListIfGoOut('journal', 'journals');

    if (item.isLoading) return <Loader />;
    if (isUndefined(item.data)) return null;

    return (
        <React.Fragment>
            <AdminViewHeader title="Редактировать журнал" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Журнал ID: {id}</h3>
                            </CardHeader>
                            <CardBody>
                                <JournalEditForm item={item} mode="edit" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default JournalEdit;
