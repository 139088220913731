import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';

type TService =
    | 'youtube'
    | 'vimeo'
    | 'coub'
    | 'vine'
    | 'imgur'
    | 'gfycat'
    | 'twitch-channel'
    | 'yandex-music-album'
    | 'yandex-music-track'
    | 'codepen'
    | 'instagram'
    | 'twitter';

export enum BlockType {
    Embed = 'embed',
    Image = 'image',
    Paragraph = 'paragraph',
    Header = 'header',
    Raw = 'raw',
    Quote = 'quote',
}

export interface IRawBlock {
    id?: string;
    type: BlockType.Raw;
    data: {
        html: string;
    };
}

export interface IEmbedBlock {
    id?: string;
    type: BlockType.Embed;
    data: {
        service: TService;
        source: string;
        embed: string;
        width: number;
        height: number;
        caption: string;
    };
}

export interface IImageBlock {
    id?: string;
    type: BlockType.Image;
    data: {
        file: {
            url: string;
        };
        caption: string;
        withBorder: boolean;
        stretched: boolean;
        withBackground: boolean;
    };
}

export interface IParagraphBlock {
    id?: string;
    type: BlockType.Paragraph;
    data: {
        text: string;
    };
}

export interface IHeaderBlock {
    id?: string;
    type: BlockType.Header;
    data: {
        text: string;
        level: number;
    };
}

export interface IQuoteBlock {
    id?: string;
    type: BlockType.Quote;
    data: {
        text: string;
        caption: string;
        alignment: string;
    };
}

export type TEditorBlockData = IEmbedBlock | IImageBlock | IParagraphBlock | IHeaderBlock | IRawBlock | IQuoteBlock;

export const isRawBlock = (block: unknown) => (block as IRawBlock).type === BlockType.Raw;
export const isParagraphBlock = (block: unknown) => (block as IParagraphBlock).type === BlockType.Paragraph;
export const isImageBlock = (block: unknown) => (block as IImageBlock).type === BlockType.Image;
export const isHeaderBlock = (block: unknown) => (block as IHeaderBlock).type === BlockType.Header;
export const isQuoteBlock = (block: unknown) => (block as IQuoteBlock).type === BlockType.Quote;

export const createParagraphBlock = (text: string) => {
    return {
        id: nanoid(10),
        type: BlockType.Paragraph,
        data: {
            text,
        },
    } as IParagraphBlock;
};

export const createImageBlock = (url: string) => {
    return {
        id: nanoid(10),
        type: BlockType.Image,
        data: {
            file: {
                url,
            },
            caption: '',
            withBorder: false,
            stretched: false,
            withBackground: false,
        },
    } as IImageBlock;
};

export const cloneBlock = (block: TEditorBlockData) => {
    const newBlock = cloneDeep(block);
    newBlock.id = nanoid(10);

    return newBlock;
};
