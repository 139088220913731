import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import TableTextTooltipField from 'components/fields/TableTextTooltipField';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const QuizList: React.FC = observer(() => {
    const storeName = 'quiz';
    const store = useStore(storeName);
    const { list: listStore } = store;
    //индекс последнего развернутого вопроса. По умолчанию все свернуты.
    store.indexLastQuestion = -1;
    //индекс последней открытой закладки
    store.indexLastTab = 1;

    useEffect(() => {
        listStore.enableFilter('status');
    }, [listStore]);
    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.quizes;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            formatter: (text, row) => <TableTextTooltipField id={`${row.id}-title`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (isCompetition: boolean) => (isCompetition ? 'на результат' : 'инфо'),
            dataField: 'isCompetition',
            text: 'Тип',
            sort: true,
            style: {
                maxWidth: '20em',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (isPublished: boolean) => (isPublished ? 'да' : 'нет'),
            dataField: 'isPublished',
            text: 'Публикация',
            sort: true,
            style: {
                maxWidth: '20em',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'periodStart',
            text: 'Начало',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'periodStop',
            text: 'Окончание',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            dataField: 'userCommonName',
            text: 'Псевдоним',
            sort: true,
            formatter: (text, row) => (
                <TableTextTooltipField id={`${row.id}-userCommonName`} text={text} isShowTooltip={true} />
            ),
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: { title: 'Добавить викторину', path: '/admin/quizes/create' },
    };
    useResetListIfGoOut(storeName, 'quizes');

    return (
        <ItemsListTable
            // filters={[
            //     {
            //         type: 'checkbox',
            //         name: 'status',
            //         filterTitle: 'только новые предложения',
            //         dropdownItemTitle: 'статус',
            //     },
            // ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'id',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Викторины" {...creatingProp} />
        </ItemsListTable>
    );
});

export default QuizList;
