//цвета темы
export const backgroundPrimaryColor = '#118EFF';
export const textPrimaryColor = '#118EFF';

//имя ключа access token в sessionStorage
export const accessTokenKey = 'fb835b8e-3fcc-45d8-a99b-e2728e1e92bd';
//имя ключа refresh token в sessionStorage
export const refreshTokenKey = 'd57571bc-d9db-4d20-b7ea-42afa64471d7';

//список ограничений по возрасту
export const ageLimitList = ['0+', '6+', '12+', '16+', '18+'];

//max размер изображения для html календаря
export const maxEventHtmlImageSize = 150 * 1024;
