import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';
import * as yup from 'yup';

import { usePublicConfig } from 'lib/config';
import FormTextInput from 'components/inputs/FormTextInput';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import { useStore } from 'store';
import ChoicesSelect from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote';
import { roleApi } from 'api';

const UserCreateForm: React.FC = observer(() => {
    const { ready: configReady, publicConfig } = usePublicConfig();
    const { item } = useStore('user');
    const plainItem = toJS(item);
    const initialValues = {
        firstname: '',
        lastname: '',
        commonName: '',
        password: '',
        confirmationPassword: '',
        email: '',
        roleId: '',
    };

    const validate = (
        values: typeof initialValues
    ): {
        [K in keyof typeof initialValues]?: string;
    } => {
        if (values.password !== values.confirmationPassword) {
            return {
                password: 'Пароли должны совпадать',
                confirmationPassword: 'Пароли должны совпадать',
            };
        }

        return {};
    };

    const validationSchema = yup.object().shape({
        //hide-fio
        // firstname: yup
        //     .string()
        //     .min(2, `Должно быть минимум 2 символа`)
        //     .max(30, `Должно быть максимум 30 символов`)
        //     .required(`Поле не должно быть пустым`),
        // lastname: yup
        //     .string()
        //     .min(2, `Должно быть минимум 2 символа`)
        //     .max(30, `Должно быть максимум 30 символов`)
        //     .required(`Поле не должно быть пустым`),
        commonName: yup
            .string()
            .min(2, `Должно быть минимум 2 символа`)
            .max(64, `Должно быть максимум 64 символов`)
            .required(`Поле не должно быть пустым`),
        email: yup.string().email('Не верный формат почты').required(`Поле не должно быть пустым`),
        password: yup
            .string()
            .required(`Поле не должно быть пустым`)
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`)
            .matches(publicConfig.passwordPattern as RegExp, 'Пароль слишком слабый'),
        confirmationPassword: yup
            .string()
            .required(`Поле не должно быть пустым`)
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`),
        roleId: yup.string().required(`Поле не должно быть пустым`),
    });

    const { dirty, values, handleChange, handleSubmit, errors, validateForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        validate,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            if (dirty) {
                //описание события
                const eventDescriptor = {
                    name: 'user_new',
                    props: { user_id: 'id' },
                };
                //hide-fio
                item.createOne({ ...data, firstname: '...', lastname: '...' }, eventDescriptor); //оригинал = item.createOne(data, eventDescriptor)
            }
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (plainItem.isCreated) {
            navigate(`/admin/users`);
        }

        return () => item.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated]);

    const [isFormSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        if (isFormSubmitted) {
            validateForm().then(() => {});
        }
    }, [values, isFormSubmitted, validateForm]);

    const handleFormSubmit = async () => {
        setFormSubmitted(true);
        handleSubmit();
    };

    return (
        <Form onSubmit={handleFormSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Почта"
                        name="email"
                        value={values.email}
                        invalidText={errors.email}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                {/* hide-fio */}
                {/* <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Имя"
                        name="firstname"
                        value={values.firstname}
                        invalidText={errors.firstname}
                        handleChange={handleChange}
                    />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Фамилия"
                        name="lastname"
                        invalidText={errors.lastname}
                        value={values.lastname}
                        handleChange={handleChange}
                    />
                </Col> */}
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Псевдоним"
                        name="commonName"
                        invalidText={errors.commonName}
                        value={values.commonName}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Пароль"
                        name="password"
                        type="password"
                        value={values.password}
                        invalidText={errors.password}
                        handleChange={handleChange}
                        disabled={!configReady}
                    />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Подтверждение пароля"
                        name="confirmationPassword"
                        type="password"
                        invalidText={errors.confirmationPassword}
                        value={values.confirmationPassword}
                        handleChange={handleChange}
                        disabled={!configReady}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <ChoicesSelect
                        getSelect={roleApi.getSelect}
                        placeholder="Укажите роль"
                        name="roleId"
                        setFieldValue={setFieldValue}
                        label="Роль"
                    />
                </Col>
            </Row>
            <div className="mt-4" />
            <FormBackOrSubmitButtons
                onSubmit={handleFormSubmit}
                backTitle="К списку"
                backPath="/admin/users"
                disabled={!dirty}
            />
        </Form>
    );
});

export default UserCreateForm;
