import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';
import { UseFormRegister, FieldValues, Path, ChangeHandler } from 'react-hook-form';

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface Props<T extends FieldValues> {
    register: UseFormRegister<T>;
    name: Path<T>;
    title?: string;
    disabled?: boolean;
    onChangeHandler?: () => void; //дополнительный пользовательский обработчик нажатия на переключатель
}

const FormCheckBox = <T extends FieldValues>({ register, name, title, disabled, onChangeHandler }: Props<T>) => {
    const { onChange, ...rest } = register(name);

    //onChangeHandler - дополнительный пользовательский обработчик нажатия на переключатель
    //если не указан, то используется только стандартный onChange
    //тип ChangeHandler взят из исходников react-hook-form
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const newHandler: ChangeHandler = (event: { target: any; type?: any }) => {
        if (onChangeHandler) onChangeHandler();
        return onChange(event);
    };

    return (
        <SelectContainer>
            <label className="custom-toggle">
                <input disabled={disabled} id={name} type="checkbox" {...rest} {...{ onChange: newHandler }} />
                <span className="custom-toggle-slider rounded-circle" />
            </label>
            {!isUndefined(title) && (
                <label className="form-control-label ml-2" htmlFor={name}>
                    {title}
                </label>
            )}
        </SelectContainer>
    );
};

export default FormCheckBox;
