import React from 'react';

interface IPagination {
    from: number;
    to: number;
    size: number;
}

const Pagination: React.FC<IPagination> = ({ from, to, size }) => {
    if (from > 0) {
        return (
            <div className="dataTables_length">
                Показано с {from} по {to} из {size} записей.
            </div>
        );
    }

    return (
        <div style={{ paddingLeft: '1em' }} className="dataTables_length">
            Записей не найдено
        </div>
    );
};

export default Pagination;
