import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';

interface IBackOrSubmit {
    disabled: boolean;
    backPath?: string;
    backTitle?: string;
    actionTitle?: string;
    onSubmit?: () => void;
}

const BackOrSubmit: React.FC<IBackOrSubmit> = ({ disabled, backPath, backTitle, actionTitle, onSubmit }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const arrayPath = location.pathname.split('/');
    arrayPath.pop();
    const defaultBackPath = arrayPath.join('/');

    const handleSubmit = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <Row>
            <Col className="d-flex justify-content-start">
                <Button
                    onClick={() => navigate(backPath || defaultBackPath, { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    {backTitle || 'Назад'}
                </Button>
            </Col>
            <Col className="d-flex justify-content-end">
                <Button onClick={onSubmit && handleSubmit} disabled={disabled} size="sm" color="primary" type="submit">
                    {actionTitle || 'Сохранить'}
                </Button>
            </Col>
        </Row>
    );
};

export default BackOrSubmit;
