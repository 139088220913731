import { makeAutoObservable } from 'mobx';

import { TEditorBlockData } from 'components/edit/HTMLEditor/types';
import { articleApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IArticleInList {
    id: number;
    title: string;
    createdAt: Date;
    publishedAt: Date;
    importantTo: Date;
    status: string;
    isPublished: boolean;
}

export interface IMeta {
    isFilled: boolean;
    isVideoCover: boolean;
}

export type IPoll = {
    id?: number;
    title: string;
    isSingle: boolean;
    isAnonymous: boolean;
    choices: Array<{ id?: number; text: string }>;
};

export type TMobileBlock = {
    title: string;
    annotate: string;
    category: { id: number; title: string };
    importantTo: Date;
    publishedAt: Date;
    tags?: Array<{ id: number; title: string }>;
    isPublished: boolean;
    cover: string;
    coverVideo: TEditorBlockData[];
    link: string;
    author: {
        id: number;
        firstname: string;
        lastname: string;
        username: string;
        commonName: string;
    };
    editorData: TEditorBlockData[];
    poll?: IPoll;
};

export type TMobileBlockSave = {
    title?: string;
    annotate?: string;
    category?: number;
    importantTo?: Date | null;
    publishedAt?: Date | null;
    tags?: number[] | null;
    isPublished?: boolean;
    cover?: string;
    author?: number;
    coverVideo?: TEditorBlockData[];
    editorData?: TEditorBlockData[];
    poll?: IPoll;
};

export type TPortalBlock = {
    title: string;
    publishedAt: Date;
    isPublished: boolean;
    cover: string;
    editorData: TEditorBlockData[];
};

export type TPortalBlockSave = {
    title?: string;
    publishedAt?: Date | null;
    isPublished?: boolean;
    cover?: string;
    editorData?: TEditorBlockData[];
};

export type TTelegramBlock = {
    hasData: boolean;
    tgMedia: TEditorBlockData[];
    tgText: TEditorBlockData[];
};

export type TTelegramBlockSave = {
    hasData?: boolean;
    tgMedia?: TEditorBlockData[];
    tgText?: TEditorBlockData[];
};

export enum TArticleDataType {
    MOBILE = 'MOBILE',
    TELEGRAM = 'TELEGRAM',
    PORTAL = 'PORTAL',
}

export interface IArticle {
    id: number;
    mobile: TMobileBlock;
    portal: TPortalBlock;
    telegram: TTelegramBlock;
}

// export interface IArticleUpdate {
//     dataType: TArticleDataType;
//     mobile?: TMobileBlockSave;
//     portal?: TPortalBlockSave;
//     telegram?: TTelegramBlockSave;
// }

// export interface IArticleCreate {
//     dataType: TArticleDataType;
//     mobile?: TMobileBlockSave;
//     portal?: TPortalBlockSave;
//     telegram?: TTelegramBlockSave;
// }

export interface IArticleSave {
    dataType: TArticleDataType;
    mobile?: TMobileBlockSave;
    portal?: TPortalBlockSave;
    telegram?: TTelegramBlockSave;
}

class ArticleStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IArticle, IArticleSave, IArticleSave>(articleApi);

    readonly list = new ListStore<IArticleInList>(articleApi);

    //используется для связи с VK
    // getArticleTextFromEditor(): string | undefined {
    //     if (this.item.data?.editorData) {
    //         return this.item.data.editorData.reduce((acc, block) => {
    //             if (isParagraphBlock(block)) {
    //                 const tmp = document.createElement('DIV');
    //                 tmp.innerHTML = block.data.text;

    //                 const text = tmp.textContent || tmp.innerText;

    //                 return text?.length > 0 ? `${acc} ${text}` : acc;
    //             }

    //             return acc;
    //         }, '');
    //     }

    //     return undefined;
    // }

    //используется для связи с VK
    // getArticleImagesFromEditor(): string[] {
    //     if (this.item.data?.editorData) {
    //         return this.item.data.editorData.reduce((acc: string[], block) => {
    //             if (isImageBlock(block)) {
    //                 const { url } = block.data.file;

    //                 return [...acc, url];
    //             }

    //             return acc;
    //         }, []);
    //     }

    //     return [];
    // }
}

export default new ArticleStore();
