import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AlertOptions } from 'react-notification-alert';

import getEditedValues from 'lib/getEditedValues';
import SaveButtons from 'components/buttons/SaveButtons';
import FormTextInput from 'components/inputs/FormTextInput';
import FormCheckBox from 'components/inputs/FormCheckBox';
import notifyStore from 'store/notification';
import { useRqUpdateProfile, TProfile } from './queries';

interface IProfileForm {
    item: TProfile;
}

//*************************************************************************************************************
//Профиль автора
const ProfileForm: React.FC<IProfileForm> = ({ item }) => {
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };
    //функция корректировки
    const rqUpdateProfile = useRqUpdateProfile();
    //разрешение на правку псевдонима
    const [allowCommonNameModify, setAllowCommonNameModify] = useState(false);

    const validationSchema = yup.object({
        commonName: yup
            .string()
            .max(64, `Псевдоним должен быть максимум 64 символа`)
            .required(`Псевдоним не должен быть пустым`),
    });

    const { dirty, values, handleChange, handleSubmit, isValid, errors, validateForm } = useFormik({
        initialValues: item,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            const ed = getEditedValues(item, data);
            if (dirty && ed) {
                rqUpdateProfile.mutate(ed);
            }
        },
    });

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        setFormSubmitted();
        handleSubmit();
        notifyStore.showNotify({
            place: 'br',
            message: 'данные сохранены',
            type: 'success',
        } as AlertOptions);
        return true;
    };

    //ОШИБКА в formik!
    //если в списке контролов есть только один контрол, например, ввод текста, то при нажатии enter форма валится
    //причем событие submit и соответствующие кнопки в этом не участвуют
    //
    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between">
                <Col md="6" className="mb-3">
                    <FormTextInput title="Почта" name="email" value={values.email} disabled={true} />
                </Col>
                {/* {rqUpdateProfile.isLoading && (
                    <Col md="2" className="mb-3 d-flex justify-content-end align-items-start">
                        <LoaderClassic />
                    </Col>
                )} */}
            </Row>

            {/* псевдоним */}
            <Row>
                <Col md="6" className="mb-0">
                    <FormTextInput
                        invalidText={errors.commonName}
                        title="Псевдоним"
                        placeholder="Введите псевдоним"
                        name="commonName"
                        value={values.commonName}
                        handleChange={handleChange}
                        disabled={rqUpdateProfile.isLoading || !allowCommonNameModify}
                    />
                </Col>
            </Row>

            {/* переключатель псевдонима */}
            <Row>
                <Col className="col-10 mt-3 mb-3">
                    <FormCheckBox
                        name="allowCommonNameModify"
                        title="Я уведомлен и согласен, что выбранный псевдоним будет виден всем пользователям в случае публикации контента от моего имени"
                        value={allowCommonNameModify}
                        handleChange={() => setAllowCommonNameModify((prev) => !prev)}
                    />
                </Col>
            </Row>

            {/* кнопки */}
            <div className="mt-4" />
            <SaveButtons
                isValid={isValid}
                isDirty={dirty}
                pathToList="/admin"
                onSave={handleSave}
                backTitle="К спискам"
                disabled={rqUpdateProfile.isLoading}
                isLoading={rqUpdateProfile.isLoading}
            />
        </React.Fragment>
    );
};

export default ProfileForm;
