import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';
import * as yup from 'yup';
import { isUndefined } from 'lodash';

import { usePublicConfig } from 'lib/config';
import FormTextInput from 'components/inputs/FormTextInput';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import { UserStatus } from 'types';
import userStore from 'store/user';
import getEditedValues from 'lib/getEditedValues';
import ChoicesSelect from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote';
import { roleApi } from 'api';
import UserStatusSelect from 'components/inputs/dropdown-selects/UserStatusSelect';

interface ITagEditForm {
    store: typeof userStore.item;
}

const UserEditForm: React.FC<ITagEditForm> = ({ store }) => {
    const { ready: configReady, publicConfig } = usePublicConfig();
    const plainItem = toJS(store);
    const initialValues = {
        firstname: store.data?.firstname,
        lastname: store.data?.lastname,
        commonName: store.data?.commonName,
        password: '',
        confirmationPassword: '',
        email: store.data?.email,
        roleId: store.data?.role?.id,
        //компонент Choices отказывается работать со значением number, поэтому преобразуем в string
        status: String(store.data ? store.data.status : UserStatus.ACTIVE),
    };

    const validate = (
        values: typeof initialValues
    ): {
        [K in keyof typeof initialValues]?: string;
    } => {
        if (values.password !== values.confirmationPassword) {
            return {
                password: 'Пароли должны совпадать',
                confirmationPassword: 'Пароли должны совпадать',
            };
        }

        return {};
    };

    const validationSchema = yup.object().shape({
        firstname: yup.string().min(2, `Должно быть минимум 2 символа`).max(30, `Должно быть максимум 30 символов`),
        lastname: yup.string().min(2, `Должно быть минимум 2 символа`).max(30, `Должно быть максимум 30 символов`),
        commonName: yup
            .string()
            .min(2, `Должно быть минимум 2 символа`)
            .max(64, `Должно быть максимум 64 символов`)
            .required(`Поле не должно быть пустым`),
        email: yup.string().email('Не верный формат почты').required(`Поле не должно быть пустым`),
        password: yup
            .string()
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`)
            .matches(publicConfig.passwordPattern as RegExp, 'Пароль слишком слабый'),
        confirmationPassword: yup
            .string()
            .min(publicConfig.passwordMinLength, `Должно быть минимум ${publicConfig.passwordMinLength} символов`)
            .max(publicConfig.passwordMaxLength, `Должно быть максимум ${publicConfig.passwordMaxLength} символов`),
    });

    const { dirty, values, handleChange, handleSubmit, errors, validateForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        validate,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            const editedValues = getEditedValues(initialValues, data);
            if (dirty) {
                //описание события
                const eventDescriptor = {
                    name: 'user_edit',
                    props: { user_id: 'id' },
                };
                //исправить объект
                //компонент Choices отказывается работать со значением number, поэтому ранее преобразовали в string
                //а теперь возвращаем в number
                const { status, ...passThroughProps } = editedValues;
                store.updateOne(
                    {
                        ...passThroughProps,
                        ...(!isUndefined(status) && { status: Number(status) }),
                    },
                    eventDescriptor
                );
            }
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (plainItem.isUpdated) {
            navigate(`/admin/users`);
        }

        return () => store.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated]);

    const [isFormSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        if (isFormSubmitted) {
            validateForm().then(() => {});
        }
    }, [values, isFormSubmitted, validateForm]);

    const handleFormSubmit = async () => {
        setFormSubmitted(true);
        handleSubmit();
    };

    return (
        <Form onSubmit={handleFormSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Почта"
                        name="email"
                        value={values.email}
                        invalidText={errors.email}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                {/* hide-fio */}
                {/* <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Имя"
                        name="firstname"
                        value={values.firstname}
                        invalidText={errors.firstname}
                        handleChange={handleChange}
                    />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Фамилия"
                        name="lastname"
                        invalidText={errors.lastname}
                        value={values.lastname}
                        handleChange={handleChange}
                    />
                </Col> */}
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Псевдоним"
                        name="commonName"
                        invalidText={errors.commonName}
                        value={values.commonName}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Пароль"
                        name="password"
                        type="password"
                        value={values.password}
                        invalidText={errors.password}
                        handleChange={handleChange}
                        disabled={!configReady}
                    />
                </Col>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Подтверждение пароля"
                        name="confirmationPassword"
                        type="password"
                        invalidText={errors.confirmationPassword}
                        value={values.confirmationPassword}
                        handleChange={handleChange}
                        disabled={!configReady}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <UserStatusSelect
                        selectedValue={values.status}
                        label="Статус"
                        name="status"
                        setFieldValue={setFieldValue}
                    />
                </Col>
                <Col className="mb-3" md="4">
                    <ChoicesSelect
                        getSelect={roleApi.getSelect}
                        defaultSelect={values.roleId}
                        placeholder="Укажите роль"
                        name="roleId"
                        setFieldValue={setFieldValue}
                        label="Роль"
                    />
                </Col>
            </Row>
            <div className="mt-4" />
            <FormBackOrSubmitButtons
                onSubmit={handleFormSubmit}
                backTitle="К списку"
                backPath="/admin/users"
                disabled={!dirty}
            />
        </Form>
    );
};

export default UserEditForm;
