import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Input } from 'reactstrap';
import { isUndefined } from 'lodash';
import { InputType } from 'reactstrap/es/Input';

import ActionIcon from 'components/icons/ActionIcon';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    // name, control уже определены в UseControllerProps
    title?: string;
    size?: 'sm' | 'lg';
    placeholder?: string;
    isValid?: boolean;
    validText?: string;
    invalidText?: string | undefined;
    infoText?: string;
    type?: InputType;
    disabled?: boolean;
    handleRemove?: () => void;
    rows?: number;
}

const FormTextInput = <T extends FieldValues>({
    //-- обязательные свойства --
    name,
    control,
    //-- custom свойства --------
    title,
    size,
    placeholder,
    isValid,
    validText,
    invalidText,
    disabled,
    type = 'text',
    handleRemove,
    rows,
    infoText,
}: Props<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <React.Fragment>
                    {!isUndefined(title) && (
                        <label className="form-control-label" htmlFor={name}>
                            {title}
                        </label>
                    )}
                    {!isUndefined(handleRemove) && (
                        <ActionIcon
                            fontSize="0.8em"
                            title="Удалить параметр"
                            activeColor="#f5365c"
                            onClick={handleRemove}
                            className="fas fa-trash-alt ml-2"
                        />
                    )}
                    <Input
                        rows={rows || 2}
                        disabled={disabled}
                        bsSize={size}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                        valid={isValid}
                        invalid={Boolean(invalidText)}
                        value={value}
                        onChange={onChange}
                    />
                    {!isUndefined(infoText) && (
                        <div className="text-muted">
                            <small>* {infoText}</small>
                        </div>
                    )}
                    <div className="valid-feedback">{validText}</div>
                    <div className="invalid-feedback">{invalidText}</div>
                </React.Fragment>
            )}
        />
    );
};

export default FormTextInput;
