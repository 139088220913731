import React, { useEffect, useRef } from 'react';
import Choices from 'choices.js';
//import Choices = require('choices.js');
import { groupBy, isUndefined } from 'lodash';

import { TSelectResponse, IGetSelectParams } from 'api';
import { FormikErrors } from 'formik/dist/types';

interface IChoicesMultipleSelect {
    name: string;
    label?: string;
    placeholder?: string;
    defaultSelects?: number[];
    defaultSelect?: number;
    maxItemCount?: number;
    isMultiple?: boolean;
    isRemoveButton?: boolean;
    getSelect: (params: IGetSelectParams) => TSelectResponse;
    setFieldValue: (field: string, value: string[] | string | null) => Promise<FormikErrors<object>> | Promise<void>;
    shouldSort?: boolean;
}

const ChoicesSelectFromRemote: React.FC<IChoicesMultipleSelect> = ({
    defaultSelects,
    maxItemCount,
    getSelect,
    name,
    setFieldValue,
    label,
    placeholder,
    isMultiple,
    defaultSelect,
    isRemoveButton,
    shouldSort = true,
}) => {
    const ch = useRef<Choices | null>(null);
    useEffect(() => {
        const choices = new Choices(`#choices-multiple-select-${name}`, {
            searchEnabled: true,
            delimiter: ',',
            removeItemButton: isUndefined(isRemoveButton) ? Boolean(isMultiple) : isRemoveButton,
            maxItemCount: isMultiple ? maxItemCount : 1,
            paste: false,
            placeholder: true,
            resetScrollPosition: false,
            placeholderValue: placeholder,
            searchPlaceholderValue: placeholder,
            noResultsText: 'Ничего не найдено',
            noChoicesText: 'Нет элементов для выбора',
            itemSelectText: 'Нажмие для выбора',
            loadingText: 'Загрузка...',
            maxItemText: `Только ${maxItemCount} элементов может быть выбрано`,
            shouldSort,
        });
        ch.current = choices;

        let lastSearchText: string;

        const setChoicesFromServer = async (params: {
            defaultSelects?: IChoicesMultipleSelect['defaultSelects'];
            searchText?: string;
        }) => {
            if (!isUndefined(params.searchText) && lastSearchText === params.searchText) {
                return;
            }
            if (!isUndefined(params.searchText)) {
                lastSearchText = params.searchText;
            }

            const { data } = await getSelect(params);

            const selectedChoicesByValue: { [key: number]: object } = groupBy(choices.getValue(), 'value');

            const choicesToSet = data.result.reduce(
                (
                    acc: Array<{
                        value: number;
                        label: string;
                        selected?: boolean | undefined;
                    }>,
                    item
                ) => (selectedChoicesByValue[item.value] ? acc : [...acc, item]),
                []
            );

            if (ch.current) choices.setChoices(choicesToSet, 'value', 'label', true);
        };

        setChoicesFromServer({
            ...(!isUndefined(defaultSelects) && isMultiple && { defaultSelects }),
            ...(!isUndefined(defaultSelect) && !isMultiple && { defaultSelects: [defaultSelect] }),
        });

        choices.passedElement.element.addEventListener(
            'search',
            // @ts-ignore
            ({ detail }) => setChoicesFromServer({ searchText: detail.value }),
            false
        );

        choices.passedElement.element.addEventListener(
            'change',
            () => {
                const value = choices.getValue(true);
                setFieldValue(name, isRemoveButton ? value || null : value);
            },
            false
        );

        return () => {
            if (ch.current) {
                ch.current.destroy();
                ch.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {label && (
                <label className="form-control-label" htmlFor={`choices-multiple-select-${name}`}>
                    {label}
                </label>
            )}
            <select
                aria-label={name}
                className="form-control"
                id={`choices-multiple-select-${name}`}
                multiple={isMultiple}
            />
        </React.Fragment>
    );
};

export default ChoicesSelectFromRemote;
