import { makeAutoObservable, action } from 'mobx';

import { IEventDescriptor, sysNotify } from 'lib/system';

interface IUpdateOne<U, T> {
    (id: number, data: U): Promise<{ data: T }>;
}

interface IApi<T extends { id: number }, U, C> {
    deleteOne: (id: number) => Promise<{ data: T }>;
    getOne: (id: number) => Promise<{ data: T }>;
    updateOne: IUpdateOne<U, T>;
    createOne: (data: C) => Promise<{ data: T }>;
}

class ItemStore<T extends { id: number }, U, C> {
    constructor(api: IApi<T, U, C>) {
        this.api = api;
        makeAutoObservable(this);
    }

    data: T | undefined;

    api: IApi<T, U, C>;

    isLoading = false;

    isDeleted: boolean | undefined;

    isUpdated: boolean | undefined;

    isCreated: boolean | undefined;

    getOne = action((id: number) => {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.api
            .getOne(id)
            .then(
                action(({ data }) => {
                    this.data = data;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    });

    deleteOne = action((id: number, eventDescriptor?: IEventDescriptor) => {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        const idToDelete = id;
        if (idToDelete) {
            this.api
                .deleteOne(idToDelete)
                .then(
                    action(({ data }) => {
                        this.isDeleted = true;
                        this.data = undefined;
                        sysNotify('delete', id, data, eventDescriptor);
                    })
                )
                .finally(
                    action(() => {
                        this.isLoading = false;
                    })
                );
        }
    });

    updateOne = action((values: U, eventDescriptor?: IEventDescriptor) => {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        const idToUpdate = this.data?.id;
        if (idToUpdate) {
            this.api
                .updateOne(idToUpdate, values)
                .then(
                    action(({ data }) => {
                        this.isUpdated = true;
                        this.data = data;
                        sysNotify('update', data.id, data, eventDescriptor);
                    })
                )
                .finally(
                    action(() => {
                        this.isLoading = false;
                    })
                );
        }
    });

    createOne = action((values: C, eventDescriptor?: IEventDescriptor) => {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.api
            .createOne(values)
            .then(
                action(({ data }) => {
                    this.isCreated = true;
                    this.data = data;
                    sysNotify('create', data.id, data, eventDescriptor);
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    });

    resetIsUpdated = action(() => {
        this.isUpdated = undefined;
    });

    resetIsCreated = action(() => {
        this.isCreated = undefined;
    });

    resetState = action(() => {
        this.isCreated = undefined;
        this.isLoading = false;
        this.isDeleted = undefined;
        this.isUpdated = undefined;
        this.data = undefined;
    });
}

export default ItemStore;
