import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';

import FormTextInput from 'components/inputs/FormTextInput';
import { useStore } from 'store';
import commentStore, { CommentStatus } from 'store/comment';
import getEditedValues from 'lib/getEditedValues';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import { commentApi } from '../../../api';

interface ITagEditForm {
    store: typeof commentStore.item;
}

//const CommentEditForm: React.FC<ITagEditForm> = ({ store }) => {
const CommentEditForm = ({ store }: ITagEditForm) => {
    useEffect(() => {
        //для установки отметки о прочтении комментария напрямую вызываем api,
        //чтобы избежать перерисовки компонента при возникновении ошибки (например, при отсутствии прав)
        const stubCheckComment = async () => {
            if (store.data?.id && store.data?.status === CommentStatus.NEW)
                try {
                    await commentApi.updateOne(store.data.id, {
                        status: CommentStatus.IN_PROCESS,
                    });
                    notifyStore.showNotify({
                        place: 'br',
                        message: `Комментарий прочитан`,
                        type: 'success',
                    } as AlertOptions);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
        };
        stubCheckComment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.comments;
    const isRightToCreate = userAccess?.create === true; //право на новый ответ
    const isRightToUpdate = userAccess?.update === true; //право на исправление ответа
    const isRightToDelete = userAccess?.delete === true; //право на удаление ответа

    const initialValues = store.data ?? {
        text: '',
        userCommonName: '',
        status: 'NEW',
        articleTitle: '',
        answerText: '',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            const { answerText } = getEditedValues(initialValues, values);
            if (formik.dirty) {
                //описание события
                const eventDescriptor = {
                    name: 'comments_response',
                };
                //исправить объект
                store.updateOne({ answerText }, eventDescriptor);
            }
        },
    });

    //права
    const initLength = initialValues.answerText.trim().length;
    const resultLength = formik.values.answerText.trim().length;
    const canApply: boolean =
        formik.dirty &&
        //можно удалять
        ((initLength !== 0 && resultLength === 0 && isRightToDelete) ||
            //можно исправлять
            (initLength !== 0 && resultLength !== 0 && isRightToUpdate) ||
            //можно создавать
            (initLength === 0 && resultLength !== 0 && isRightToCreate));

    return (
        <Form onSubmit={formik.handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        title="Статья"
                        name="articleTitle"
                        value={initialValues.articleTitle}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        type="textarea"
                        title="Текст комментария"
                        name="text"
                        value={initialValues.text}
                        disabled={true}
                        rows={5}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="12">
                    <FormTextInput
                        type="textarea"
                        title="Ответ  (пусто - удалить ответ)"
                        name="answerText"
                        value={formik.values.answerText}
                        handleChange={formik.handleChange}
                        rows={5}
                    />
                </Col>
            </Row>
            <FormBackOrSubmitButtons backPath="/admin/comments" actionTitle="Отправить ответ" disabled={!canApply} />
        </Form>
    );
};

export default CommentEditForm;
