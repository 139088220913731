import React from 'react';

import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

const AdminFooter: React.FC = () => {
    return (
        <React.Fragment>
            <Container fluid={true}>
                <footer className="footer pt-0">
                    <Row className="align-items-center justify-content-lg-between">
                        <Col lg="6">
                            <div className="copyright text-center text-lg-left text-muted">
                                © {new Date().getFullYear()}
                                <a
                                    className="font-weight-bold ml-1"
                                    href="https://digitalatom.ru/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Digital Atom Media by Digital Atom
                                </a>
                            </div>
                        </Col>
                        <Col lg="6">
                            <Nav className="nav-footer justify-content-center justify-content-lg-end">
                                <NavItem>
                                    <NavLink href="https://digitalatom.ru/contacts" target="_blank">
                                        Контакты
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </footer>
            </Container>
        </React.Fragment>
    );
};

export default AdminFooter;
