import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toJS } from 'mobx';

import ChoicesSelect from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote';
import ReactDropzone from 'components/inputs/ReactDropzone';
import PdfReactDropzone from 'components/inputs/PdfReactDropzone/PdfReactDropzone';
import FormTextInput from 'components/inputs/FormTextInput';
import FormDateSelect from 'components/inputs/FormDateSelect';
import ArticleFormButtons from 'components/buttons/ArticleFormButtons';
import journalStore, { IJournalUpdate, IJournalCreate } from 'store/journal';
import getEditedValues from 'lib/getEditedValues';
import { fileJournalApi, smiApi } from 'api';

interface IJournalEditForm {
    item: typeof journalStore.item;
    mode: string;
}

const JournalEditForm: React.FC<IJournalEditForm> = observer(({ item, mode }) => {
    const plainItem = toJS(item);

    //заполнить начальные значения
    let initialValues: IJournalUpdate | IJournalCreate = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initialValues = {
            title: plainItem.data?.title,
            cover: plainItem.data?.cover,
            coverId: plainItem.data?.coverId,
            content: plainItem.data?.content,
            contentId: plainItem.data?.contentId,
            publishedAt: plainItem.data?.publishedAt,
            isPublished: plainItem.data?.isPublished,
            smiId: plainItem.data?.smi.id,
        };
    } else {
        //если создание, то занести пустые данные
        initialValues = {
            title: '',
            cover: '',
            coverId: 0,
            content: '',
            contentId: 0,
            publishedAt: new Date(),
            isPublished: false,
            smiId: 0,
        };
    }

    //*************************************************************************************************************
    //Используется для проверки отдельных свойств формы
    const validationSchema = yup.object().shape({
        title: yup.string().max(128, `Должно быть максимум 128 символов`).required(`Название не должно быть пустым`),
        content: yup.string().required(`Контент не должен быть пустым`),
    });

    const { dirty, values, handleChange, handleSubmit, setFieldValue, isValid, errors /*,validateForm*/ } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            if (mode === 'edit') {
                if (dirty) {
                    const editedValues = getEditedValues(initialValues, data);
                    item.updateOne(editedValues);
                }
            } else if (dirty) {
                item.createOne(data as IJournalCreate);
            }
        },
    });

    //после создания переход в список
    const navigate = useNavigate();
    useEffect(() => {
        if (mode !== 'edit' && plainItem.isCreated) {
            navigate(`/admin/journals`);
        }

        return () => {
            if (mode !== 'edit') item.resetState();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainItem.isCreated, mode]);

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            {/* СМИ */}
            <Row>
                <Col className="mb-3" md="6">
                    <ChoicesSelect
                        getSelect={smiApi.getSelect}
                        defaultSelect={values.smiId}
                        isRemoveButton={true}
                        placeholder="Укажите СМИ"
                        name="smiId"
                        setFieldValue={setFieldValue}
                        label="СМИ"
                    />
                </Col>
            </Row>

            {/* Название */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        invalidText={errors.title}
                        title="Название журнала"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>

            {/* обложка */}
            <Row className="justify-content-center">
                <Col className="mb-3">
                    <ReactDropzone
                        buttonText="Загрузите обложку здесь"
                        name="cover"
                        nameFileId="coverId"
                        label="Обложка"
                        setFieldValue={setFieldValue}
                        preloadedImageUrl={values.cover}
                        invalidText={errors.cover}
                        clearValueOnError={false}
                        createImageFile={fileJournalApi.createImageFile}
                    />
                </Col>
            </Row>

            {/* контент */}
            <Row className="justify-content-center">
                <Col className="mb-3">
                    <PdfReactDropzone
                        // key="pdf1"
                        buttonText="Загрузите журнал здесь"
                        name="content"
                        nameFileId="contentId"
                        label="Контент"
                        setFieldValue={setFieldValue}
                        preloadedUrl={values.content}
                        invalidText={errors.content}
                        clearValueOnError={false}
                    />
                </Col>
            </Row>

            <Row>
                <Col md="4" className="mb-3">
                    <FormDateSelect
                        invalidText={errors.publishedAt}
                        initialValue={values.publishedAt}
                        name="publishedAt"
                        label="Дата публикации"
                        placeholder="Введите дату"
                        setFieldValue={setFieldValue}
                        timeFormat={false}
                        // dateFormat="DD-MM-YYYY"
                    />
                </Col>
            </Row>

            <div className="mt-4" />
            <ArticleFormButtons
                onSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isPublished={values.isPublished}
                isValid={isValid}
                isDirty={dirty}
                pathToList="/admin/journals"
                hidePublishButton={!values.isPublished && !values.publishedAt}
            />
        </Form>
    );
});

export default JournalEditForm;
