import { makeAutoObservable } from 'mobx';

import { commentApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export enum CommentStatus {
    NEW = 'NEW', //новый
    IN_PROCESS = 'IN_PROCESS', //прочитан
    //    ARCHIVED = 'ARCHIVED', //архивирован
}

export const CommentStatusTitle = {
    [CommentStatus.NEW]: 'новый',
    [CommentStatus.IN_PROCESS]: 'прочитан',
    //    [CommentStatus.ARCHIVED]: 'архив',
};

export interface IComment {
    id: number;
    text: string;
    userCommonName: string;
    status: keyof typeof CommentStatus;
    createdAt: Date;
    articleTitle: string;
    answerText: string;
}

export interface ICommentUpdate {
    status?: string;
    answerText?: string;
}

class CommentStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IComment, ICommentUpdate, never>(commentApi);

    readonly list = new ListStore<IComment>(commentApi);
}

export default new CommentStore();
