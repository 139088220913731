import React, { useEffect } from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Sidebar from 'components/navs/Sidebar';
import AdminNavbar from 'components/navs/Navbar';
import AdminFooter from 'layouts/Admin/AdminFooter';
import Notification from 'components/notifications/Notification';
import routes, { getRoutes } from 'routes';
import { useStore } from 'store';

import Profile from 'views/profile';
import Security from 'views/security';
import LoggerList from 'views/logger/LoggerList';
import LoggerEdit from 'views/logger/LoggerEdit';
import ServiceList from 'views/services';
import Notice from 'views/notice';

const AdminLayout: React.FC = () => {
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const location = useLocation();

    //событие выбора пункта меню "Статьи" засылаем принудительно, т.к. при старте нет клика на этот элемент
    // if (location.pathname.includes('/articles')) {
    //     stat.addEvent({
    //         name: 'chapter_open',
    //         value: { chapter_name: 'articles' },
    //     });
    // }

    const mainContentRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
    }, [location]);

    const toggleSidenav = () => {
        if (document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-pinned');
            document.body.classList.add('g-sidenav-hidden');
        } else {
            document.body.classList.add('g-sidenav-pinned');
            document.body.classList.remove('g-sidenav-hidden');
        }
        setSidenavOpen(!sidenavOpen);
    };
    const navigate = useNavigate();

    const authStore = useStore('auth');
    const adminStore = useStore('admin');

    useEffect(() => {
        if (!authStore.isLoggedIn) {
            navigate('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore]);

    const redirectPath = authStore.isLoggedIn ? '/admin/articles' : '/auth';
    if (!authStore.isLoggedIn) return null; //иначе, после нажатия Выход, идут неавторизованные запросы для текущего списка List

    return (
        <React.Fragment>
            <Sidebar routes={routes} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
            <div className="main-content" ref={mainContentRef}>
                <AdminNavbar theme="light" toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
                <div style={{ minHeight: 'calc(75vh)', marginBottom: '15vh' }}>
                    <Routes>
                        {getRoutes(routes, '/admin', adminStore.currentUser.permissions)}
                        {/* uuid() нужен для пересоздания Profile. Иначе остаются старые значения values */}
                        <Route path="/profile" element={<Profile key={`${uuid()}`} />} key="/admin/profile" />
                        <Route path="/security" element={<Security />} />
                        <Route path="/logger" element={<LoggerList />} />
                        <Route path="/logger/:id/edit" element={<LoggerEdit />} />
                        <Route path="/services" element={<ServiceList />} />
                        <Route path="/notice" element={<Notice />} />
                        <Route path="*" element={<Navigate to={redirectPath} replace={true} />} />
                    </Routes>
                </div>
                <Notification />
                <AdminFooter />
            </div>
            {sidenavOpen ? (
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
            ) : null}
        </React.Fragment>
    );
};

export default AdminLayout;
