import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormikErrors } from 'formik/dist/types';
import { isUndefined } from 'lodash';
import { Card, CardText, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import LoaderClassic from 'components/LoaderClassic';
import InvalidLabel from 'components/labels/InvalidLabel';
import { useRqCreateFile } from './queries';

interface IPdfReactDropzone {
    label?: string;
    invalidText?: string;
    preloadedUrl?: string;
    buttonText: string;
    name: string;
    nameFileId?: string;
    setFieldValue: (
        field: string,
        value: number | string | undefined,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    disabled?: boolean;
    clearValueOnError?: boolean;
}

//isVariabledHeight - разрешает менять высоту контрола
const UploadContainer = styled.div<{ isBordered: boolean; isVariabledHeight?: boolean }>`
    height: 30em;
    @media (max-width: 767.98px) {
        height: ${(props) => (props.isVariabledHeight ? '10em' : '30em')};
    }
    cursor: pointer;
    border: ${(props) => (props.isBordered ? '1px dashed #e5e5e5' : 'none')};
    border-radius: 5px;

    margin-bottom: 0 !important;
`;

const StyledCard = styled(Card)`
    position: relative;
    overflow: hidden;

    &::after {
        display: block;
        padding-bottom: 100%;
    }
`;

const StyledCardText = styled(CardText)`
    font-weight: 400;
    color: #adb5bd;
`;

const PdfReactDropzone: React.FC<IPdfReactDropzone> = ({
    label,
    buttonText,
    invalidText,
    preloadedUrl,
    name,
    nameFileId, //имя свойства с id созданного файла
    setFieldValue,
    disabled = false,
}) => {
    //содержание
    const [pdfUrl, setPdfUrl] = useState(preloadedUrl);
    //создание файла
    const { rqCreateFile, isLoading } = useRqCreateFile();

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: 'application/pdf',
        onDrop: async (file) => {
            rqCreateFile(file[0], {
                onSuccess: async (data) => {
                    const { file: createdFile } = data.data;
                    if (!isUndefined(createdFile)) {
                        setPdfUrl(createdFile.url);
                        //если задано имя свойства с id созданного файла
                        if (nameFileId) {
                            const results = [];
                            results.push(setFieldValue(name, createdFile.url));
                            results.push(setFieldValue(nameFileId, createdFile.id));
                            await Promise.all(results);
                        }
                        //если id созданного файла не используется
                        else await setFieldValue(name, createdFile.url);
                    }
                },
            });
        },
        disabled,
    });

    return (
        <React.Fragment>
            {label && <label className="form-control-label">{label}</label>}
            <Row>
                {/* фрейм показывается, когда есть содержимое (pdfUrl) */}
                {pdfUrl && (
                    <Col className="col-12 col-md-10">
                        <UploadContainer isBordered={false}>
                            <iframe id="pdf frame" title="pdf frame" width="100%" height="100%" src={pdfUrl}>
                                Ваш браузер не поддерживает встроенные фреймы!
                            </iframe>
                        </UploadContainer>
                    </Col>
                )}
                {/* область drag&drop показывается всегда*/}
                {/* если нет содержимого, то растягивается по ширине */}
                <Col className={`col-12 ${pdfUrl ? 'col-md-2' : ''}`}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <UploadContainer isBordered={true} isVariabledHeight={true}>
                            <StyledCard className="text-center h-100 justify-content-center shadow-none mb-0">
                                {isLoading && (
                                    <Row className="mt-5 d-flex justify-content-center">
                                        <LoaderClassic />
                                    </Row>
                                )}
                                {!isLoading && <StyledCardText>{buttonText}</StyledCardText>}
                            </StyledCard>
                        </UploadContainer>
                        {invalidText && <InvalidLabel>{invalidText}</InvalidLabel>}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PdfReactDropzone;
