export enum TScreen {
    SEND_CODE,
    REPEAT_CODE,
}

export enum TRequestStatus {
    EMPTY, //запроса не было
    DONE, //запрос выполнен
    ERROR, //ошибка
}
