import React, { useEffect } from 'react';
import Choices from 'choices.js';
import { FormikErrors } from 'formik/dist/types';

import { SupportStatus, SupportStatusTitle } from 'store/support';

interface IChoicesMultipleSelect {
    name: string;
    label?: string;
    selectedValue: string;
    setFieldValue: (field: string, value: string[]) => Promise<FormikErrors<object>> | Promise<void>;
}

const SupportStatusSelect: React.FC<IChoicesMultipleSelect> = ({ name, setFieldValue, label, selectedValue }) => {
    const choicesList = [
        {
            value: SupportStatus.ARCHIVED,
            label: SupportStatusTitle[SupportStatus.ARCHIVED],
        },
        {
            value: SupportStatus.IN_PROCESS,
            label: SupportStatusTitle[SupportStatus.IN_PROCESS],
        },
    ];

    useEffect(() => {
        const choices = new Choices(`#choices-select-${name}`, {
            searchEnabled: false,
            delimiter: ',',
            removeItemButton: false,
            maxItemCount: 1,
            paste: false,
            placeholder: true,
            resetScrollPosition: false,
            placeholderValue: 'Выберите статус',
            noResultsText: 'Ничего не найдено',
            noChoicesText: 'Нет элементов для выбора',
            itemSelectText: 'Нажмите для выбора',
            loadingText: 'Загрузка...',
            maxItemText: `Только 1 элемент может быть выбран`,
            choices: choicesList.map((item) => (item.value === selectedValue ? { ...item, selected: true } : item)),
        });

        choices.passedElement.element.addEventListener(
            'change',
            () => setFieldValue(name, choices.getValue(true) as string[]),
            false
        );

        return () => choices.destroy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {label && (
                <label className="form-control-label" htmlFor={`choices-select-${name}`}>
                    {label}
                </label>
            )}
            <select aria-label={name} className="form-control" id={`choices-select-${name}`} />
        </React.Fragment>
    );
};

export default SupportStatusSelect;
