import React, { useState } from 'react';
import TableRowActionIcon from 'components/icons/TableRowActionIcon';
import ConfirmationModal from 'components/modals/ConfirmationModal';

interface IDeleteButton {
    itemName?: string;
    id: number;
    isLoading: boolean;
    callback: () => void;
}

const DeleteButton: React.FC<IDeleteButton> = ({ itemName, isLoading, callback, id }) => {
    const [isOpen, toggleOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRowActionIcon
                activeColor="#f5365c"
                isLoading={isLoading}
                onClick={() => toggleOpen(true)}
                className="fas fa-trash-alt"
            />
            <ConfirmationModal
                isOpen={isOpen}
                toggleOpen={toggleOpen}
                title="Подтверждение удаления"
                text={`${itemName || 'Запись'} с ID: ${id} будет удален(а).`}
                callback={callback}
            />
        </React.Fragment>
    );
};

export default DeleteButton;
