import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row, Button } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import { toJS } from 'mobx';
import * as yup from 'yup';
import { isUndefined } from 'lodash';

import FormTextInput from 'components/inputs/FormTextInput';
import HTMLEditField from 'components/edit/HTMLEditor';
import SaveAndPublishButtons from 'components/buttons/SaveAndPublishButtons';
import getEditedValues from 'lib/getEditedValues';
import ReactDropzone from 'components/inputs/ReactDropzone';
import articleStore, { TPortalBlockSave, TArticleDataType } from 'store/article';

interface IPortalBlockForm {
    item: typeof articleStore.item;
    mode: 'create' | 'edit';
    setUnsavedTab: (isSaved: boolean) => void;
    setSavingFunction: (func: () => Promise<boolean>) => void;
}

const PortalBlockForm: React.FC<IPortalBlockForm> = observer(({ item, mode, setUnsavedTab, setSavingFunction }) => {
    const plainItem = toJS(item);
    //если при вызове изменить значение, то принудительно создается новый редактор editorJS
    const forceRefreshToken = useRef(uuid());
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };

    let initialValues: TPortalBlockSave = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        const {
            title,
            publishedAt,
            isPublished,
            cover,
            editorData,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        } = plainItem.data!.portal;

        initialValues = {
            title,
            isPublished,
            publishedAt,
            cover,
            editorData,
        };
    } else {
        //если создание, то занести пустые данные
        initialValues = {
            title: '',
            cover: '',
            publishedAt: new Date(),
            isPublished: false,
            editorData: [],
        };
    }

    const validationSchema = yup.object({
        title: yup
            .string()
            .max(300, `Название должно быть максимум 200 символов`)
            .required(`Название не должно быть пустым`),
        editorData: yup.array().min(1, 'Отсутствует содержание'),
    });

    const { dirty, values, handleChange, handleSubmit, setFieldValue, isValid, errors, validateForm } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            const dataType = TArticleDataType.PORTAL;
            //если редактирование
            if (mode === 'edit') {
                const ed: TPortalBlockSave = getEditedValues(initialValues, data);
                if (ed) {
                    const { isPublished: newIsPublishedValue, ...editedValues } = ed;
                    //если снять с публикации
                    if (newIsPublishedValue === false) {
                        item.updateOne({ dataType, portal: { isPublished: false } });
                    }
                    //в остальных случаях
                    else if (dirty) {
                        const v = {
                            ...editedValues,
                            ...(!isUndefined(newIsPublishedValue) && { isPublished: newIsPublishedValue }),
                        };
                        item.updateOne({ dataType, portal: { ...v } });
                    }
                }
            }
            //если добавить новый
            else if (dirty) {
                item.createOne({ dataType, portal: { ...data } });
            }
        },
    });

    //информирование о несохраненных данных в закладке
    setUnsavedTab(!dirty);

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //заполнить поля на основе данных из статьи
    const handleSetFields = async () => {
        //изменить значение для принудительного создания нового редактора editorJS с новым текстом
        forceRefreshToken.current = uuid();
        const results = [];
        results.push(setFieldValue('title', item.data?.mobile.title || ''));
        results.push(setFieldValue('cover', item.data?.mobile.cover || ''));
        results.push(setFieldValue('editorData', item.data?.mobile.editorData || []));
        await Promise.all(results);
    };

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = useRef(async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return false;
        handleSubmit();
        return true;
    }).current;

    useEffect(() => {
        setSavingFunction(handleSave);
    }, [setSavingFunction, handleSave]);

    //*************************************************************************************************************
    //Опубликовать
    const handlePublish = async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return;
        await setFieldValue('isPublished', true);
        handleSubmit();
    };

    //*************************************************************************************************************
    //Снять с публикации
    const handleUnpublish = async () => {
        setFormSubmitted();
        //здесь нет проверки свойств, т.к. при снятии с публикации другие свойства не сохраняются
        await setFieldValue('isPublished', false);
        handleSubmit();
    };

    //ОШИБКА в formik!
    //если в списке контролов есть только один контроло, например, ввод текста, то при нажатии enter форма валится
    //причем событие submit и соответствующие кнопки в этом не участвуют
    //
    return (
        <Form className="needs-validation" noValidate={true}>
            <Row className="mb-2 justify-content-end">
                <Col className="col-auto">
                    <Button
                        className="btn-icon"
                        color="primary"
                        outline={true}
                        type="button"
                        size="sm"
                        onClick={handleSetFields}
                    >
                        СКОПИРОВАТЬ ИЗ МОБ ПРИЛОЖЕНИЯ
                    </Button>
                </Col>
            </Row>

            {/* обложка - картинка */}
            <Row className="justify-content-center">
                <Col className="mb-3">
                    <ReactDropzone
                        buttonText="Загрузите обложку здесь"
                        name="cover"
                        label="Обложка"
                        setFieldValue={setFieldValue}
                        preloadedImageUrl={values.cover}
                        invalidText={errors.cover}
                    />
                </Col>
            </Row>

            <Row>
                <Col md="6" className="mb-3">
                    <FormTextInput
                        invalidText={errors.title}
                        title="Название"
                        placeholder="Введите название статьи"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    <HTMLEditField
                        name="editorData"
                        label="Контент"
                        initialValue={values.editorData}
                        invalidText={errors.editorData as string}
                        setFieldValue={setFieldValue}
                        forceRefreshToken={forceRefreshToken.current}
                    />
                </Col>
            </Row>

            {/* кнопки */}
            <div className="mt-4" />
            <SaveAndPublishButtons
                isPublished={values.isPublished || false}
                isValid={isValid}
                isDirty={dirty}
                pathToList="/admin/articles"
                onSave={handleSave}
                onPublish={handlePublish}
                onUnpublish={handleUnpublish}
            />
        </Form>
    );
});

export default PortalBlockForm;
