import React, { useState } from 'react';
import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { FormikErrors } from 'formik/dist/types';

import ReactDropzone from 'components/inputs/ReactDropzone';
import ActionIcon from 'components/icons/ActionIcon';
import FormTextInput from 'components/inputs/FormTextInput';
import FormCheckBox from 'components/inputs/FormCheckBox';
import { IQuizUpdate, IRating, IMeta } from 'store/quiz';
import { Counter } from './Counter';
import { createMetaByRating } from './tools';

//************************************************************************************************************
//подготовить пустой критерий
export const createEmptyRating = (): IRating => ({
    text: '',
    maxCount: 0,
});

//************************************************************************************************************
//Компонент Ratings

interface IQuizProps {
    handleChange: <T>(arg: T) => void;
    errors: Partial<IQuizUpdate> | undefined;
    setFieldValue: (
        field: string,
        value: IRating[] | number | boolean | null | string | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    value: IQuizUpdate;
    meta: IMeta;
}

export const Ratings: React.FC<IQuizProps> = ({ errors, handleChange, setFieldValue, value, meta }) => {
    const { ratings } = value;
    //массив keys держит ключи для рендеринга списка критериев
    const [keys, setKeys] = useState(() => ratings?.map(() => uuid()) || []);

    //выровнять значения критериев
    const handleAlignment = () => {
        if (!value.questions || value.questions.length === 0) return;
        if (!ratings || ratings.length === 0) return;

        const questionsCount = value.questions.length;
        const ratingCount = ratings.length;

        if (questionsCount < ratingCount) return;
        const delta = Math.trunc(questionsCount / ratingCount); //сколько баллов на каждый критерий
        for (let i = 0; i <= ratingCount - 2; i++) setFieldValue(`ratings[${i}].maxCount`, delta * (i + 1));
        setFieldValue(`ratings[${ratingCount - 1}].maxCount`, questionsCount);
    };

    //добавить критерий
    /* eslint-disable no-param-reassign */
    const handleAddRating = () => {
        if (!value.questions || value.questions.length === 0) return;
        const questionsCount = value.questions.length;

        //нельзя добавить, если последняя градация содержит максимальное значение
        if (ratings && ratings.length !== 0 && ratings[ratings.length - 1].maxCount >= questionsCount) return;

        //добавить ключ для критерия
        setKeys((prev) => [...prev, uuid()]);
        //добавить сам критерий
        const newRating = createEmptyRating();
        newRating.maxCount = questionsCount;
        const v = ratings ? [...ratings, newRating] : [newRating];
        setFieldValue('ratings', v);
        //добавить информацию в meta
        const newMeta = createMetaByRating(newRating);
        meta.ratings = meta.ratings ? [...meta.ratings, newMeta] : [newMeta];
    };
    /* eslint-enable no-param-reassign */

    //удалить критерий
    /* eslint-disable no-param-reassign */
    const handleRemoveRating = (indexRating: number) => {
        if (!ratings || ratings.length <= 1) return;
        //удалить ключ критерия
        setKeys((prev) => prev.filter((item, index) => index !== indexRating));
        //удалить сам критерий
        const v = ratings ? ratings.filter((item, index) => index !== indexRating) : [];
        setFieldValue('ratings', v);
        //удалить и нформацию в meta
        meta.ratings = meta.ratings.filter((item, index) => index !== indexRating);
    };
    /* eslint-enable no-param-reassign */

    //уменьшить значение критерия
    const maxCountMinus = (indexRating: number) => {
        if (!ratings || !ratings[indexRating]) return;

        const v = ratings[indexRating].maxCount - 1;

        //не может быть меньше 0
        if (v <= 0) return;

        //не может быть меньше предыдущей градации
        if (indexRating > 0 && v <= ratings[indexRating - 1].maxCount) return;

        setFieldValue(`ratings[${indexRating}].maxCount`, v);
    };

    //увеличить значение критерия
    const maxCountPlus = (indexRating: number) => {
        if (!value.questions || value.questions.length === 0) return;
        if (!ratings || ratings.length === 0 || !ratings[indexRating]) return;

        const questionsCount = value.questions.length;
        const ratingCount = ratings.length;
        const v = ratings[indexRating].maxCount + 1;

        //не может быть больше количества вопросов
        if (v > questionsCount) return;

        //не может быть больше следующей градации
        if (indexRating < ratingCount - 1 && v >= ratings[indexRating + 1].maxCount) return;

        setFieldValue(`ratings[${indexRating}].maxCount`, v);
    };

    //поменять значение isCover (checkbox) для критерия indexRating
    /* eslint-disable no-param-reassign */
    const handleIsCoverRating = async (indexRating: number) => {
        const newValue = !meta.ratings[indexRating].isCover;
        meta.ratings[indexRating].isCover = newValue;
        //всегда обновляем значение => обновляем экран
        setFieldValue(`ratings[${indexRating}].cover`, '');
    };
    /* eslint-enable no-param-reassign */

    return (
        <div>
            {ratings && (
                <>
                    {/* выравнивание значений */}
                    <Row
                        className="d-flex align-items-center mb-3 ml-3 mr-3 p-3 border border-2 rounded"
                        style={{ backgroundColor: '#FCEEE4' }}
                    >
                        <Col className="d-flex col-1 justify-content-end">
                            <i className="fas fa-exclamation text-success" />
                        </Col>
                        <Col className="col-6">
                            <span className="h5">
                                Вы можете выполнить автоматическое выравнивание значений градаций.{' '}
                                <strong>Сейчас всего вопросов: {`${value.questions?.length}`}</strong>
                            </span>
                        </Col>
                        <Col className="col-5">
                            <Button
                                className="btn-icon"
                                color="primary"
                                outline={true}
                                type="button"
                                onClick={handleAlignment}
                            >
                                ВЫРОВНЯТЬ
                            </Button>
                        </Col>
                    </Row>

                    {/* Список критериев */}
                    {ratings.map((rating, indexRating) => (
                        // карточка критерия
                        <Card
                            key={`${keys[indexRating]}`}
                            className="card-plain"
                            style={{ backgroundColor: '#FCEEE4' }}
                        >
                            <CardBody>
                                {/* счетчик */}

                                <Row className="d-flex justify-content-left align-items-center mb-1">
                                    <Col className="col-3">
                                        <span className="h5">Правильных ответов меньше или равно</span>
                                    </Col>
                                    <Col className="col-2">
                                        <Counter
                                            value={rating.maxCount}
                                            onPlus={() => maxCountPlus(indexRating)}
                                            onMinus={() => maxCountMinus(indexRating)}
                                        />
                                    </Col>
                                    <Col className="col-7">
                                        <ActionIcon
                                            title="Удалить градацию"
                                            activeColor="#fb6340"
                                            onClick={() => handleRemoveRating(indexRating)}
                                            className="fas fa-trash-alt"
                                        />
                                    </Col>
                                </Row>

                                {/* описание результата */}
                                <Row>
                                    <Col className="col-9 mb-3">
                                        <FormTextInput
                                            type="textarea"
                                            rows={4}
                                            invalidText={errors?.ratings && errors.ratings[indexRating].text}
                                            placeholder="Комментарий к результату"
                                            name={`ratings[${indexRating}].text`}
                                            value={rating.text}
                                            handleChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                {/* переключатель картинки */}
                                <Row>
                                    <Col className="col-10 mb-3">
                                        <FormCheckBox
                                            name={`ratings[${indexRating}].isCover`}
                                            title="С картинкой"
                                            value={meta.ratings[indexRating].isCover}
                                            handleChange={() => handleIsCoverRating(indexRating)}
                                        />
                                    </Col>
                                </Row>

                                {/* обложка */}
                                {meta.ratings[indexRating].isCover && (
                                    <Row>
                                        <Col className="mb-3 col-7">
                                            <ReactDropzone
                                                buttonText="Загрузите обложку здесь"
                                                name={`ratings[${indexRating}].cover`}
                                                label="Картинка к комментарию"
                                                setFieldValue={setFieldValue}
                                                preloadedImageUrl={ratings[indexRating].cover}
                                                invalidText={errors?.ratings && errors.ratings[indexRating].cover}
                                                clearValueOnError={false}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    ))}
                </>
            )}

            {/* добавить критерий */}
            <Button className="btn-icon btn-3" color="primary" outline={true} type="button" onClick={handleAddRating}>
                <span className="btn-inner--icon">
                    <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">ДОБАВИТЬ</span>
            </Button>
        </div>
    );
};
