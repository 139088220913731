import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AuthLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Admin';
import { useStore } from 'store';

const App: React.FC = observer(() => {
    const authStore = useStore('auth');

    useEffect(() => {
        if (!authStore.isLoggedIn) {
            authStore.loginByToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isLoggedIn]);

    return (
        <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="*" element={<Navigate to="/admin" replace={true} />} />
        </Routes>
    );
});

export default App;
