/* eslint-disable import/prefer-default-export */
export interface ITab {
    tabId: number;
    href: string;
    title: string;
    disabled?: boolean;
}

export interface ITabs {
    activeTab: number;
    tabArray: ITab[];
}

export const tabArray: ITab[] = [
    { title: 'Викторина', href: '#', tabId: 1 },
    { title: 'Вопросы', href: '#', tabId: 2 },
    { title: 'Пояснения', href: '#', tabId: 3 /*, disabled: true */ },
    { title: 'Результаты', href: '#', tabId: 4 },
    { title: 'Публикация', href: '#', tabId: 5 },
];
