import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AdminViewHeader from 'components/headers/AdminViewHeader';

const StyledI = styled.i<{
    color?: string;
    activeColor?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color || '#0d6efd'};
    cursor: pointer;
    font-size: ${({ fontSize }) => fontSize || '2.1em'};
    :hover {
        color: ${({ activeColor }) => activeColor || '#2dce89'};
    }
`;

interface IProps {
    icon: string;
    handleClick?: () => void;
}

const RoundedButton: React.FC<IProps> = ({ icon, handleClick }) => {
    return (
        <Button
            className="border border-primary"
            outline={true}
            color="secondary"
            type="button"
            style={{
                width: '4em',
                height: '4em',
                lineHeight: 1.33,
                padding: '0',
                borderRadius: '50%',
            }}
            onClick={() => {
                if (handleClick) handleClick();
            }}
        >
            <StyledI className={icon} />
        </Button>
    );
};

const Settings: React.FC = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <AdminViewHeader title="Настройка и обслуживание системы">
                <span className="h1 btn-inner--icon text-red">
                    <i className="fas fa-tools" />
                </span>
            </AdminViewHeader>
            <Container className="mt--6">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="py-3">
                                <h3 className="mb-0">Системные настройки</h3>
                            </CardHeader>
                            <CardBody className="pt-0 pb-3">
                                <Row className="mt-2 pt-2">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="fas fa-shield-alt"
                                            handleClick={() => navigate('/admin/security')}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Безопасность</span>
                                        <span className="h4">управление аутентификацией и паролями</span>
                                    </Col>
                                </Row>
                                <Row className="mt-2 pt-2 border-top">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="fas fa-bell"
                                            handleClick={() => navigate('/admin/notice')}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Уведомления</span>
                                        <span className="h4">
                                            настройка уведомлений администратора о системных событиях
                                        </span>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="py-3">
                                <h3 className="mb-0">Обслуживание системы</h3>
                            </CardHeader>
                            <CardBody className="pt-0 pb-3">
                                <Row className="mt-2 pt-2">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="far fa-eye"
                                            handleClick={() => navigate('/admin/logger')}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Протокол событий</span>
                                        <span className="h4">просмотр полного системного протокола событий</span>
                                    </Col>
                                </Row>
                                <Row className="mt-2 pt-2">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="far fa-eye"
                                            handleClick={() => navigate('/admin/logger', { state: 'AdminAuthService' })}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Протокол аутентификации</span>
                                        <span className="h4">
                                            просмотр протокола событий регистрации пользователей в приложении
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mt-2 pt-2">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="far fa-eye"
                                            handleClick={() => navigate('/admin/logger', { state: 'AdminUserService' })}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Протокол изменений карточки пользователя</span>
                                        <span className="h4">
                                            просмотр протокола событий изменения карточек пользователей
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mt-2 pt-2 border-top">
                                    <Col className="col-2 d-flex justify-content-center align-items-center">
                                        <RoundedButton
                                            icon="fas fa-cog"
                                            handleClick={() => navigate('/admin/services')}
                                        />
                                    </Col>
                                    <Col className="col d-flex flex-column justify-content-start align-items-left">
                                        <span className="h3">Сервисы</span>
                                        <span className="h4">выполнение сервисных операций</span>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Settings;
