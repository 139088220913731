import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { v4 as uuid } from 'uuid';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import ErrorWarning from 'components/errors/ErrorWarning';
import ProfileForm from 'views/profile/Form';
import { useRqProfileData } from './queries';

const Profile: React.FC = () => {
    //чтение
    const profileData = useRqProfileData();

    if (profileData.isLoading || profileData.isFetching) return <Loader />;
    if (profileData.isError) return <ErrorWarning error={profileData.error as Error} />;
    if (isUndefined(profileData.data)) return null;

    return (
        <React.Fragment>
            <AdminViewHeader title="Профиль автора">
                <span className="h1 btn-inner--icon text-green">
                    <i className="fas fa-user-cog" />
                </span>
            </AdminViewHeader>

            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                {/* hide-fio */}
                                {/* <h2 className="mb-0">{`${profileData.data.firstname} ${profileData.data.lastname}`}</h2> */}
                                <h2 className="mb-0">{profileData.data.commonName}</h2>
                            </CardHeader>
                            <CardBody>
                                {/* uuid() нужен для пересоздания данных formik. Иначе остаются старые значения values */}
                                <ProfileForm key={`${uuid()}`} item={profileData.data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Profile;
