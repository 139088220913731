import { isEqual, reduce, isObject, isEmpty } from 'lodash';

interface IGetEditedValues {
    <T extends object>(initialValues: T, values: T): Partial<T>;
}

const getEditedValues: IGetEditedValues = (initialValues, values) =>
    reduce(
        values,
        (result, value, key) => {
            const initialValue = initialValues[key as keyof typeof initialValues];
            if (value instanceof Array && initialValue instanceof Array) {
                return isEqual(value, initialValue) ? result : { ...result, [key]: value };
            }

            if (isObject(value) && isObject(initialValue)) {
                const editedSubFields = getEditedValues(initialValue, value);

                return isEmpty(editedSubFields) ? result : { ...result, [key]: editedSubFields };
            }

            return isEqual(value, initialValue) ? result : { ...result, [key]: value };
        },
        {}
    );

export default getEditedValues;

type UnknownObject = Record<string, unknown>;
type UnknownArrayOrObject = unknown[] | UnknownObject;

//*************************************************************************************************************
// dirtyFields - массив объектов с измененными именами
// allValues - все текущие значения
// recognizeArrays - разбивать массив (если есть) по измененным элементам (true) или нет (false)
export const getDirtyValues = (
    dirtyFields: UnknownArrayOrObject | boolean | unknown,
    allValues: UnknownArrayOrObject | unknown,
    recognizeArrays = true
): UnknownArrayOrObject | unknown => {
    //если уже спустились до конца дерева и dirtyFields это уже только boolean
    if (dirtyFields === true) {
        return allValues;
    }

    //если имеем измененное значение = массив
    if (Array.isArray(dirtyFields)) {
        //если не разбиваем массив по измененным элементам, то возвращаем целиком массив
        if (!recognizeArrays) {
            return allValues;
        }
        //если разбиваем массив по измененным элементам, то возвращаем целиком измененные объекты
        const allValuesArray = allValues as unknown[];
        const values = dirtyFields.map((el, index) => {
            return allValuesArray[index];
        });
        return values.filter((v) => !isEmpty(v));
    }

    //спускаемся дальше по дереву измененных значений - реккурсия
    const dirtyFieldsObject = dirtyFields as UnknownObject;
    const allValuesObject = allValues as UnknownObject;

    return Object.fromEntries(
        Object.keys(dirtyFieldsObject).map((key) => [
            key,
            getDirtyValues(dirtyFieldsObject[key], allValuesObject[key], recognizeArrays),
        ])
    );
};
