import { IQuestion, IAnswer, IRating } from 'store/quiz';

//заполнить meta по данным из ответа
export const createMetaByAnswer = (a: IAnswer) => ({ isCover: !!a.cover });

//заполнить meta по данным из вопроса
export const createMetaByQuestion = (q: IQuestion) => {
    return {
        isCover: !!q.cover,
        answers: q.answers.map((a) => createMetaByAnswer(a)),
    };
};

//заполнить meta по данным из критерия
export const createMetaByRating = (r: IRating) => ({ isCover: !!r.cover });
