import { useReducer } from 'react';

//источник уведомления
type TSourceState = {
    sourceId: number;
    sourceName: string;
    warning: string;
    isLoading: boolean;
    hasError: boolean;
};

//действия с состоянием источника уведомления
type TSourceStateAction =
    | {
          type: 'SOURCE_SUCCESS';
          payload: {
              sourceId: number;
              sourceName: string;
          };
      }
    | {
          type: 'SOURCE_NOT_ENOUGH_DATA';
          payload?: {
              warning: string;
          };
      }
    | { type: 'SOURCE_START' }
    | { type: 'SOURCE_FAILURE' };

//*************************************************************************************************************
//Функция управления ТОЛЬКО состоянием источника!
//Получает:
//  state = текущее состояние state
//  action = описание действия action, которое надо выполнить с этим состоянием, action = type + payload
//Возвращает НОВОЕ состояние.
export const reducer = (state: TSourceState, action: TSourceStateAction) => {
    switch (action.type) {
        case 'SOURCE_START':
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        case 'SOURCE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                hasError: false,
                sourceId: action.payload.sourceId,
                sourceName: action.payload.sourceName,
                warning: '',
            };
        case 'SOURCE_NOT_ENOUGH_DATA':
            return {
                ...state,
                isLoading: false,
                hasError: false,
                sourceId: 0,
                sourceName: '',
                warning: action.payload?.warning || '',
            };
        case 'SOURCE_FAILURE':
            return {
                ...state,
                isLoading: false,
                hasError: true,
                sourceId: 0,
                sourceName: '',
            };
        default:
            throw new Error('reducer: некорректный action.type');
    }
};

//*************************************************************************************************************
//управление состоянием источника
export const useSourceState = () => {
    return useReducer(reducer, {
        sourceId: 0,
        sourceName: '',
        warning: '',
        isLoading: false,
        hasError: false,
    });
};
