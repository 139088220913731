import React from 'react';
import { Button, Modal } from 'reactstrap';

interface IConfirmationModal {
    isOpen: boolean;
    toggleOpen: (isOpen: boolean | ((value: boolean) => boolean)) => void;
    buttonTitle?: string;
    callback: () => void;
    buttonTitle2?: string;
    callback2?: () => void;
    title: string;
    text: string;
    modalSize?: string;
}

const ConfirmationModal: React.FC<IConfirmationModal> = ({
    title,
    text,
    buttonTitle = 'Подтвердить',
    callback,
    buttonTitle2,
    callback2,
    toggleOpen,
    isOpen,
    modalSize = '',
}) => {
    const handleSubmit = () => {
        toggleOpen(false);
        callback();
    };
    const handleSubmit2 = () => {
        if (!callback2) return;
        toggleOpen(false);
        callback2();
    };

    return (
        <React.Fragment>
            <Modal
                className={`modal-dialog-centered ${modalSize}`}
                isOpen={isOpen}
                toggle={() => toggleOpen((value) => !value)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {title}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleOpen(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">{text}</div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        className="mr-auto"
                        type="button"
                        onClick={() => toggleOpen(false)}
                    >
                        Отмена
                    </Button>
                    {buttonTitle2 && callback2 && (
                        <Button onClick={handleSubmit2} color="primary" type="button">
                            {buttonTitle2}
                        </Button>
                    )}
                    <Button onClick={handleSubmit} color="primary" type="button">
                        {buttonTitle}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ConfirmationModal;
