import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { v4 as uuid } from 'uuid';
import { Col, Row, Button } from 'reactstrap';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import TableTextTooltipField from 'components/fields/TableTextTooltipField';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import { ChooseQuizModal } from '../Components/ChooseQuizModal';
import { quizApi } from '../../../api';

const QuizResultList: React.FC = observer(() => {
    const storeName = 'quizResult';
    const store = useStore(storeName);
    //показать/скрыть диалог выбора викторины
    const [chooseQuiz, setChooseQuiz] = useState(false);
    //выбранная викторина
    const [quizTitle, setQuizTitle] = useState('');
    //refresh
    const [, setRefresh] = useState(false);

    useEffect(() => {
        const readQuiz = async (id: number) => {
            if (id) {
                try {
                    const v = await quizApi.getOne(id);
                    const { title } = v.data;
                    setQuizTitle(title);
                    // eslint-disable-next-line no-empty
                } catch (e) {
                    setQuizTitle('не найдена!');
                }
            }
        };

        const quizId: number = store.list.persistedFilters.quizId as number;
        if (quizId !== undefined) readQuiz(quizId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.list.persistedFilters.quizId]);

    const columns: ColumnDescription[] = [
        {
            formatter: (percent, row) => <span key={row.id}>{`${percent}%`}</span>,
            dataField: 'percent',
            text: 'Итог',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'correctCount',
            text: 'Отвечено',
            sort: true,
            headerStyle: {
                width: '10em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'createdAt',
            text: 'Когда',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            dataField: 'userCommonName',
            text: 'Псевдоним',
            sort: true,
            formatter: (text) => <TableTextTooltipField id={`${uuid()}`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'userEmail',
            text: 'Email',
            sort: true,
            formatter: (text) => <TableTextTooltipField id={`${uuid()}`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'userId',
            text: 'ID user',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'quizId',
            text: 'ID quiz',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'quizTitle',
            text: 'Викторина',
            sort: true,
            formatter: (text) => <TableTextTooltipField id={`${uuid()}`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    useResetListIfGoOut(storeName, 'quizResults');

    let title = '';
    const quizId: number = store.list.persistedFilters.quizId as number;
    if (quizId === undefined) title = 'не выбрана';
    else if (quizId === 0) title = 'весь список';
    else title = `${quizId}`;
    if (quizTitle) title = `${title} (${quizTitle})`;

    return (
        <>
            <ItemsListTable
                defaultTableParams={{
                    page: 1,
                    sizePerPage: 10,
                    sortField: '',
                    sortOrder: 'desc',
                }}
                storeName={storeName}
                columns={columns}
            >
                <Row className="d-flex flex-row align-items-start">
                    <Col>
                        <Header title={`Викторина: ${title}`} />
                    </Col>
                    <Col className="d-flex flex-row justify-content-end col-3 py-4 mr-4">
                        <Button
                            className="btn-icon"
                            color="primary"
                            size="sm"
                            outline={true}
                            type="button"
                            onClick={() => setChooseQuiz(true)}
                        >
                            <span className="btn-inner--icon">
                                <i className="fas fa-search" />
                            </span>
                            <span className="btn-inner--text">ВЫБРАТЬ</span>
                        </Button>
                        <Button
                            className="btn-icon"
                            color="primary"
                            size="sm"
                            outline={true}
                            type="button"
                            onClick={() => {
                                store.list.setPersistedFilter({ quizId: 0 });
                                setQuizTitle('');
                                setRefresh((prev) => !prev);
                            }}
                        >
                            <span className="btn-inner--icon">
                                <i className="fas fa-search-plus" />
                            </span>
                            <span className="btn-inner--text">ВСЕ</span>
                        </Button>
                    </Col>
                </Row>
            </ItemsListTable>

            {/* модальное окно для выбора викторины */}
            {chooseQuiz && (
                <ChooseQuizModal
                    isOpen={chooseQuiz}
                    toggleOpen={setChooseQuiz}
                    initialValue={store.list.persistedFilters.quizId as number}
                    callback={async (id) => {
                        //устанавливаем значение постоянного фильтра по id викторины, которое не отслеживается mobx,
                        //но добавляется к запросу на чтение списка
                        store.list.setPersistedFilter({ quizId: id });
                    }}
                />
            )}
        </>
    );
});

export default QuizResultList;
