import React from 'react';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header, { TAction } from 'components/headers/ItemListHeaderEx';
import ItemsListTable from 'components/tables/ItemsListTable';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const StationList: React.FC = observer(() => {
    const storeName = 'station';
    const navigate = useNavigate();

    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.stations;
    const isActionButtonsHidden = userAccess?.update === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={false}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            //formatter: (text, row) => <TableTextTooltipField id={`${row.id}-title`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '50em',
                maxWidth: '50em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    //*************************************************************************************************************
    //Добавить действия
    const buttons: TAction[] = [];
    if (userAccess?.create === true) {
        buttons.push({ name: 'Добавить филиал', handle: () => navigate('/admin/stations/create') });
    }

    useResetListIfGoOut(storeName, 'stations');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'title',
                sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Филиалы" buttons={buttons} />
        </ItemsListTable>
    );
});

export default StationList;
