import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Button } from 'reactstrap';
import FileSaver from 'file-saver';
import { template } from 'lodash';
import notifyStore from 'store/notification';
import Notification from 'components/notifications/Notification';
import { AlertOptions } from 'react-notification-alert';

import * as appConst from 'app.const';
import { IEditorData } from '../types';

interface IProps {
    getEditorData: () => IEditorData;
    setUnsavedTab: (isSaved: boolean) => void;
    setSavingFunction: (func: () => Promise<boolean>) => void;
}
const EventHtmlEditor: React.FC<IProps> = ({ getEditorData, setUnsavedTab, setSavingFunction }) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const handleSave = useRef(async () => {
        if (editorRef.current) {
            setUnsavedTab(true);
            editorRef.current.setDirty(false);

            const result = template(getEditorData().pattern)({
                fonts: getEditorData().fonts,
                style: getEditorData().style,
                content: editorRef.current.getContent(),
            });

            const blob = new Blob([result], { type: 'text/plain' });
            FileSaver.saveAs(blob, 'calendar.html');
        }
        return true;
    }).current;

    useEffect(() => {
        setSavingFunction(handleSave);
    }, [setSavingFunction, handleSave]);

    return (
        <React.Fragment>
            <Editor
                tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
                initialValue={getEditorData().content}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                }}
                onDirty={() => {
                    //информирование о несохраненных данных в закладке
                    setUnsavedTab(false);
                }}
                init={{
                    //entity_encoding: 'raw',
                    language: 'ru_RU',
                    height: '84vh',
                    content_css: getEditorData().fonts,
                    content_css_cors: true,
                    content_style: getEditorData().style,
                    visual: false,
                    menu: {
                        file: { title: 'File', items: 'print' },
                        edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                        insert: {
                            title: 'Insert',
                            items: 'image link media inserttable | charmap | anchor | insertdatetime',
                        },
                        view: {
                            title: 'View',
                            items: 'code | visualaid | preview fullscreen',
                        },
                        format: {
                            title: 'Format',
                            items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
                        },
                        table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                        tools: { title: 'Tools', items: 'code' },
                        help: { title: 'Help', items: 'help' },
                    },
                    menubar: 'file edit insert view format table tools help',
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'anchor',
                        'searchreplace',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'help',
                        'print',
                    ],
                    toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | ' +
                        'bullist numlist outdent indent | ' +
                        'removeformat | help',
                    file_picker_types: 'image',
                    file_picker_callback(cb /*, value, meta */) {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');

                        input.onchange = (event) => {
                            const file = (event.target as HTMLInputElement).files?.item(0);
                            if (!file) return;
                            if (file.size > appConst.maxEventHtmlImageSize) {
                                notifyStore.showNotify({
                                    place: 'br',
                                    message: `Max размер файла изображения ${appConst.maxEventHtmlImageSize / 1024}K`,
                                    type: 'danger',
                                } as AlertOptions);

                                return;
                            }

                            const reader = new FileReader();
                            reader.onload = () => {
                                if (editorRef.current && reader.result) {
                                    const id = `blobid${new Date().getTime()}`;
                                    const blobCache = editorRef.current.editorUpload.blobCache;
                                    const base64 = (reader.result as string).split(',')[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                                    const v = blobInfo.blobUri();
                                    cb(v, { title: file.name });
                                }
                            };
                            reader.readAsDataURL(file);
                        };

                        input.click();
                    },
                }}
            />
            <Button className="mt-4" color="primary" type="button" outline={true} onClick={handleSave}>
                Сохранить как html файл
            </Button>
            <Button
                className="mt-4"
                color="primary"
                type="button"
                outline={true}
                onClick={() => {
                    editorRef.current?.execCommand('mcePrint');
                }}
            >
                Печать
            </Button>
            <Notification />
        </React.Fragment>
    );
};

export default EventHtmlEditor;
