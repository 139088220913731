import { AxiosError } from 'axios';

const prepareError = (err: AxiosError): string => {
    if (err.response?.data.message) {
        return err.response.data.message;
    }

    if (err.response?.statusText) {
        return `${err.message}: ${err.response.statusText}`;
    }

    return err.message ? err.message : 'Ошибка сервера';
};

export default prepareError;
