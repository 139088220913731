import { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { useStore, stores } from 'store';
import { useLocation } from 'react-router-dom';

import { ListItemsStores } from 'lib/types';

type TEditHook = {
    <T extends ListItemsStores>(arg: { storeName: T }): {
        item: typeof stores[T]['item'];
        id: string | undefined;
        itemStore: typeof stores[T];
    };
};

const useEditHooks: TEditHook = ({ storeName }) => {
    const itemStore = useStore(storeName);
    const { item } = itemStore;
    const location = useLocation();

    const arrayPath = location.pathname.split('/');

    const id = arrayPath.slice(-2)[0];

    useEffect(() => {
        if (!isUndefined(id) && isUndefined(item.data)) {
            item.getOne(+id);
        }

        return () => item.resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, item]);

    return { item, id, itemStore };
};

export default useEditHooks;
