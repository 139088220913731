import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, CardBody, Card } from 'reactstrap';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';
import { toJS } from 'mobx';
import * as yup from 'yup';

import { stat } from 'lib/stat';
import LoaderClassic from 'components/LoaderClassic';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { substrByTags, substrByStartTag } from 'lib/tools';
import {
    BlockType,
    IRawBlock,
    IParagraphBlock,
    IImageBlock,
    IHeaderBlock,
    IQuoteBlock,
    isImageBlock,
    isParagraphBlock,
    isHeaderBlock,
    isRawBlock,
    isQuoteBlock,
    createParagraphBlock,
    createImageBlock,
    cloneBlock,
} from 'components/edit/HTMLEditor/types';
import HTMLEditField from 'components/edit/HTMLEditor';
import SaveButtons from 'components/buttons/SaveButtons';
import getEditedValues from 'lib/getEditedValues';
import articleStore, { TTelegramBlockSave, TArticleDataType } from 'store/article';
import { IEventDescriptor } from 'lib/system';
import { telegramApi } from 'api';
import { useRqChannelList, useRqPublicationList, /*useRqAuthor,*/ useRqCreatePost } from '../queries';

const allowBlockTypesTgMedia = [BlockType.Image];
const allowBlockTypesTgText = [BlockType.Paragraph, BlockType.Header, BlockType.Quote, BlockType.Raw];

const notify = (message: string) =>
    notifyStore.showNotify({
        place: 'br',
        message,
        type: 'success',
    } as AlertOptions);

interface ITelegramBlockForm {
    item: typeof articleStore.item;
    mode: 'create' | 'edit';
    setUnsavedTab: (isSaved: boolean) => void;
    setSavingFunction: (func: () => Promise<boolean>) => void;
}

const TelegramBlockForm: React.FC<ITelegramBlockForm> = observer(({ item, mode, setUnsavedTab, setSavingFunction }) => {
    const plainItem = toJS(item);
    const mobile = plainItem.data?.mobile;
    const articleId = plainItem.data?.id || 0;

    //информация о формате поста
    const [info, setInfo] = useState({ format: '', secondMessage: false, textLength: 0, error: '' });
    //кеш списка каналов
    const rqChannelList = useRqChannelList();
    //кеш списка публикаций статьи
    const rqPublicationList = useRqPublicationList(articleId);
    //кеш автора поста
    //const rqAuthor = useRqAuthor(values.author);
    //функция опубликования поста
    const rqCreatePost = useRqCreatePost(articleId);
    //если при вызове изменить значение, то принудительно создается новый редактор editorJS
    const forceRefreshToken = useRef(uuid());
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };

    let initialValues: TTelegramBlockSave = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        const {
            hasData,
            tgMedia,
            tgText,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        } = plainItem.data!.telegram;

        initialValues = {
            hasData,
            tgMedia,
            tgText,
        };
    } else {
        //если создание, то занести пустые данные
        initialValues = {
            hasData: false,
            tgMedia: [],
            tgText: [],
        };
    }

    const validationSchema = yup
        .object({
            tgMedia: yup.array().optional(),
            tgText: yup.array().optional(),
        })
        .test('myCustomTest', '', (obj) => {
            if (
                (obj.tgMedia instanceof Array && obj.tgMedia.length > 0) ||
                (obj.tgText instanceof Array && obj.tgText.length > 0)
            ) {
                return true;
            }
            return new yup.ValidationError('Отсутствует содержание', null, 'tgMedia');
        });

    const { dirty, values, /*handleChange,*/ handleSubmit, setFieldValue, isValid, errors, validateForm } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            if (dirty) {
                //событие при добавлении
                //описание события: соответствие между именем свойства в событии и именем поля в БД
                let eventDescriptor: IEventDescriptor | undefined;
                if (mode === 'create' || !initialValues.hasData) {
                    eventDescriptor = {
                        name: 'article_tg_new',
                        props: { article_id: 'id' },
                    };
                }

                const dataType = TArticleDataType.TELEGRAM;
                //если редактирование
                if (mode === 'edit') {
                    const editedValues: TTelegramBlockSave = getEditedValues(initialValues, data);
                    if (editedValues) {
                        item.updateOne({ dataType, telegram: { ...editedValues } }, eventDescriptor);
                    }
                }
                //если добавить
                else {
                    item.createOne({ dataType, telegram: { ...data } }, eventDescriptor);
                }
            }
        },
    });

    //*************************************************************************************************************
    //при изменении контента выполняем отложенную проверку формата (по таймеру)
    useEffect(() => {
        const controller = new AbortController();
        const timeOutId = setTimeout(() => {
            telegramApi
                .check(values.tgMedia || [], values.tgText || [], controller.signal)
                .then(({ data }) => {
                    const { format, secondMessage, textLength, error } = data;
                    setInfo({ format, secondMessage, textLength, error });
                })
                .catch(() => {
                    setInfo({
                        format: '',
                        secondMessage: false,
                        textLength: 0,
                        error: 'ошибка проверки формата поста',
                    });
                });
        }, 500);
        return () => {
            controller.abort();
            clearTimeout(timeOutId);
        };
    }, [values.tgMedia, values.tgText]);

    //информирование о несохраненных данных в закладке
    setUnsavedTab(!dirty);

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //выделить ссылку на видео из raw блока
    const getVideoFromRawBlock = (block: IRawBlock) => {
        let s = substrByTags(block.data.html, '<iframe', '>');
        if (s) {
            s = substrByStartTag(s, ' src ') || substrByStartTag(s, ' src=');
            if (s) return substrByTags(s, '"', '"');
        }

        return '';
    };

    //*************************************************************************************************************
    //заполнить поля на основе данных из статьи
    const handleSetFields = async () => {
        //скопировать блоки с изображениями из обложки и контента в медиа часть
        const imageBlockList: IImageBlock[] = [];
        if (mobile?.cover) imageBlockList.push(createImageBlock(mobile?.cover));
        if (mobile?.editorData)
            for (let i = 0; i < mobile.editorData.length; i++) {
                const imageBlock = mobile.editorData[i];
                if (isImageBlock(imageBlock)) imageBlockList.push(cloneBlock(imageBlock) as IImageBlock);
            }

        //выделить ссылку на видео из видеообложки
        let videoLink = '';
        if (mobile?.coverVideo) {
            const rawBlock = mobile.coverVideo.find((block) => block.type === BlockType.Raw);
            if (rawBlock) videoLink = getVideoFromRawBlock(rawBlock as IRawBlock);
        }

        //скопировать блоки с текстом в текстовую часть
        const textBlockList: IParagraphBlock[] = [];
        if (mobile?.title) textBlockList.push(createParagraphBlock(`<b><i>${mobile.title}</i></b>`));
        //ссылка из видеообложки
        if (videoLink) textBlockList.push(createParagraphBlock(`<a href="${videoLink}">Ссылка на видео</a>`));
        //по всем блокам из контента
        if (mobile?.editorData)
            for (let i = 0; i < mobile.editorData.length; i++) {
                const block = mobile.editorData[i];
                //если параграф
                if (isParagraphBlock(block)) textBlockList.push(cloneBlock(block) as IParagraphBlock);
                //если заголовок
                else if (isHeaderBlock(block))
                    textBlockList.push(createParagraphBlock(`<b><i>${(block as IHeaderBlock).data.text}</i></b>`));
                //если ссылка на видео
                else if (isRawBlock(block)) {
                    const link = getVideoFromRawBlock(block as IRawBlock);
                    if (link && link !== videoLink)
                        textBlockList.push(createParagraphBlock(`<a href="${link}">Ссылка на видео</a>`));
                }
                //если цитата
                else if (isQuoteBlock(block)) {
                    let text = (block as IQuoteBlock).data.text;
                    if (text) textBlockList.push(createParagraphBlock(`<i>${text}</i>`));
                    text = (block as IQuoteBlock).data.caption;
                    if (text) textBlockList.push(createParagraphBlock(`<i>${text}</i>`));
                }
            }
        //if (rqAuthor.data) textBlockList.push(createParagraphBlock(`Автор: ${rqAuthor.data}`));

        //изменить значение для принудительного создания нового редактора editorJS с новым текстом
        forceRefreshToken.current = uuid();
        const results = [];
        results.push(setFieldValue('tgMedia', imageBlockList));
        results.push(setFieldValue('tgText', textBlockList));
        await Promise.all(results);
    };

    //*************************************************************************************************************
    //вернуть объект channel
    const findChannel = (channelId: number) => rqChannelList.data?.find((v) => v.id === channelId);

    //*************************************************************************************************************
    //вернуть true, если статья опубликована в канале channelId и этот канал НЕ тестовый
    const isPublishedInProd = (channelId: number) => {
        const pub = rqPublicationList.data?.find((v) => v.tgChannelId === channelId);
        if (!pub) return false;
        const ch = findChannel(pub.tgChannelId);
        return !!ch && ch.isTest === false;
    };

    //*************************************************************************************************************
    //опубликовать пост articleId в канале channelId
    const handleCreatePost = (channelId: number) => {
        if (!articleId) return;
        rqCreatePost(
            { action: 'create', channelId },
            {
                onSuccess: () => {
                    const ch = findChannel(channelId);
                    if (ch && ch.isTest === false)
                        stat.addEvent({
                            name: 'article_tg_publish',
                            value: { articleId, channelId, channel_title: ch.title },
                        }); //событие
                    notify('Пост опубликован');
                },
            }
        );
    };

    //*************************************************************************************************************
    //исправить пост articleId в канале channelId
    const handleUpdatePost = (channelId: number) => {
        if (!articleId) return;
        rqCreatePost(
            { action: 'update', channelId },
            {
                onSuccess: () => {
                    const ch = findChannel(channelId);
                    if (ch && ch.isTest === false)
                        stat.addEvent({
                            name: 'article_tg_update',
                            value: { articleId, channelId, channel_title: ch.title },
                        }); //событие
                    notify('Пост исправлен');
                },
            }
        );
    };

    //*************************************************************************************************************
    //удалить пост articleId в канале channelId
    const handleDeletePost = (channelId: number) => {
        if (!articleId) return;
        rqCreatePost(
            { action: 'delete', channelId },
            {
                onSuccess: () => {
                    const ch = findChannel(channelId);
                    if (ch && ch.isTest === false)
                        stat.addEvent({
                            name: 'article_tg_delete',
                            value: { articleId, channelId, channel_title: ch.title },
                        }); //событие
                    notify('Пост удален');
                },
            }
        );
    };

    //*************************************************************************************************************
    //вернуть true, если статья опубликована в канале channelId
    const isPublished = (channelId: number) => {
        return rqPublicationList.data?.findIndex((v) => v.tgChannelId === channelId) !== -1;
    };

    //*************************************************************************************************************
    //вернуть строку даты публикации
    const createdAt = (channelId: number) => {
        const pub = rqPublicationList.data?.find((v) => v.tgChannelId === channelId);
        if (!pub) return '';

        return formatDate(pub.createdAt, config.formats.time);
    };

    //*************************************************************************************************************
    //вернуть строку даты изменения публикации
    const updatedAt = (channelId: number) => {
        const pub = rqPublicationList.data?.find((v) => v.tgChannelId === channelId);
        if (!pub) return '';

        return formatDate(pub.updatedAt, config.formats.time);
    };

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = useRef(async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return false;
        handleSubmit();
        return true;
    }).current;

    useEffect(() => {
        setSavingFunction(handleSave);
    }, [setSavingFunction, handleSave]);

    //ОШИБКА в formik!
    //если в списке контролов есть только один контрол, например, ввод текста, то при нажатии enter форма валится
    //причем событие submit и соответствующие кнопки в этом не участвуют
    //
    return (
        <React.Fragment>
            <Row className="mb-2 justify-content-end">
                <Col className="col-auto">
                    <Button
                        className="btn-icon"
                        color="primary"
                        outline={true}
                        type="button"
                        size="sm"
                        onClick={handleSetFields}
                    >
                        СКОПИРОВАТЬ ИЗ МОБ ПРИЛОЖЕНИЯ
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col className="mb-3">
                    <HTMLEditField
                        name="tgMedia"
                        label="Медиа часть"
                        initialValue={values.tgMedia}
                        invalidText={errors.tgMedia}
                        setFieldValue={setFieldValue}
                        forceRefreshToken={forceRefreshToken.current}
                        blockTypes={allowBlockTypesTgMedia}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mb-3">
                    <HTMLEditField
                        name="tgText"
                        label="Текстовая часть"
                        initialValue={values.tgText}
                        invalidText={errors.tgText}
                        setFieldValue={setFieldValue}
                        forceRefreshToken={forceRefreshToken.current}
                        blockTypes={allowBlockTypesTgText}
                    />
                </Col>
            </Row>

            {/* Формат поста в Телеграм */}
            <Row className="mb-0">
                <Col className="col-10">
                    <span className="h3">Внешний вид в Телеграм</span>
                </Col>
            </Row>
            <Card className="mt-3 mb-3">
                <CardBody className="pt-0 pb-1" style={{ backgroundColor: '#FCEEE4' }}>
                    <Row className="mt-1">
                        <Col>
                            <span>Формат: </span>
                            <span className="h2">
                                <i>{info.format}</i>
                            </span>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <span>
                                Длина текстового блока: <i>{info.textLength}</i>
                            </span>
                        </Col>
                    </Row>
                    {info.secondMessage && (
                        <Row className="mt-1">
                            <Col>
                                <span className="h3 text-success">+ дополнительное текстовое сообщение</span>
                            </Col>
                        </Row>
                    )}
                    {info.error && (
                        <Row className="mt-1">
                            <Col>
                                <span className="h3 text-danger">{info.error}</span>
                            </Col>
                        </Row>
                    )}
                </CardBody>
            </Card>

            {/* Список каналов */}
            <Row className="mb-0">
                <Col className="col-10">
                    <span className="h3">Управление публикацией в каналах</span>
                </Col>
            </Row>
            {rqChannelList.isLoading && (
                <Row className="mt-5 d-flex justify-content-center">
                    <LoaderClassic />
                </Row>
            )}
            {rqChannelList.isSuccess &&
                rqChannelList.data.map((channel) => (
                    <Card key={`${channel.id}`} className="mt-3 mb-0">
                        <CardBody className="pt-0 pb-1" style={{ backgroundColor: '#FCEEE4' }}>
                            <Row className="mt-1">
                                <Col>
                                    <span>Канал: </span>
                                    <span className="h2">
                                        <i>{channel.title}</i>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col className="col-md">
                                    <Row className="mt-1 ml-2">
                                        <span>Тип:</span>
                                        <span
                                            className={`h3 ${
                                                channel.isTest ? 'text-success' : 'text-danger'
                                            } ml-1  m-0`}
                                        >
                                            {channel.isTest ? 'Тестовый' : 'Рабочий'}
                                        </span>
                                    </Row>
                                    <Row className="mt-1 ml-2">
                                        <span>Опубликовано:</span>
                                        <span className="ml-1">{createdAt(channel.id)}</span>
                                    </Row>
                                    <Row className="ml-2">
                                        <span>Изменено:</span>
                                        <span className="ml-1">{updatedAt(channel.id)}</span>
                                    </Row>
                                </Col>
                                <Col className="col-md d-flex flex-row align-items-center justify-content-center">
                                    <Button
                                        className="btn-icon"
                                        color="primary"
                                        outline={true}
                                        type="button"
                                        size="sm"
                                        onClick={() => handleCreatePost(channel.id)}
                                        disabled={dirty || isPublishedInProd(channel.id)}
                                    >
                                        ОПУБЛИКОВАТЬ
                                    </Button>
                                    <Button
                                        className="btn-icon"
                                        color="primary"
                                        outline={true}
                                        type="button"
                                        size="sm"
                                        onClick={() => handleUpdatePost(channel.id)}
                                        disabled={dirty || !isPublished(channel.id)}
                                    >
                                        ИСПРАВИТЬ
                                    </Button>
                                    <Button
                                        className="btn-icon"
                                        color="primary"
                                        outline={true}
                                        type="button"
                                        size="sm"
                                        onClick={() => handleDeletePost(channel.id)}
                                        disabled={dirty || !isPublished(channel.id)}
                                    >
                                        СНЯТЬ С ПУБЛИКАЦИИ
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                ))}

            {/* кнопки */}
            <div className="mt-4" />
            <SaveButtons isValid={isValid} isDirty={dirty} pathToList="/admin/articles" onSave={handleSave} />
        </React.Fragment>
    );
});

export default TelegramBlockForm;
