import React from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';

import FormTextInput from 'components/inputs/FormTextInput';
import tagStore, { ITagUpdate } from 'store/tag';
import getEditedValues from 'lib/getEditedValues';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';

interface ITagEditForm {
    store: typeof tagStore.item;
}

const TagEditForm: React.FC<ITagEditForm> = ({ store }) => {
    const initialValues: ITagUpdate = store.data ?? { title: '' };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            const editedFields = getEditedValues(initialValues, values);
            if (formik.dirty) {
                //описание события
                const eventDescriptor = {
                    name: 'tag_edit',
                    props: { tag_id: 'id', tag_name: 'title' },
                };
                //исправить объект
                store.updateOne(editedFields as ITagUpdate, eventDescriptor);
            }
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Название тэга"
                        name="title"
                        value={formik.values.title}
                        handleChange={formik.handleChange}
                    />
                </Col>
            </Row>
            <FormBackOrSubmitButtons backPath="/admin/tags" disabled={!formik.dirty} />
        </Form>
    );
};

export default TagEditForm;
