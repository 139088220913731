import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import TableTextTooltipField from 'components/fields/TableTextTooltipField';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import { AdviceStatus, AdviceStatusTitle } from 'store/advice';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const AdviceList: React.FC = observer(() => {
    const storeName = 'advice';
    const { list: listStore } = useStore(storeName);

    useEffect(() => {
        listStore.enableFilter('status');
    }, [listStore]);

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.advices;

    const isActionButtonsHidden = userAccess?.read === false; //право на установку признака прочтения

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.read === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            formatter: (status: keyof typeof AdviceStatus) => AdviceStatusTitle[status],
            dataField: 'status',
            text: 'Статус',
            sort: true,
            style: {
                maxWidth: '20em',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'text',
            text: 'Содержание',
            sort: true,
            formatter: (text, row) => <TableTextTooltipField id={`${row.id}-text`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            dataField: 'userCommonName',
            text: 'Псевдоним',
            sort: true,
            formatter: (text, row) => (
                <TableTextTooltipField id={`${row.id}-userCommonName`} text={text} isShowTooltip={true} />
            ),
            style: {
                textOverflow: 'ellipsis',
                minWidth: '30em',
                maxWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    useResetListIfGoOut(storeName, 'advices');

    return (
        <ItemsListTable
            filters={[
                {
                    type: 'checkbox',
                    name: 'status',
                    filterTitle: 'только новые предложения',
                    dropdownItemTitle: 'статус',
                },
            ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Новостные предложения" />
        </ItemsListTable>
    );
});

export default AdviceList;
