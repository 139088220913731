import React from 'react';
import { Input } from 'reactstrap';
import { isUndefined } from 'lodash';
import { InputType } from 'reactstrap/es/Input';
import ActionIcon from 'components/icons/ActionIcon';

interface IFormTextInput {
    title?: string;
    name: string;
    value: string | number | undefined;
    size?: 'sm' | 'lg';
    handleChange?: <T>(arg: T) => void;
    placeholder?: string;
    isValid?: boolean;
    validText?: string;
    invalidText?: string;
    infoText?: string;
    type?: InputType;
    disabled?: boolean;
    handleRemove?: () => void;
    rows?: number;
}

const FormTextInput: React.FC<IFormTextInput> = ({
    title,
    name,
    value,
    size,
    handleChange,
    placeholder,
    isValid,
    validText,
    invalidText,
    disabled,
    type,
    handleRemove,
    rows,
    infoText,
}) => {
    return (
        <React.Fragment>
            {!isUndefined(title) && (
                <label className="form-control-label" htmlFor={name}>
                    {title}
                </label>
            )}
            {!isUndefined(handleRemove) && (
                <ActionIcon
                    fontSize="0.8em"
                    title="Удалить параметр"
                    activeColor="#f5365c"
                    onClick={handleRemove}
                    className="fas fa-trash-alt ml-2"
                />
            )}
            <Input
                rows={rows || 2}
                disabled={disabled}
                bsSize={size}
                id={name}
                name={name}
                placeholder={placeholder}
                type={type}
                valid={isValid}
                invalid={Boolean(invalidText)}
                value={value}
                onChange={handleChange}
            />
            {!isUndefined(infoText) && (
                <div className="text-muted">
                    <small>* {infoText}</small>
                </div>
            )}
            <div className="valid-feedback">{validText}</div>
            <div className="invalid-feedback">{invalidText}</div>
        </React.Fragment>
    );
};

FormTextInput.defaultProps = {
    type: 'text',
};

export default FormTextInput;
