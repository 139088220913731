import React from 'react';

import ItemCreate from 'components/create/ItemCreate';
import RoleCreateForm from 'views/role/RoleCreate/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const RoleCreate: React.FC = () => {
    useResetListIfGoOut('role', 'roles');

    return (
        <ItemCreate title="Добавить роль" headerTitle="Новая роль">
            <RoleCreateForm />
        </ItemCreate>
    );
};

export default RoleCreate;
