import { useQuery, useMutation } from 'react-query';

import { securityApi } from 'api';

export type TSecurity = {
    roleId: number;
    title: string;
    ttl: boolean;
    factor2: boolean;
};

export type TSecurityArray = TSecurity[];

//*************************************************************************************************************
//чтение
export function useRqSecurityData() {
    return useQuery<TSecurityArray>(
        ['securityData'],
        async () => {
            const { data } = await securityApi.get();
            return data;
        },
        {
            staleTime: 0,
        }
    );
}

//*************************************************************************************************************
//исправить
export const useRqUpdateSecurity = () => {
    const mutation = useMutation((security: TSecurityArray) => securityApi.update({ data: security }));

    return mutation;
};
