import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Header, { TAction } from 'components/headers/ItemListHeaderEx';
import { getFcmStatus, FcmType, getFcmType } from 'types';

const FcmList: React.FC = observer(() => {
    const navigate = useNavigate();
    const storeName = 'fcm';

    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.fcms;
    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Заголовок',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '40em',
                maxWidth: '40em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            dataField: 'type',
            text: 'Тип',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (type) => getFcmType(type),
        },
        {
            dataField: 'isPublished',
            text: 'Опубликовано',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (isPublished) => (isPublished ? 'Да' : 'Нет'),
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'publishedAt',
            text: 'Дата публикации',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            dataField: 'status',
            text: 'Обработка',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (status) => getFcmStatus(status),
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'statusAt',
            text: 'Дата обработки',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            style: {
                width: '12em',
            },
        },
    ];

    //*************************************************************************************************************
    //Добавить действия
    const buttons: TAction[] = [];
    if (userAccess?.create === true) {
        buttons.push({ name: 'Добавить', handle: () => navigate(`/admin/fcms/create?sourceType=${FcmType.SIMPLE}`) });
        buttons.push({
            name: 'Добавить по статье',
            handle: () => navigate(`/admin/fcms/create?sourceType=${FcmType.ARTICLE}`),
        });
        buttons.push({
            name: 'Добавить по викторине',
            handle: () => navigate(`/admin/fcms/create?sourceType=${FcmType.QUIZ}`),
        });
    }
    useResetListIfGoOut(storeName, 'fcms');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Push уведомления" buttons={buttons} />
        </ItemsListTable>
    );
});

export default FcmList;
