import { useMutation } from 'react-query';

import { fileJournalApi } from 'api';

//*************************************************************************************************************
//сохранить файл
export const useRqCreateFile = () => {
    const { mutate: rqCreateFile, isLoading } = useMutation((file: File) => {
        return fileJournalApi.createJournalFile(file);
    });

    return { rqCreateFile, isLoading };
};
