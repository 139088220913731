import React from 'react';

import { Button, Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';

interface IItemListHeader {
    title: string;
    creating?: {
        title: string;
        path: string;
    };
}

const ItemListHeader: React.FC<IItemListHeader> = ({ title, creating }) => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="header pb-6">
                <Container fluid={true}>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col>
                                <h6 className="h2 d-inline-block mb-0">{title}</h6>
                            </Col>
                            {!isUndefined(creating) && (
                                <Col className="text-right" lg="6" xs="5">
                                    <Button
                                        className="btn-primary"
                                        color=""
                                        onClick={() => navigate(creating.path)}
                                        size="sm"
                                    >
                                        {creating.title}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ItemListHeader;
