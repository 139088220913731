import React from 'react';
import { Row, Col } from 'reactstrap';

interface IErrorWarning {
    error?: Error;
}

//*************************************************************************************************************
//Сообщение об ошибке
const ErrorWarning: React.FC<IErrorWarning> = ({ error }) => {
    return (
        <>
            <Row className="mt-3 ml-3">
                <Col>
                    <span className="h3 text-danger">Ошибка обработки данных!</span>
                </Col>
            </Row>
            {error && (
                <Row className="mt-3 ml-3">
                    <Col>
                        <span className="h3 text-danger">{error.message}</span>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ErrorWarning;
