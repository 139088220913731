import styled from 'styled-components';

const StyledI = styled.i<{
    width?: string;
    height?: string;
    fontSize?: string;
}>`
    width: ${({ width }) => width || '1rem'};
    height: ${({ height }) => height || '1rem'};
    font-size: ${({ fontSize }) => fontSize || '1em'}; ;
`;

export default StyledI;
