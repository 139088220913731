import React, { useEffect, useRef } from 'react';
import './choices.css';
import Choices from 'choices.js';
import { groupBy, isUndefined } from 'lodash';
import { FormGroup } from 'reactstrap';

import { TSelectResponse, IGetSelectParams } from 'api';

interface IProps {
    name: string;
    value: number[];
    label: string;
    placeholder?: string;
    maxItemCount?: number;
    getSelect: (params: IGetSelectParams) => TSelectResponse;
    onChange: (ids: number[]) => void;
    shouldSort?: boolean;
}

const ChoicesSelectFromRemote2: React.FC<IProps> = ({
    name,
    value,
    label,
    placeholder = 'начните набирать текст',
    maxItemCount = 50,
    getSelect,
    onChange,
    shouldSort = true,
}) => {
    //ссылка на объект Choices
    const ch = useRef<Choices | null>(null);

    useEffect(() => {
        const choices = new Choices(`#choices-multiple-select-${name}`, {
            searchEnabled: true,
            delimiter: ',',
            removeItemButton: true,
            maxItemCount,
            paste: false,
            placeholder: true,
            resetScrollPosition: false,
            placeholderValue: placeholder,
            searchPlaceholderValue: placeholder,
            noResultsText: 'Ничего не найдено',
            noChoicesText: 'Нет элементов для выбора',
            itemSelectText: 'Нажмие для выбора',
            loadingText: 'Загрузка...',
            maxItemText: `Только ${maxItemCount} элементов может быть выбрано`,
            shouldSort,
        });
        ch.current = choices;

        let lastSearchText: string;

        const setChoicesFromServer = async (params: { defaultSelects?: number[]; searchText?: string }) => {
            if (!isUndefined(params.searchText) && lastSearchText === params.searchText) {
                return;
            }
            if (!isUndefined(params.searchText)) {
                lastSearchText = params.searchText;
            }

            const { data } = await getSelect(params);

            const selectedChoicesByValue: { [key: number]: object } = groupBy(choices.getValue(), 'value');

            const choicesToSet = data.result.reduce(
                (
                    acc: Array<{
                        value: number;
                        label: string;
                        selected?: boolean | undefined;
                    }>,
                    item
                ) => (selectedChoicesByValue[item.value] ? acc : [...acc, item]),
                []
            );

            if (ch.current) choices.setChoices(choicesToSet, 'value', 'label', true);
        };

        setChoicesFromServer({ defaultSelects: value });

        choices.passedElement.element.addEventListener(
            'search',
            // @ts-ignore
            ({ detail }) => setChoicesFromServer({ searchText: detail.value }),
            false
        );

        return () => {
            if (ch.current) {
                ch.current.destroy();
                ch.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormGroup className="mb-0">
            {label && (
                <label className="form-control-label" htmlFor={`choices-multiple-select-${name}`}>
                    {label}
                </label>
            )}
            <select
                onChange={() => {
                    if (ch.current) {
                        const choiceValue = ch.current.getValue(true);
                        onChange(((choiceValue as string[]) || []).map((v) => Number(v)));
                    }
                }}
                value={(value || []).map((v) => String(v))}
                aria-label={name}
                className="form-control"
                id={`choices-multiple-select-${name}`}
                multiple={true}
            />
        </FormGroup>
    );
};

export default ChoicesSelectFromRemote2;
