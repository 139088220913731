import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { getDirtyValues } from 'lib/getEditedValues';
import { sysNotify } from 'lib/system';
import { useIsMounted } from 'lib/hooks';
import FormTextInput from 'components/inputs.uncontrolled/FormTextInputRhf';
import ChoicesSelect from 'components/inputs.uncontrolled/dropdown-selects/ChoicesSelectFromRemoteRhf';
import SaveButtons from 'components/buttons/SaveButtons';
import { tagApi } from 'api';
import { IItem, IItemContent } from '../types';
import { useRqItemUpdate, useRqItemCreate } from '../queries';

interface IProps {
    mode: 'edit' | 'create';
    id?: number;
    content?: Partial<IItemContent>;
}

const validationSchema = yup.object().shape({
    title: yup.string().max(128, `максимум 128 символов`).required(`поле не заполнено`),
    text: yup.string().required(`поле не заполнено`),
});

const ItemForm: React.FC<IProps> = ({ id, content, mode }) => {
    const navigate = useNavigate();
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    const rqItemCreate = useRqItemCreate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    let defaultValues: Partial<IItemContent> = {};
    if (mode === 'create') {
        defaultValues = {
            title: '',
            text: '',
            tags: [],
        };
    } else {
        const { id: ignoreItemId, ...rest } = content as IItem;
        defaultValues = { ...rest };
    }

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
    } = useForm<IItemContent>({ defaultValues, resolver: yupResolver(validationSchema) });

    const onSubmit = (values: IItemContent) => {
        if (mode === 'edit' && id) {
            rqItemUpdate.mutate(
                { id, content: getDirtyValues(dirtyFields, values) as Partial<IItemContent> },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //notify + событие countly
                            sysNotify('update', id, data /*, eventDescriptor*/);
                        }
                    },
                }
            );
        } else if (mode === 'create') {
            rqItemCreate.mutate(
                { content: values },
                {
                    onSuccess: (data /*, variables, context */) => {
                        if (isMounted.current) {
                            reset(data);
                            //notify + событие countly
                            sysNotify('create', data.id, data /*, eventDescriptor*/);
                            //после создания переход на редактирование
                            navigate(`/admin/channels/${data.id}/edit`);
                        }
                    },
                }
            );
        }
    };

    const isLoading = rqItemUpdate.isLoading || rqItemCreate.isLoading;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Название канала */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        control={control}
                        name="title"
                        invalidText={errors.title?.message}
                        title="Название канала"
                    />
                </Col>
            </Row>

            {/* Описание канала */}
            <Row>
                <Col className="mb-3" md="10">
                    <FormTextInput
                        control={control}
                        name="text"
                        invalidText={errors.text?.message}
                        title="Описание"
                        type="textarea"
                        rows={3}
                        placeholder="Введите описание"
                    />
                </Col>
            </Row>

            {/* Теги */}
            <Row>
                <Col className="mb-3" md="6">
                    <ChoicesSelect
                        name="tags"
                        control={control}
                        maxItemCount={20}
                        getSelect={tagApi.getSelect}
                        placeholder="Начните вводить название тэга"
                        label="Тэги"
                    />
                </Col>
            </Row>

            <div className="mt-4" />
            <SaveButtons
                buttonType="submit"
                isDirty={isDirty}
                pathToList="/admin/channels"
                disabled={isLoading}
                isLoading={isLoading}
            />
        </Form>
    );
};

export default ItemForm;
