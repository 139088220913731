import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col, Collapse } from 'reactstrap';

import { IQuizUpdate } from 'store/quiz';
import { useStore } from 'store';

interface IQuizProps {
    value: IQuizUpdate;
    onGoToTab: (numTab: number) => void;
}

//************************************************************************************************************
//Компонент Publication
export const Publication: React.FC<IQuizProps> = ({ value, onGoToTab }) => {
    const quizStore = useStore('quiz');
    const { questions } = value;
    //текущий вопрос
    const [openedCollapses, setOpenedCollapses] = useState([-1]);

    //переключатель collapse
    const collapsesToggle = (collapse: number) => {
        //свернуть все
        if (openedCollapses.includes(collapse)) setOpenedCollapses([]);
        //развернуть заданный
        else {
            quizStore.indexLastQuestion = collapse; //индекс последнего развернутого вопроса
            setOpenedCollapses([collapse]);
        }
    };

    return (
        <>
            <div className="accordion">
                {questions && (
                    <>
                        {/* Список вопросов */}
                        {questions.map((question, indexQuestion) => (
                            // карточка вопроса
                            // eslint-disable-next-line react/no-array-index-key
                            <Card key={`question_${indexQuestion}`} className="card-plain">
                                {/* заголовок вопроса с символом свертки */}
                                <CardHeader
                                    role="tab"
                                    onClick={() => collapsesToggle(indexQuestion)}
                                    aria-expanded={openedCollapses.includes(indexQuestion)}
                                >
                                    <Row>
                                        <span className="mb-0 h3">
                                            <strong>{`Вопрос ${indexQuestion + 1}`}</strong>
                                        </span>
                                    </Row>
                                    <Row>
                                        <Col className="col-11 text-truncate">
                                            <span className="h4">{questions[indexQuestion].text}</span>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <Collapse role="tabpanel" isOpen={openedCollapses.includes(indexQuestion)}>
                                    <CardBody>
                                        {/* Список ответов */}
                                        {questions[indexQuestion].answers &&
                                            questions[indexQuestion].answers.map((answer, indexAnswer) => (
                                                // карточка ответа
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Row key={`answer_${indexAnswer}`} className="mb-1">
                                                    <Col className="col-10 text-truncate">
                                                        <span className="mb-0 h4">
                                                            {`${indexAnswer + 1}. ${
                                                                questions[indexQuestion].answers[indexAnswer].text
                                                            }`}
                                                        </span>
                                                    </Col>
                                                    <Col className="col-2 text-truncate">
                                                        <span className="mb-0 h4">
                                                            {answer.isCorrect ? 'правильный ответ' : '-'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            ))}

                                        <Button
                                            className="btn-icon mt-1"
                                            color="primary"
                                            outline={true}
                                            type="button"
                                            size="sm"
                                            onClick={() => onGoToTab(2)}
                                        >
                                            <span className="btn-inner--icon">
                                                <i className="fas fa-edit" />
                                            </span>
                                            <span className="btn-inner--text">РЕДАКТИРОВАТЬ</span>
                                        </Button>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};
