import { useQuery, useMutation, useQueryClient } from 'react-query';

import { profileApi } from 'api';

export type TProfile = {
    commonName: string;
    email: string;
    firstname: string;
    lastname: string;
};

//*************************************************************************************************************
//чтение
export function useRqProfileData() {
    return useQuery<TProfile>(
        ['profileData'],
        async () => {
            const { data } = await profileApi.getOne();
            return data;
        },
        {
            staleTime: 0,
        }
    );
}

//*************************************************************************************************************
//исправить
export const useRqUpdateProfile = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation((profile: Partial<TProfile>) => profileApi.updateOne(profile), {
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData(['profileData'], data.data);
        },
    });

    return mutation;
};
