import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useIsMounted } from 'lib/hooks';
import { msToMMSS } from 'lib/formatTime';
import authStore, { LoginStatus } from 'store/auth';
import FormTextInput from 'components/inputs/FormTextInput';
import { authApi } from 'api';
import { TScreen, TRequestStatus } from './login.type';
import LoginButton from './LoginButton';

interface ILoginFactor2 {
    screen: TScreen;
    setScreen: (type: TScreen) => void;
}

const LoginFactor2: React.FC<ILoginFactor2> = ({ screen, setScreen }) => {
    const navigate = useNavigate();
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };
    //данные запроса
    const [content, setContent] = useState({ status: TRequestStatus.EMPTY, message: '' });
    //состояние выполнения
    const [isLoading, setIsLoading] = useState(false);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //таймер
    const timer = useRef<NodeJS.Timer | null>(null);
    const timeLeft = useRef(screen === TScreen.SEND_CODE ? authStore.factor2SessionTTL : authStore.factor2RepeatPeriod); //остаток времени
    const timeMsgRef = useRef<HTMLSpanElement>(null); //ссылка на отображаемую строку времени

    //*************************************************************************************************************
    useEffect(() => {
        const timeMsg = () => `осталось ${msToMMSS(timeLeft.current)}`;

        const checkTimer = async () => {
            if (isMounted.current) {
                let newTimeLeft = 0;
                if (timeLeft.current >= 1000) newTimeLeft = timeLeft.current - 1000;

                //если время еще не истекло, просто изменить счетчик времени
                if (newTimeLeft !== 0) {
                    timeLeft.current = newTimeLeft;
                    if (timeMsgRef.current) timeMsgRef.current.textContent = timeMsg();
                }
                //переход на экран повторного запроса
                else if (screen === TScreen.SEND_CODE) {
                    setScreen(TScreen.REPEAT_CODE);
                }
                //переход на экран базового логина
                else {
                    navigate('/auth/login', { replace: true });
                }
            }
        };

        timer.current = setInterval(checkTimer, 1000);
        if (timeMsgRef.current) timeMsgRef.current.textContent = timeMsg();

        return () => {
            if (timer.current) clearInterval(timer.current);
        };
    }, [screen, setScreen, navigate, isMounted]);

    //*************************************************************************************************************
    //отправить код в запросе на логин
    const requestLoginByFactor2Code = (code: string) => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .loginByFactor2Code(code)
            .then((data) => {
                const { retval } = data.data;
                if (isMounted.current) {
                    switch (retval) {
                        case LoginStatus.SUCCESS:
                            authStore.setSuccessLogin(data.data);
                            break;
                        case LoginStatus.WRONG_CODE:
                            setContent({ status: TRequestStatus.DONE, message: 'некорректный код' });
                            break;
                        case LoginStatus.LOGIN_LOCKED:
                            //setContent({ status: TRequestStatus.DONE, message: 'логин временно заблокирован' });
                            navigate('/auth/login', { replace: true });
                            break;
                        case LoginStatus.CODE_EXPIRED:
                            setContent({ status: TRequestStatus.DONE, message: 'код просрочен' });
                            break;
                        default:
                            setContent({ status: TRequestStatus.DONE, message: 'ошибка аутентификации' });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    //уведомление об ошибке сервера будет автоматически отображено через ближайший компонент <Notification /> (notifyStore.showNotify)
                    //а здесь просто сохраним инфо
                    setContent({
                        status: TRequestStatus.ERROR,
                        message: /*error.message ||*/ 'ошибка аутентификации',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    //*************************************************************************************************************
    //отправить повторный запрос factor2 кода
    const requestRepeatFactor2Code = () => {
        setIsLoading(true);
        setContent((prev) => ({ ...prev, message: '' }));
        authApi
            .repeatFactor2Code()
            .then((res) => {
                const { retval } = res.data;
                if (isMounted.current) {
                    switch (retval) {
                        case LoginStatus.CODE_SEND:
                            authStore.setFactor2(res.data.token, res.data.ttl, res.data.repeatPeriod);
                            setScreen(TScreen.SEND_CODE);
                            break;
                        case LoginStatus.LOGIN_LOCKED:
                            setContent({ status: TRequestStatus.DONE, message: 'логин временно заблокирован' });
                            break;
                        case LoginStatus.NOT_FINISHED:
                            setContent({ status: TRequestStatus.DONE, message: 'старый код еще действует' });
                            break;
                        default:
                            setContent({ status: TRequestStatus.DONE, message: 'ошибка аутентификации' });
                    }
                }
            })
            // eslint-disable-next-line
            .catch((error: any) => {
                if (isMounted.current) {
                    //уведомление об ошибке сервера будет автоматически отображено через ближайший компонент <Notification /> (notifyStore.showNotify)
                    //а здесь просто сохраним инфо
                    setContent({
                        status: TRequestStatus.ERROR,
                        message: /*error.message ||*/ 'ошибка аутентификации',
                    });
                }
            })
            .finally(() => {
                if (isMounted.current) setIsLoading(false);
            });
    };

    //данные формы ввода
    const initialValues = {
        code: '',
    };

    //*************************************************************************************************************
    //Используется для проверки отдельных свойств формы
    const validationSchema = yup.object().shape({
        code: yup.string().length(6, 'код из 6 цифр').required(`Поле не должно быть пустым`),
    });

    const { dirty, values, handleChange, handleSubmit, errors, isValid, validateForm } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        // eslint-disable-next-line
        onSubmit: (data: any) => {
            if (dirty) {
                //запрос
                requestLoginByFactor2Code(data.code);
            }
        },
    });

    //*************************************************************************************************************
    //Если редактируется код, то убрать message
    useEffect(() => {
        setContent((prev) => ({ ...prev, message: '' }));
    }, [values.code]);

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        setFormSubmitted();
        handleSubmit();
        return true;
    };

    return (
        <React.Fragment>
            {screen === TScreen.SEND_CODE && (
                <div className="text-muted pr-5 mb-0">
                    <small className="text-dark">Код был отправлен на ваш e-mail</small>
                </div>
            )}

            {screen !== TScreen.SEND_CODE && (
                <div className="text-muted pr-5 mb-0">
                    <small className="text-dark">Корректный код не был введен. Попробуйте получить новый.</small>
                </div>
            )}

            <div className="text-right mt-0 mb-0">
                <small ref={timeMsgRef} className="text-muted">
                    ...
                </small>
            </div>

            <React.Fragment>
                <Row>
                    <Col className="mb-3">
                        {screen === TScreen.SEND_CODE && (
                            <FormTextInput
                                placeholder="код"
                                name="code"
                                value={values.code}
                                invalidText={errors.code}
                                handleChange={handleChange}
                                disabled={isLoading}
                            />
                        )}
                        {screen !== TScreen.SEND_CODE && <FormTextInput name="code" value="..." disabled={true} />}
                    </Col>
                </Row>

                {/* кнопка */}
                {screen === TScreen.SEND_CODE && (
                    <LoginButton
                        isDisabled={isLoading || !isValid}
                        actionName="ОТПРАВИТЬ"
                        type="button"
                        onClick={handleSave}
                        isLoading={isLoading}
                    />
                )}
                {screen !== TScreen.SEND_CODE && (
                    <LoginButton
                        isDisabled={isLoading}
                        actionName="ПОЛУЧИТЬ ПОВТОРНО"
                        type="button"
                        onClick={requestRepeatFactor2Code}
                        isLoading={isLoading}
                    />
                )}
            </React.Fragment>

            {content.message && (
                <div className="h3 text-center mt-1 mb-1">
                    <small className="text-danger">{content.message}</small>
                </div>
            )}
        </React.Fragment>
    );
};

export default LoginFactor2;
