import React from 'react';
import { Col, Row } from 'reactstrap';
import { useWatch, Control, UseFormRegister, FieldError, DeepMap, FieldValues, Path } from 'react-hook-form';
import { get } from 'lodash';

import FormTextInput from 'components/inputs.uncontrolled/FormTextInputRhf';
import FormCheckBox from 'components/inputs.uncontrolled/FormCheckBoxRhf';

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

interface Props<T extends FieldValues> {
    errors?: DeepMap<RecursivePartial<T>, FieldError>;
    control: Control<T>;
    register: UseFormRegister<T>;
    index: number;
    key: string;
    title: string;
    disabled?: boolean;
}

export const ConditionField = <T extends FieldValues>({
    control,
    register,
    errors,
    index,
    key,
    title,
    disabled = false,
}: Props<T>) => {
    //отследить переключение активности
    const isActive = useWatch({
        name: `content.${index}.active` as Path<T>,
        control,
    });

    //достаем текст ошибки через get от lodash, иначе типизация не пропускает
    const invalidText = get(errors, `content.${index}.email`);

    return (
        <Row key={key} className="d-flex justify-content-left align-items-center">
            {/* переключатель активности */}
            <Col className="col-auto mb-3 mt-4">
                <FormCheckBox register={register} name={`content.${index}.active` as Path<T>} />
            </Col>

            {/* email */}
            <Col className="mb-3">
                <FormTextInput
                    control={control}
                    name={`content.${index}.email` as Path<T>}
                    invalidText={invalidText?.message}
                    title={title}
                    disabled={disabled || !isActive}
                />
            </Col>
        </Row>
    );
};
