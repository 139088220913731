import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import 'assets/css/tooltip.css';

interface ITableTextFieldProps {
    id: string;
    text: string;
    isShowTooltip?: boolean;
}

const TableTextTooltipField: React.FC<ITableTextFieldProps> = ({ text, isShowTooltip, id }) => {
    const spanUniqId = `span-id-${id}`;

    return (
        <React.Fragment>
            <span id={spanUniqId}>{text}</span>
            {isShowTooltip && (
                <UncontrolledTooltip popperClassName="tooltip-custom" placement="bottom-start" target={spanUniqId}>
                    {text}
                </UncontrolledTooltip>
            )}
        </React.Fragment>
    );
};

TableTextTooltipField.defaultProps = {
    isShowTooltip: false,
};

export default TableTextTooltipField;
