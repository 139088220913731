import { useQuery, useMutation, useQueryClient } from 'react-query';

import { telegramApi, userApi } from 'api';
import { useIsMounted } from 'lib/hooks';

//канал Телеграм
type TChannel = {
    id: number;
    title: string;
    isTest: boolean;
};

//публикация в канале
type TPublication = {
    tgChannelId: number;
    createdAt: Date;
    updatedAt: Date;
};

//типы действий со статьей
type TAction = { action: 'create' | 'update' | 'delete'; channelId: number };

const keyPublicationList = 'publicationList';
const keyChannelList = 'channelList';

//*************************************************************************************************************
//список телеграм каналов
export function useRqChannelList() {
    return useQuery<TChannel[]>(
        [keyChannelList],
        async () => {
            const { data } = await telegramApi.getChannelList();

            return data;
        },
        {
            //Список каналов статичен. Можно принудительно обновлять при корректировке списка каналов.
            staleTime: Infinity,
        }
    );
}

//*************************************************************************************************************
//однократно обновить кеш списка каналов
export const useRqChannelListInvalidate = () => {
    const queryClient = useQueryClient();
    const isMounted = useIsMounted();
    if (!isMounted.current) queryClient.invalidateQueries([keyChannelList]);
};

//*************************************************************************************************************
//чтение автора
export function useRqAuthor(author: number | undefined) {
    return useQuery<string>(
        ['author'],
        async () => {
            if (!author) return '';
            const { data } = await userApi.getOne(author);

            return data.commonName;
        },
        {
            //имя автора статично
            staleTime: Infinity,
        }
    );
}

//*************************************************************************************************************
//список каналов с публикацией статьи
export function useRqPublicationList(articleId: number) {
    return useQuery<TPublication[]>(
        [keyPublicationList, articleId],
        async ({ queryKey: [, id] }) => {
            if (!id) return [];
            const { data } = await telegramApi.getListPublications(id as number);

            return data;
        },
        {
            staleTime: 0,
        }
    );
}

//*************************************************************************************************************
//опубликовать пост articleId в канале channelId
export const useRqCreatePost = (articleId: number) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ({ action, channelId }: TAction) => {
            switch (action) {
                case 'create':
                    return telegramApi.createOne(articleId, channelId);
                case 'update':
                    return telegramApi.updateOne(articleId, channelId);
                case 'delete':
                    return telegramApi.deleteOne(articleId, channelId);
                default:
                    throw Error('useRqCreatePost: некорректный тип действия');
            }
        },
        {
            onSuccess: () => {
                //обновить кеш списка публикаций
                queryClient.invalidateQueries([keyPublicationList, articleId]);
            },
        }
    );

    return mutation.mutate;
};
