import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { isUndefined } from 'lodash';

import ActionIcon from 'components/icons/ActionIcon';
import FormTextInput from 'components/inputs/FormTextInput';
import { FormikErrors } from 'formik/dist/types';
import { IPoll } from 'store/article';
import FormSelect from 'components/inputs/FormSelect';

interface IPollProps {
    id?: string;
    handleChange: <T>(arg: T) => void;
    errors: Partial<IPoll> | undefined;
    setFieldValue: (
        field: string,
        value: IPoll['choices'] | IPoll | boolean | null
    ) => Promise<FormikErrors<object>> | Promise<void>;
    value: IPoll | undefined;
}

const Poll: React.FC<IPollProps> = ({ id, errors, handleChange, setFieldValue, value }) => {
    const [isOpen, toggleOpen] = useState(Boolean(value));
    const [choiceRowKeys, setChoiceRowKeys] = useState(value?.choices.map(() => uuid()) || []);

    const handleOpen = () => {
        toggleOpen(true);
        setFieldValue('poll', {
            title: '',
            isSingle: true,
            isAnonymous: false,
            choices: [{ text: '' }],
        });
        setChoiceRowKeys([uuid()]);
    };

    const handleClose = () => {
        toggleOpen(false);
        setFieldValue('poll', null);
    };

    const handleAddChoice = () => {
        setChoiceRowKeys((ids) => [...ids, uuid()]);
        setFieldValue('poll.choices', value?.choices ? [...value?.choices, { text: '' }] : [{ text: '' }]);
    };

    return (
        <React.Fragment>
            <label htmlFor={id} className="form-control-label">
                Опрос
            </label>
            {isOpen && (
                <ActionIcon
                    fontSize="0.9em"
                    title="Удалить опрос"
                    activeColor="#f5365c"
                    onClick={handleClose}
                    className="fas fa-trash-alt ml-2"
                />
            )}
            {!isOpen && (
                <ActionIcon
                    fontSize="0.9em"
                    title="Добавить опрос"
                    activeColor="#2dce89"
                    onClick={handleOpen}
                    className="fas fa-plus ml-2"
                />
            )}
            <div id={id}>
                {isOpen && !isUndefined(value) && (
                    <React.Fragment>
                        <Row>
                            <Col className="mb-3">
                                <FormTextInput
                                    invalidText={errors?.title}
                                    placeholder="Введите вопрос для голосования"
                                    name="poll.title"
                                    type="textarea"
                                    value={value?.title}
                                    handleChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FormSelect
                                    title="Несколько ответов при голосовании"
                                    name="poll.isSingle"
                                    value={!value?.isSingle}
                                    handleChange={() => setFieldValue('poll.isSingle', !value.isSingle)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FormSelect
                                    title="Анонимное голосование"
                                    name="poll.isAnonymous"
                                    value={value?.isAnonymous}
                                    handleChange={handleChange}
                                />
                            </Col>
                        </Row>
                        {value?.choices.map((item, i) => (
                            <Row key={choiceRowKeys[i]}>
                                <Col className="mb-3">
                                    <FormTextInput
                                        handleRemove={
                                            value?.choices?.length > 1
                                                ? () => {
                                                      setFieldValue(
                                                          'poll.choices',
                                                          value.choices.filter((el, ind) => ind !== i)
                                                      );
                                                  }
                                                : undefined
                                        }
                                        invalidText={errors?.choices?.[i]?.text}
                                        placeholder="Введите вариант ответа"
                                        name={`poll.choices[${i}].text`}
                                        title={`Вариант ответа ${i + 1}`}
                                        value={item.text}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                        ))}
                        {value?.choices?.length < 50 && (
                            <Row>
                                <Col className="mt-2">
                                    <ActionIcon
                                        fontSize="0.9em"
                                        title="Добавить вариант ответа"
                                        activeColor="#2dce89"
                                        onClick={handleAddChoice}
                                        className="fas fa-plus"
                                    />
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

Poll.defaultProps = {
    id: 'article-poll',
};

export default Poll;
