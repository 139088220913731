import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const RoleList: React.FC = observer(() => {
    const storeName = 'role';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.roles;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    itemName="Роль"
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true && row.name !== 'admin' && row.name !== 'author'}
                />
            ),
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            headerStyle: {
                width: '18em',
            },
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.time)}</span>,
            dataField: 'updatedAt',
            text: 'Дата редактирования',
            sort: true,
            headerStyle: {
                width: '20em',
            },
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: { title: 'Добавить роль', path: '/admin/roles/create' },
    };

    useResetListIfGoOut(storeName, 'roles');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Список ролей" {...creatingProp} />
        </ItemsListTable>
    );
});

export default RoleList;
