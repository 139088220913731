import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import TagEditForm from 'views/tags/TagEdit/Form';
import useTagEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const TagEdit: React.FC = observer(() => {
    const { item, id } = useTagEditHooks({ storeName: 'tag' });

    useResetListIfGoOut('tag', 'tags');

    if (item.isLoading) {
        return <Loader />;
    }

    if (isUndefined(item.data)) {
        return null;
    }

    return (
        <React.Fragment>
            <AdminViewHeader title="Редактировать тэг" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Тэг ID: {id}</h3>
                            </CardHeader>
                            <CardBody>
                                <TagEditForm store={item} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default TagEdit;
