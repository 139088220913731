import React from 'react';
import { Route } from 'react-router-dom';
import { orderBy, isUndefined } from 'lodash';
import { TPermission } from 'store/role';

import { ISimpleRoute, ICollapsedRoute, TRoute, IHiddenRoute } from 'routes/types';

export const isCollapsedRoute = (route: unknown): route is ICollapsedRoute => {
    return typeof (route as ICollapsedRoute).collapse !== 'undefined';
};

export const isSimpleRoute = (route: unknown): route is ISimpleRoute => {
    return (
        isUndefined((route as ICollapsedRoute).collapse) &&
        (isUndefined((route as IHiddenRoute).isHiddenInMenu) || !(route as IHiddenRoute).isHiddenInMenu)
    );
};

export const isHiddenRoute = (route: unknown): route is IHiddenRoute => {
    return (route as IHiddenRoute).isHiddenInMenu;
};

export const getRoutes = <T extends TRoute>(routes: T[], layout: string, permissions?: TPermission): JSX.Element[] => {
    const orderedRoutes = orderBy(routes, 'isHiddenInMenu', 'asc');

    return orderedRoutes.reduce((acc: JSX.Element[], route): JSX.Element[] => {
        const r: ISimpleRoute = route as ISimpleRoute;
        if (r.layout === layout) {
            const [, itemName] = r.path.split('/');

            const pathPermission = permissions && permissions[itemName as keyof TPermission];

            const isRouteShown =
                isUndefined(permissions) ||
                isUndefined(pathPermission) ||
                (!isUndefined(pathPermission) && pathPermission.read);

            if (isRouteShown) {
                return [
                    ...acc,
                    // eslint-disable-next-line react/react-in-jsx-scope
                    <Route
                        // path={route.layout + route.path}
                        path={r.path}
                        element={<r.component />}
                        key={r.layout + r.path}
                    />,
                ];
            }

            return acc;
        }

        return acc;
    }, []);
};

export default getRoutes;
