import React, { useState, useRef } from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col, Collapse } from 'reactstrap';
import { v4 as uuid } from 'uuid';

import ReactDropzone from 'components/inputs/ReactDropzone';
import FormTextInput from 'components/inputs/FormTextInput';
import { FormikErrors } from 'formik/dist/types';
import { IQuiz, IQuizUpdate, IQuestion, IAnswer, IMeta } from 'store/quiz';
import ActionIcon from 'components/icons/ActionIcon';
import { useStore } from 'store';
import FormCheckBox from 'components/inputs/FormCheckBox';
import { createMetaByQuestion, createMetaByAnswer } from './tools';

interface IQuizProps {
    handleChange: <T>(arg: T) => void;
    errors: Partial<IQuiz> | undefined;
    setFieldValue: (
        field: string,
        value: IQuestion[] | IAnswer[] | boolean | null | string | undefined | number
    ) => Promise<FormikErrors<object>> | Promise<void>;
    value: IQuizUpdate;
    meta: IMeta;
}

//************************************************************************************************************
//подготовить пустой ответ
export const createEmptyAnswer = (): IAnswer => ({
    text: '',
    isCorrect: true,
});

//************************************************************************************************************
//подготовить пустой вопрос
export const createEmptyQuestion = (): IQuestion => {
    const newAnswer1 = createEmptyAnswer();
    newAnswer1.isCorrect = true;
    const newAnswer2 = createEmptyAnswer();
    newAnswer2.isCorrect = false;

    return {
        text: '',
        explanation: '',
        errorExplanation: '',
        answers: [newAnswer1, newAnswer2],
    };
};

//************************************************************************************************************
//Компонент Questions
export const Questions: React.FC<IQuizProps> = ({ errors, handleChange, setFieldValue, value, meta }) => {
    //индекс (в массиве) добавленного вопроса
    //const myRefIndex = useRef(-1);
    //ссылка на компонент добавленного вопроса
    const myRef = useRef<HTMLDivElement | null>(null);

    const quizStore = useStore('quiz');
    //массив keys держит ключи для рендеринга списка вопросов
    //и подмассивы ключей subKeys для рендеринга ответов
    const [keys, setKeys] = useState(() => {
        if (!value.questions) return [];

        return value.questions.map((q) => ({
            key: uuid(),
            subKeys: q.answers.map(() => uuid()),
        }));
    });

    const { questions } = value;

    //текущий вопрос по индексу последнего развернутого вопроса
    const [openedCollapses, setOpenedCollapses] = useState([quizStore.indexLastQuestion]);

    //переключатель collapse
    const collapsesToggle = (collapse: number) => {
        //свернуть все
        if (openedCollapses.includes(collapse)) {
            quizStore.indexLastQuestion = -1; //индекс последнего развернутого вопроса
            setOpenedCollapses([]);
        }
        //развернуть заданный
        else {
            quizStore.indexLastQuestion = collapse; //индекс последнего развернутого вопроса
            setOpenedCollapses([collapse]);
        }
    };

    //добавить вопрос
    /* eslint-disable no-param-reassign */
    const handleAddQuestion = () => {
        //добавить ключ для вопроса
        setKeys((prev) => [...prev, { key: uuid(), subKeys: [] }]);
        //добавить пустой вопрос + 2 ответа
        const newQuestion = createEmptyQuestion();
        const v = value.questions ? [...value.questions, newQuestion] : [newQuestion];
        const count = v.length;
        setFieldValue('questions', v);
        //добавить информацию в meta
        const newMeta = createMetaByQuestion(newQuestion);
        meta.questions = meta.questions ? [...meta.questions, newMeta] : [newMeta];
        //развернуть вопрос
        collapsesToggle(count - 1);
        //myRef - запомнить индекс добавленного вопроса для выполнения scroll на него
        //myRefIndex.current = count - 1;
    };
    /* eslint-enable no-param-reassign */

    //удалить вопрос
    /* eslint-disable no-param-reassign */
    const handleRemoveQuestion = (indexQuestion: number) => {
        if (!questions) return;
        //единственный вопрос удалять нельзя
        if (questions.length <= 1) return;
        //удалить ключ вопроса
        setKeys((prev) => prev.filter((item, index) => index !== indexQuestion));
        //удалить сам вопрос
        const v = questions ? questions.filter((item, index) => index !== indexQuestion) : [];
        setFieldValue('questions', v);
        //удалить и нформацию в meta
        meta.questions = meta.questions.filter((item, index) => index !== indexQuestion);
        //свернуть все вопросы
        collapsesToggle(-1);
    };
    /* eslint-enable no-param-reassign */

    //добавить ответ
    /* eslint-disable no-param-reassign */
    const handleAddAnswer = (indexQuestion: number) => {
        if (!value.questions || !value.questions[indexQuestion]) return;
        if (!keys[indexQuestion]) return;
        //добавить ключ ответа
        setKeys((prev) => {
            const newKeys = prev.slice();
            const newSubKeys = [...prev[indexQuestion].subKeys, uuid()];
            newKeys[indexQuestion].subKeys = newSubKeys;

            return newKeys;
        });
        //добавить пустой ответ
        const newAnswer = createEmptyAnswer();
        const v = value.questions[indexQuestion].answers
            ? [...value.questions[indexQuestion].answers, newAnswer]
            : [newAnswer];
        setFieldValue(`questions[${indexQuestion}].answers`, v);
        //добавить информацию в meta
        const newMeta = createMetaByAnswer(newAnswer);
        meta.questions[indexQuestion].answers = meta.questions[indexQuestion].answers
            ? [...meta.questions[indexQuestion].answers, newMeta]
            : [newMeta];
    };
    /* eslint-enable no-param-reassign */

    //удалить ответ
    /* eslint-disable no-param-reassign */
    const handleRemoveAnswer = (indexQuestion: number, indexAnswer: number) => {
        if (!questions || !questions[indexQuestion]) return;
        if (!keys[indexQuestion]) return;
        //единственный вопрос удалять нельзя
        if (!questions[indexQuestion].answers) return;
        if (questions[indexQuestion].answers.length <= 1) return;
        //удалить ключ ответа
        setKeys((prev) => {
            const newKeys = prev.slice();
            const v = prev[indexQuestion].subKeys.filter((item, index) => index !== indexAnswer);
            newKeys[indexQuestion].subKeys = v;

            return newKeys;
        });
        //удалить сам ответ
        const v = questions[indexQuestion].answers
            ? questions[indexQuestion].answers.filter((item, index) => index !== indexAnswer)
            : [];
        setFieldValue(`questions[${indexQuestion}].answers`, v);
        //удалить и нформацию в meta
        meta.questions[indexQuestion].answers = meta.questions[indexQuestion].answers.filter(
            (item, index) => index !== indexQuestion
        );
    };
    /* eslint-enable no-param-reassign */

    //поменять значение isCorrect (checkbox) для вопроса indexQuestion ответа indexAnswer
    const handleIsCorrect = async (indexQuestion: number, indexAnswer: number) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { answers } = questions![indexQuestion];
        const countAnswers = answers.length;
        const oldValue = answers[indexAnswer].isCorrect || false;

        //если количество ответов = 2
        type TPromise = Promise<void> | Promise<object>;
        if (countAnswers === 2) {
            const results: TPromise[] = [];
            for (let i = 0; i < countAnswers; i++) {
                results.push(
                    setFieldValue(
                        `questions[${indexQuestion}].answers[${i}].isCorrect`,
                        i === indexAnswer ? !oldValue : oldValue
                    )
                );
            }
            await Promise.all<TPromise>(results);
        }
        //если количество ответов !== 2
        else await setFieldValue(`questions[${indexQuestion}].answers[${indexAnswer}].isCorrect`, !oldValue);
    };

    //поменять значение isCover (checkbox) для вопроса indexQuestion
    /* eslint-disable no-param-reassign */
    const handleIsCoverQuestion = async (indexQuestion: number) => {
        const newValue = !meta.questions[indexQuestion].isCover;
        meta.questions[indexQuestion].isCover = newValue;
        //всегда обновляем значение => обновляем экран
        setFieldValue(`questions[${indexQuestion}].cover`, '');
    };
    /* eslint-enable no-param-reassign */

    //поменять значение isCover (checkbox) для вопроса indexQuestion ответа indexAnswer
    /* eslint-disable no-param-reassign */
    const handleIsCoverAnswer = async (indexQuestion: number, indexAnswer: number) => {
        const newValue = !meta.questions[indexQuestion].answers[indexAnswer].isCover;
        meta.questions[indexQuestion].answers[indexAnswer].isCover = newValue;
        //всегда обновляем значение => обновляем экран
        setFieldValue(`questions[${indexQuestion}].answers[${indexAnswer}].cover`, '');
    };
    /* eslint-enable no-param-reassign */

    return (
        <>
            <div className="accordion">
                {questions && (
                    <>
                        {/* Список вопросов */}
                        {questions.map((question, indexQuestion) => (
                            // карточка вопроса
                            <Card
                                key={`${keys[indexQuestion].key}`}
                                className="card-plain"
                                style={{ backgroundColor: '#FCEEE4' }}
                            >
                                {/* заголовок вопроса с символом свертки */}
                                <CardHeader
                                    role="tab"
                                    onClick={() => collapsesToggle(indexQuestion)}
                                    aria-expanded={openedCollapses.includes(indexQuestion)}
                                    style={{ backgroundColor: '#FCEEE4' }}
                                >
                                    {/* якорь, пустой элемент, для позиционирования на начале добавленного вопроса */}
                                    <div
                                        ref={
                                            indexQuestion === quizStore.indexLastQuestion
                                                ? (ref) => {
                                                      if (ref && myRef.current !== ref) {
                                                          myRef.current = ref;
                                                          //setRefresh((prev) => !prev);
                                                          if (myRef.current) {
                                                              setTimeout(() => {
                                                                  if (myRef.current) {
                                                                      myRef.current.scrollIntoView({
                                                                          behavior: 'smooth',
                                                                          block: 'start',
                                                                          inline: 'center',
                                                                      });
                                                                      //myRef.current = null;
                                                                      //myRefIndex.current = -1;
                                                                  }
                                                              }, 500);
                                                          }
                                                      }
                                                  }
                                                : undefined
                                        }
                                    />
                                    <Row>
                                        <span className="mb-0 h3">
                                            <strong>{`Вопрос ${indexQuestion + 1}`}</strong>
                                        </span>
                                    </Row>
                                    {!openedCollapses.includes(indexQuestion) && (
                                        <Row>
                                            <Col className="col-11 text-truncate">
                                                <span className="mb-0 h5">{questions[indexQuestion].text}</span>
                                            </Col>
                                        </Row>
                                    )}
                                </CardHeader>

                                <Collapse role="tabpanel" isOpen={openedCollapses.includes(indexQuestion)}>
                                    <CardBody>
                                        <Card>
                                            <CardBody>
                                                {/* кнопка удаления вопроса */}
                                                <Button
                                                    className="btn-icon btn-3 mb-3"
                                                    color="danger"
                                                    outline={true}
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleRemoveQuestion(indexQuestion)}
                                                >
                                                    <span className="btn-inner--icon">
                                                        <i className="fas fa-trash-alt" />
                                                    </span>
                                                    <span className="btn-inner--text">УДАЛИТЬ ВОПРОС</span>
                                                </Button>

                                                {/* вопрос */}
                                                <Row>
                                                    <Col className="col-10 mb-3">
                                                        <FormTextInput
                                                            type="textarea"
                                                            rows={4}
                                                            invalidText={
                                                                errors?.questions &&
                                                                errors.questions[indexQuestion].text
                                                            }
                                                            placeholder="Введите текст вопроса"
                                                            name={`questions[${indexQuestion}].text`}
                                                            // title={`Вопрос ${indexQuestion + 1}`}
                                                            value={question.text}
                                                            handleChange={handleChange}
                                                        />
                                                        {/* </div> */}
                                                    </Col>
                                                </Row>

                                                {/* переключатель картинки вопроса */}
                                                <Row>
                                                    <Col className="col-10 mb-3">
                                                        <FormCheckBox
                                                            name={`questions[${indexQuestion}].isCover`}
                                                            title="С картинкой"
                                                            value={meta.questions[indexQuestion].isCover}
                                                            handleChange={() => handleIsCoverQuestion(indexQuestion)}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* картинка вопроса */}
                                                {meta.questions[indexQuestion].isCover && (
                                                    <Row>
                                                        <Col className="col-7 mb-3">
                                                            <ReactDropzone
                                                                buttonText="Загрузите обложку здесь"
                                                                name={`questions[${indexQuestion}].cover`}
                                                                label="Картинка для вопроса"
                                                                setFieldValue={setFieldValue}
                                                                preloadedImageUrl={questions[indexQuestion].cover}
                                                                invalidText={
                                                                    errors?.questions &&
                                                                    errors.questions[indexQuestion].cover
                                                                }
                                                                clearValueOnError={false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </CardBody>
                                        </Card>

                                        <Row
                                            className="d-flex align-items-center mb-3 ml-1 mr-1 p-2 border border-2 rounded"
                                            style={{ backgroundColor: '#FAE0CE' }}
                                        >
                                            <Col className="d-flex col-1 justify-content-end">
                                                <i className="fas fa-exclamation text-success" />
                                            </Col>
                                            <Col className="col-11">
                                                <span className="h5">
                                                    Не забудьте отметить правильные ответы! Несколько правильных ответов
                                                    вы сможете выбрать, когда добавите 3 и более ответов.
                                                </span>
                                            </Col>
                                        </Row>

                                        {/* Список ответов */}
                                        {questions[indexQuestion].answers &&
                                            questions[indexQuestion].answers.map((answer, indexAnswer) => (
                                                // карточка ответа
                                                <Card key={keys[indexQuestion].subKeys[indexAnswer]}>
                                                    <CardBody>
                                                        {/* заголовок ответа */}
                                                        <Row>
                                                            <Col className="d-flex col-11 justify-content-start">
                                                                <h4 className="mb-1">{`Ответ ${indexAnswer + 1}`}</h4>
                                                            </Col>
                                                            {/* кнопка удалить */}
                                                            <Col className="d-flex col-1 justify-content-end">
                                                                <ActionIcon
                                                                    title="Удалить ответ"
                                                                    activeColor="#fb6340"
                                                                    onClick={() =>
                                                                        handleRemoveAnswer(indexQuestion, indexAnswer)
                                                                    }
                                                                    className="fas fa-trash-alt"
                                                                    style={{ marginBottom: '1em' }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        {/* текст ответа */}
                                                        <Row className="d-flex align-items-center mb-3">
                                                            {/* текст ответа */}
                                                            <Col className="col-9">
                                                                <FormTextInput
                                                                    type="textarea"
                                                                    rows={3}
                                                                    invalidText={
                                                                        errors?.questions &&
                                                                        //errors.questions[indexQuestion].answers &&
                                                                        errors.questions[indexQuestion].answers[
                                                                            indexAnswer
                                                                        ].text
                                                                    }
                                                                    placeholder="Введите текст ответа"
                                                                    name={`questions[${indexQuestion}].answers[${indexAnswer}].text`}
                                                                    // title={`Ответ ${indexAnswer + 1}`}
                                                                    value={answer.text}
                                                                    handleChange={handleChange}
                                                                />
                                                            </Col>

                                                            {/* выбор правильный/неправильный */}
                                                            <Col className="col-3">
                                                                <FormCheckBox
                                                                    name={`questions[${indexQuestion}].answers[${indexAnswer}].isCorrect`}
                                                                    title="правильный ответ"
                                                                    value={answer.isCorrect || false}
                                                                    handleChange={() =>
                                                                        handleIsCorrect(indexQuestion, indexAnswer)
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        {/* переключатель картинки ответа */}
                                                        <Row>
                                                            <Col className="col-10 mb-3">
                                                                <FormCheckBox
                                                                    name={`questions[${indexQuestion}].answers[${indexAnswer}].isCover`}
                                                                    title="С картинкой"
                                                                    value={
                                                                        meta.questions[indexQuestion].answers[
                                                                            indexAnswer
                                                                        ].isCover
                                                                    }
                                                                    handleChange={() =>
                                                                        handleIsCoverAnswer(indexQuestion, indexAnswer)
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        {/* обложка */}
                                                        {meta.questions[indexQuestion].answers[indexAnswer].isCover && (
                                                            <Row>
                                                                <Col className="mb-3 col-7">
                                                                    <ReactDropzone
                                                                        buttonText="Загрузите обложку здесь"
                                                                        name={`questions[${indexQuestion}].answers[${indexAnswer}].cover`}
                                                                        label="Картинка для вопроса"
                                                                        setFieldValue={setFieldValue}
                                                                        preloadedImageUrl={
                                                                            questions[indexQuestion].answers[
                                                                                indexAnswer
                                                                            ].cover
                                                                        }
                                                                        invalidText={
                                                                            errors?.questions &&
                                                                            errors.questions[indexQuestion].answers[
                                                                                indexAnswer
                                                                            ].cover
                                                                        }
                                                                        clearValueOnError={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            ))}

                                        {/* добавить вариант ответа */}
                                        <Button
                                            className="btn-icon btn-3"
                                            color="primary"
                                            outline={true}
                                            type="button"
                                            onClick={() => handleAddAnswer(indexQuestion)}
                                        >
                                            <span className="btn-inner--icon">
                                                <i className="fas fa-plus" />
                                            </span>
                                            <span className="btn-inner--text">ДОБАВИТЬ ВАРИАНТ ОТВЕТА</span>
                                        </Button>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </>
                )}

                {/* добавить вопрос */}
                <Button
                    className="btn-icon btn-3"
                    color="primary"
                    outline={true}
                    type="button"
                    onClick={handleAddQuestion}
                >
                    <span className="btn-inner--icon">
                        <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">ДОБАВИТЬ ВОПРОС</span>
                </Button>
            </div>
        </>
    );
};
