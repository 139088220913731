import styled from 'styled-components';

const StyledI = styled.i<{
    isLoading?: boolean | undefined;
    activeColor?: string;
}>`
    color: ${({ isLoading }) => (isLoading ? '#f7fafc' : '#8898aa')};
    cursor: pointer;
    margin-left: 1em;
    :hover {
        color: ${({ activeColor }) => activeColor || '#2dce89'};
    }
`;

export default StyledI;
