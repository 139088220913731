import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

interface IFormTextInput {
    name: string;
    value: boolean;
    handleChange: <T>(arg: T) => void;
    disabled?: boolean;
    title?: string;
}

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const FormCheckBox: React.FC<IFormTextInput> = ({ name, value, handleChange, disabled, title }) => {
    return (
        <SelectContainer>
            <label className="custom-toggle">
                <input
                    disabled={disabled}
                    id={name}
                    name={name}
                    type="checkbox"
                    // defaultChecked={value}
                    checked={value}
                    onChange={handleChange}
                />
                <span className="custom-toggle-slider rounded-circle" />
            </label>
            {!isUndefined(title) && (
                <label className="form-control-label ml-2" htmlFor={name}>
                    {title}
                </label>
            )}
        </SelectContainer>
    );
};

export default FormCheckBox;
