import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';

import AdminViewHeader from 'components/headers/AdminViewHeader';

interface IItemCreate {
    title: string;
    headerTitle: string;
}

const ItemCreate: React.FC<IItemCreate> = ({ title, headerTitle, children }) => {
    return (
        <React.Fragment>
            <AdminViewHeader title={title} />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{headerTitle}</h3>
                            </CardHeader>
                            <CardBody>{children}</CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ItemCreate;
