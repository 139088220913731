import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';

import { eventApi } from 'api';
import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import { isoToFulltime, isoToLocal } from 'lib/datetime';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';
import Header, { TAction } from 'components/headers/ItemListHeaderEx';

const EventList: React.FC = observer(() => {
    const navigate = useNavigate();
    const storeName = 'event';

    const {
        currentUser: { permissions },
    } = useStore('admin');
    const userAccess = permissions && permissions.event;
    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'isPublished',
            text: 'Опубликовано',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (isPublished) => (isPublished ? 'Да' : 'Нет'),
        },
        {
            dataField: 'title',
            text: 'Заголовок',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '40em',
                maxWidth: '40em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (date, row) => (
                <span key={row.id}>
                    {formatDate(
                        /* eslint-disable @typescript-eslint/no-non-null-assertion */
                        row.fullTime ? isoToFulltime(date)! : isoToLocal(date)!,
                        config.formats.onlyDate
                    )}
                </span>
            ),
            dataField: 'dateBegin',
            text: 'Дата начала',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            formatter: (date, row) => (
                <span key={row.id}>
                    {formatDate(
                        /* eslint-disable @typescript-eslint/no-non-null-assertion */
                        row.fullTime ? isoToFulltime(date)! : isoToLocal(date)!,
                        config.formats.onlyDate
                    )}
                </span>
            ),
            dataField: 'dateEnd',
            text: 'Дата окончания',
            sort: true,
            style: {
                width: '12em',
            },
        },
        {
            dataField: 'fullTime',
            text: 'Весь день',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (fullTime) => (fullTime ? 'Да' : 'Нет'),
        },
        {
            dataField: 'repeat',
            text: 'Повторение',
            sort: true,
            headerStyle: {
                width: '4em',
            },
            formatter: (repeat) => (repeat ? 'Да' : 'Нет'),
        },
        {
            formatter: (date, row) => <span key={row.id}>{formatDate(date, config.formats.onlyDate)}</span>,
            dataField: 'createdAt',
            text: 'Дата создания',
            sort: true,
            style: {
                width: '12em',
            },
        },
    ];

    //*************************************************************************************************************
    //Добавить действия
    const buttons: TAction[] = [];
    if (userAccess?.create === true) {
        buttons.push({
            name: 'Добавить',
            handle: () => navigate(`/admin/event/create`),
        });
    }
    if (userAccess?.read === true) {
        buttons.push({
            name: 'Календарь в html',
            handle: () => navigate(`/admin/event/html`),
        });
    }
    useResetListIfGoOut(storeName, 'event');

    return (
        <ItemsListTable
            filters={[
                {
                    type: 'multiselect',
                    name: 'themes',
                    filterTitle: 'Список тем',
                    dropdownItemTitle: 'Список тем',
                    getSelect: eventApi.getSelect,
                    shouldSort: false,
                },
                {
                    type: 'text',
                    name: 'textFilter',
                    filterTitle: 'Поиск по тексту',
                    dropdownItemTitle: 'Поиск по тексту',
                },
            ]}
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'createdAt',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Календарь" buttons={buttons} />
        </ItemsListTable>
    );
});

export default EventList;
