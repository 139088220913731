import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';
import { isUndefined, get } from 'lodash';

import { stat, IEvent } from 'lib/stat';

export interface IEventDescriptor {
    name: string; //имя события
    props?: Record<string, string>; //описание полей события в формате: имя поля в событии -> имя поля в описании объекта с сервера
}

//*************************************************************************************************************
//сформировать событие на основе описания IEventDescriptor
export function addEvent(d: object, eventDescriptor?: IEventDescriptor): void {
    if (eventDescriptor) {
        //имя события
        const event: IEvent = { name: eventDescriptor.name };
        //цикл по всем полям описания события:
        //для каждого свойства в описании события берем значение имени поля в БД
        //если есть такое значение в описании объекта с сервера, то добавить новое поле в событие
        if (eventDescriptor.props) {
            event.value = {};
            const keys = Object.keys(eventDescriptor.props);
            for (let i = 0; i < keys.length; i++) {
                //имя свойства в событии
                const key = keys[i];
                //имя поля в БД
                const filedName = eventDescriptor.props[key];

                const v = get(d, filedName);
                if (!isUndefined(v)) event.value[key] = v;
            }
        }
        stat.addEvent(event);
    }
}

//*************************************************************************************************************
//по результату ответа сервера:
// - вывести уведомление о выполнении действия action
// - сформировать событие на основе описания IEventDescriptor
//
// action - действие
// id - идентификатор объекта
// d - ответ сервера
// eventDescriptor - описание формируемого события
type TAction = 'create' | 'update' | 'delete';
export function sysNotify(action: TAction, id: number, d: object, eventDescriptor?: IEventDescriptor): void {
    let msg = '';
    if (action === 'create') msg = 'добавлена';
    else if (action === 'update') msg = 'обновлена';
    else msg = 'удалена';

    notifyStore.showNotify({
        place: 'br',
        message: `Запись #${id} ${msg}`,
        type: 'success',
    } as AlertOptions);
    //сформировать событие по результату ответа сервера
    addEvent(d, eventDescriptor);
}

//*************************************************************************************************************
// вывести уведомление о выполнении изменения данных
export function sysNotifyCommon(): void {
    notifyStore.showNotify({
        place: 'br',
        message: 'изменения выполнены',
        type: 'success',
    } as AlertOptions);
}
