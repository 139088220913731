import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import SpinnerButton from './SpinnerButton';

interface ISaveButtons {
    isValid?: boolean;
    isDirty: boolean;
    onSave?: () => void;
    pathToList: string;
    backTitle?: string;
    disabled?: boolean;
    isLoading?: boolean;
    buttonType?: 'button' | 'submit';
}

const SaveButtons: React.FC<ISaveButtons> = ({
    isValid = true,
    isDirty,
    onSave,
    pathToList,
    backTitle = 'К списку',
    disabled = false,
    isLoading = false,
    buttonType = 'button',
}) => {
    const navigate = useNavigate();

    return (
        <Row>
            <Col className="col-6 d-flex justify-content-start">
                <Button
                    className="my-0"
                    onClick={() => navigate(pathToList, { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    {backTitle}
                </Button>
            </Col>

            <Col className="col-6 d-flex flex-column align-items-end">
                <SpinnerButton
                    onClick={onSave}
                    disabled={disabled || !isValid || !isDirty || isLoading}
                    isLoading={isLoading}
                    buttonType={buttonType}
                    title="Сохранить"
                />
            </Col>
        </Row>
    );
};

export default SaveButtons;
