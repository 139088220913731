import { useQuery, useMutation, useQueryClient } from 'react-query';

import { tgChannelApi as itemApi } from 'api';

export interface IItemContent {
    title: string;
    chatId: string;
    isTest: boolean;
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

//ключ кеша
const queryKey = 'tgChannelContent';

//*************************************************************************************************************
//чтение
async function itemRead(id: number): Promise<IItem> {
    const response = await itemApi.getOne(id);
    return response.data;
}
export function useRqItemRead(id: number) {
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => itemRead(id),
        staleTime: 0,
    });
}

//*************************************************************************************************************
//исправить
type TUpdateProps = { id: number; content: Partial<IItemContent> };
async function itemUpdate({ id, content }: TUpdateProps): Promise<IItem> {
    const response = await itemApi.updateOne(id, content);
    return response.data;
}
export const useRqItemUpdate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TUpdateProps) => itemUpdate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};

//*************************************************************************************************************
//добавить
type TCreateProps = { content: Partial<IItemContent> };
async function itemCreate({ content }: TCreateProps): Promise<IItem> {
    const response = await itemApi.createOne(content);
    return response.data;
}
export const useRqItemCreate = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (props: TCreateProps) => itemCreate(props),
        onSuccess: (data) => {
            //обновить:
            //data.data - возвращает axios
            //variables - объект, который получила функция mutate при вызове
            queryClient.setQueryData([queryKey], data);
        },
    });

    return mutation;
};
