import React from 'react';
import { useFormik } from 'formik';
import { Card, CardHeader, Col, Form, Row, CardBody } from 'reactstrap';

import FormTextInput from 'components/inputs/FormTextInput';
import FormBackOrSubmitButtons from 'components/buttons/FormBackOrSubmitButtons';
import SectionPermissions from 'views/role/RoleEdit/SectionPermissions';
import roleStore, { TPermission } from 'store/role';
import getEditedValues from 'lib/getEditedValues';

interface IRoleEditForm {
    store: typeof roleStore.item;
}

const RoleEditForm: React.FC<IRoleEditForm> = ({ store }) => {
    const initialValues = store.data ?? {
        title: '',
        name: '',
        permissions: {} as TPermission,
    };

    const { dirty, values, handleChange, handleSubmit } = useFormik({
        initialValues,
        onSubmit: (data) => {
            const editedFields = getEditedValues(initialValues, data);
            if (dirty) {
                store.updateOne(editedFields);
            }
        },
    });

    const defaultAccessObject = {
        read: 'Чтение',
        update: 'Изменение',
        create: 'Создание',
        delete: 'Удаление',
    };

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        disabled={initialValues.name === 'admin'}
                        title="Название роли"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Card className="shadow-none">
                <CardHeader>
                    <Row>
                        <Col />
                        {Object.values(defaultAccessObject).map((value) => (
                            <Col key={value}>{value}</Col>
                        ))}
                    </Row>
                </CardHeader>
                <CardBody>
                    <SectionPermissions
                        disabled={initialValues.name === 'admin'}
                        handleChange={handleChange}
                        permissions={values.permissions}
                    />
                </CardBody>
            </Card>

            <FormBackOrSubmitButtons
                backTitle="К списку"
                backPath="/admin/roles"
                disabled={!dirty || initialValues.name === 'admin'}
            />
        </Form>
    );
};

export default RoleEditForm;
