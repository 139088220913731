import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { FormikErrors } from 'formik/dist/types';

interface IArticleFormButtons {
    isValid?: boolean;
    isDirty?: boolean;
    isPublished?: boolean;
    onSubmit?: () => void;
    setFieldValue: (
        field: string,
        value: boolean,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<object>> | Promise<void>;
    hidePublishButton?: boolean;
    pathToList?: string;
}

const ArticleFormButtons: React.FC<IArticleFormButtons> = ({
    isValid,
    isDirty,
    isPublished,
    onSubmit,
    setFieldValue,
    hidePublishButton = false,
    pathToList = '/admin/articles',
}) => {
    const navigate = useNavigate();

    const handleSubmit = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onSubmit) {
            onSubmit();
        }
    };

    const handleUnPublish = async (e: React.MouseEvent) => {
        e.preventDefault();
        await setFieldValue('isPublished', false);
        if (onSubmit) onSubmit();
    };

    const handlePublish = async (e: React.MouseEvent) => {
        e.preventDefault();
        await setFieldValue('isPublished', true);
        if (onSubmit) onSubmit();
    };

    return (
        <Row>
            <Col className="d-flex justify-content-start">
                <Button
                    onClick={() => navigate(pathToList, { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    К списку
                </Button>
            </Col>
            <Col className="d-flex justify-content-end">
                {!hidePublishButton && isPublished && (
                    <Button onClick={handleUnPublish} size="sm" color="warning" type="submit">
                        Снять с публикации
                    </Button>
                )}
                <Button onClick={handleSubmit} disabled={!isValid || !isDirty} size="sm" color="primary" type="submit">
                    {isPublished ? 'Сохранить изменения' : 'Сохранить как черновик'}
                </Button>
                {!hidePublishButton && !isPublished && (
                    <Button disabled={!isValid} onClick={handlePublish} size="sm" color="success" type="submit">
                        Опубликовать
                    </Button>
                )}
            </Col>
        </Row>
    );
};

export default ArticleFormButtons;
