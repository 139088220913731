import { makeAutoObservable } from 'mobx';

import { channelApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IChannelInList {
    id: number;
    title: string;
}

class ChannelStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<{ id: number }, {}, {}>(channelApi);

    readonly list = new ListStore<IChannelInList>(channelApi);
}

export default new ChannelStore();
