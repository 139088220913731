import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as appConst from 'app.const';
import { ReactComponent as ReactSvg } from 'assets/svg/arrows/arrow-circle-left-solid.svg';
import { TScreen } from './login.type';
import LoginBackground from './LoginBackground';
import LoginFactor2 from './LoginFactor2';

const LoginFactor2Container: React.FC = () => {
    const navigate = useNavigate();
    //текущий вид экрана
    const [screen, setScreen] = useState(TScreen.SEND_CODE);

    return (
        <LoginBackground>
            <ReactSvg
                height={24}
                fill={appConst.backgroundPrimaryColor}
                onClick={() => {
                    navigate(-1);
                }}
                cursor="pointer"
            />
            <div className="h2 text-left mt-2 mb-1">Ввод одноразового кода</div>

            <LoginFactor2 key={`screen-${screen}`} screen={screen} setScreen={setScreen} />
        </LoginBackground>
    );
};

export default LoginFactor2Container;
