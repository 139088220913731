import { makeAutoObservable } from 'mobx';

import { quizApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IQuizInList {
    id: number;
    title: string;
    isCompetition: boolean;
    isPublished: boolean;
    periodStart: Date;
    periodStop: Date;
    userCommonName?: string;
}

export interface IMeta {
    isFilled: boolean;
    isCover: boolean;
    questions: Array<{
        isCover: boolean;
        answers: Array<{
            isCover: boolean;
        }>;
    }>;
    ratings: Array<{
        isCover: boolean;
    }>;
}

export interface IQuiz {
    id: number;
    title: string;
    text: string;
    cover: string;
    isCompetition: boolean;
    isPublished: boolean;
    periodStart: Date;
    periodStop: Date;
    questions: IQuestion[];
    ratings: IRating[];
}

export interface IQuizUpdate {
    id?: number;
    title?: string;
    text?: string;
    cover?: string;
    isCompetition?: boolean;
    isPublished?: boolean;
    periodStart?: Date;
    periodStop?: Date;
    questions?: IQuestion[];
    ratings?: IRating[];
}

export type IQuestion = {
    id?: number;
    text?: string;
    cover?: string;
    explanation?: string;
    errorExplanation?: string;
    numOrder?: number;
    answers: IAnswer[];
};

export type IAnswer = {
    id?: number;
    text?: string;
    cover?: string;
    numOrder?: number;
    isCorrect?: boolean;
};

export type IRating = {
    id?: number;
    text?: string;
    cover?: string;
    maxCount: number;
};

class QuizStore {
    constructor() {
        makeAutoObservable(this, { indexLastQuestion: false, indexLastTab: false });
        runItemsReactions(this);
    }

    //изменения этих свойств не отслеживаются в mobx, как указано в конструкторе
    //индекс последнего развернутого вопроса
    indexLastQuestion = -1;

    //индекс последней открытой закладки
    indexLastTab = -1;

    item = new ItemStore<IQuiz, IQuizUpdate, IQuizUpdate>(quizApi);

    readonly list = new ListStore<IQuizInList>(quizApi);
}

export default new QuizStore();
