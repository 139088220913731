import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as appConst from 'app.const';
import { ReactComponent as ReactSvg } from 'assets/svg/arrows/arrow-circle-left-solid.svg';
import LoginBackground from './LoginBackground';
import LoginButton from './LoginButton';

const PasswordExpired: React.FC = () => {
    const navigate = useNavigate();

    return (
        <LoginBackground>
            <ReactSvg
                height={24}
                fill={appConst.backgroundPrimaryColor}
                onClick={() => {
                    navigate(-1);
                }}
                cursor="pointer"
            />
            <div className="h2 text-left mt-2 mb-1">Пароль просрочен</div>

            <div className="text-muted mb-3 pr-5">
                <small className="text-dark">
                    Инструкция по восстановлению доступа в течение 5 минут придет вам на почту
                </small>
            </div>

            <LoginButton
                actionName="OK"
                type="submit"
                onClick={() => {
                    navigate('/auth/login', { replace: true });
                }}
            />
        </LoginBackground>
    );
};

export default PasswordExpired;
