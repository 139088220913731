import { makeAutoObservable } from 'mobx';

import { eventApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

interface IEventInList {
    id: number;
    title: string;
}

class EventStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<{ id: number }, {}, {}>(eventApi);

    readonly list = new ListStore<IEventInList>(eventApi);
}

export default new EventStore();
