import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import AdminViewHeader from 'components/headers/AdminViewHeader';
import Loader from 'components/Loader';
import AdviceEditForm from 'views/advices/AdviceEdit/Form';
import useEditHooks from 'hooks/itemEdit';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const AdviceEdit: React.FC = observer(() => {
    const { item, id } = useEditHooks({ storeName: 'advice' }); //чтение данных. id извлекается из path. Вызывается api.getOne(id)
    useResetListIfGoOut('advice', 'advices');

    if (item.isLoading) {
        return <Loader />;
    }

    if (isUndefined(item.data)) {
        return null;
    }

    return (
        <React.Fragment>
            <AdminViewHeader title="Обработать новостное предложение" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">
                                    Предложение ID: {id}
                                    {item.data.userCommonName ? ` (от ${item.data.userCommonName})` : ''}
                                </h3>
                            </CardHeader>
                            <CardBody>
                                <AdviceEditForm store={item} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
});

export default AdviceEdit;
