import { TEditorBlockData } from 'components/edit/HTMLEditor/types';

//тип планировщика
export enum EventRepeat {
    EMPTY = 0,
    YEAR = 1,
}

export interface IItemContent {
    title: string;
    isPublished: boolean;
    repeat: boolean;
    dateBegin: Date;
    dateEnd: Date;
    fullTime: boolean;
    coverId: number;
    cover: string;
    themes: number[]; //массив id тем, для контрола выбора тем
    themesList: Array<{ id: number; title: string }>; //полное описание тем, используется для статистики
    place: string;
    editorData: TEditorBlockData[];
    subtitle: string;
    ageLimit: string;
    isOnline: boolean;
}

export interface IItem extends Partial<IItemContent> {
    id: number;
}

export interface IEditorData {
    pattern: string;
    style: string;
    fonts: string;
    content: string;
}

export interface IParamsContent {
    cover: string;
    coverId: number;
    month: string;
    year: string;
    themes: number[];
    showImages: boolean;
}
