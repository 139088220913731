import { makeAutoObservable } from 'mobx';
import { reportApi } from 'api';

import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export interface IReportInList {
    id: number;
    title: string;
}

export interface IReportItem {
    id: number;
    title: string;
}

class ReportStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    readonly item = new ItemStore<IReportItem, IReportItem, IReportItem>(reportApi);

    readonly list = new ListStore<IReportInList>(reportApi);
}

export default new ReportStore();
