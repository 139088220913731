import React from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';

import { useStore } from 'store';
import AdminViewHeader from 'components/headers/AdminViewHeader';
import JournalEditForm from 'views/journals/Components/Form';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const JournalCreate: React.FC = () => {
    const { item } = useStore('journal');
    useResetListIfGoOut('journal', 'journals');

    return (
        <React.Fragment>
            <AdminViewHeader title="Создать журнал" />
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="pb-0">
                                <h3 className="mb-0">Новый журнал</h3>
                            </CardHeader>
                            <CardBody>
                                <JournalEditForm item={item} mode="create" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default JournalCreate;
