import React from 'react';
import classnames from 'classnames';
import {
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
} from 'reactstrap';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';

import { authApi } from 'api';
import { useStore } from 'store';

interface INavbarProps {
    toggleSidenav: () => void;
    sidenavOpen: boolean;
    theme: 'dark' | 'light';
}

const AdminNavbar: React.FC<INavbarProps> = observer(({ toggleSidenav, sidenavOpen, theme }) => {
    const adminStore = useStore('admin');
    const authStore = useStore('auth');
    const navigate = useNavigate();

    //hide-fio
    const { /*username, lastname, firstname,*/ commonName } = adminStore.currentUser;

    //hide-fio
    //const navbarUsername = (firstname && lastname && `${firstname} ${lastname}`) || username || 'Админ';
    const navbarUsername = commonName || 'Админ';

    const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        try {
            await authApi.logout();
            // eslint-disable-next-line no-empty
        } catch (error) {}

        authStore.logout();
    };

    return (
        <React.Fragment>
            <Navbar
                className={classnames(
                    'navbar-top navbar-expand border-bottom',
                    { 'navbar-dark bg-info': theme === 'dark' },
                    { 'navbar-light bg-secondary': theme === 'light' }
                )}
            >
                <Container fluid={true}>
                    <Collapse navbar={true} isOpen={true}>
                        <Breadcrumbs />
                        <Nav className="align-items-center ml-md-auto" navbar={true}>
                            <NavItem className="d-xl-none">
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <div
                                    className={classnames(
                                        'pr-3 sidenav-toggler',
                                        { active: sidenavOpen },
                                        {
                                            'sidenav-toggler-dark': theme === 'dark',
                                        }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </div>
                            </NavItem>
                        </Nav>
                        <Nav className="align-items-center ml-auto ml-md-0" navbar={true}>
                            <UncontrolledDropdown nav={true}>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm bg-primary">
                                            <i
                                                style={{
                                                    fontSize: 'large',
                                                }}
                                                className="ni ni-single-02"
                                            />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">{navbarUsername}</span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right={true}>
                                    <DropdownItem
                                        onClick={() => {
                                            navigate('/admin/profile');
                                        }}
                                    >
                                        <i className="fas fa-user-cog text-success" />
                                        <span>Профиль</span>
                                    </DropdownItem>
                                    <DropdownItem divider={true} />
                                    <DropdownItem href="#pablo" onClick={(e) => handleLogout(e)}>
                                        <i className="ni ni-user-run" />
                                        <span>Выйти</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
});

AdminNavbar.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: 'dark',
};

export default AdminNavbar;
