import React from 'react';
import { observer } from 'mobx-react-lite';

import { IEventDescriptor } from 'lib/system';
import { ListItemsStores } from 'lib/types';
import TableRowActionIcon from 'components/icons/TableRowActionIcon';
import { useStore } from 'store';
import DeleteButton from 'components/buttons/DeleteButton';
import { useNavigate, useLocation } from 'react-router-dom';

interface IItemActionButtons {
    itemName?: string;
    path?: string;
    id: number;
    isEdit?: boolean;
    isDelete?: boolean;
    storeName: ListItemsStores;
    deleteEventDescriptor?: IEventDescriptor; //данные для заполнения события удаления
}

const ItemActionButtons: React.FC<IItemActionButtons> = observer(
    ({ itemName, id, isEdit, isDelete, children, storeName, deleteEventDescriptor }) => {
        const { item } = useStore(storeName);
        const location = useLocation();
        const navigate = useNavigate();

        return (
            <div style={{ textAlign: 'left', lineHeight: 'normal' }}>
                {isEdit && (
                    <TableRowActionIcon
                        activeColor="#11cdef"
                        onClick={() => navigate(`${location.pathname}/${id}/edit`, { state: { id } })}
                        className="far fa-edit"
                    />
                )}
                {isDelete && (
                    <DeleteButton
                        itemName={itemName}
                        id={id}
                        isLoading={item.isLoading}
                        callback={() => {
                            item.deleteOne(id, deleteEventDescriptor);
                        }}
                    />
                )}
                {children}
            </div>
        );
    }
);

export default ItemActionButtons;
