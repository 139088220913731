import { reaction } from 'mobx';
import { isUndefined } from 'lodash';
import { stores } from 'store';
import { ListItemsStores } from 'lib/types';

const runItemsReactions = <T extends ListItemsStores>(store: typeof stores[T]) => {
    reaction(
        () => store.item.isDeleted,
        (isDeleted) => {
            if (!isUndefined(isDeleted)) {
                const prevQuery = store.list.queryParams;
                const prevPage = prevQuery.page - 1 > 0 ? prevQuery.page - 1 : 1;
                const page = store.list.itemsList?.length === 1 ? prevPage : store.list.queryParams.page;
                const query = { ...prevQuery, page };
                store.list.pullList(query);
                store.item.resetState();
            }
        }
    );
};

export default runItemsReactions;
