import React from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getDirtyValues } from 'lib/getEditedValues';
import { sysNotifyCommon } from 'lib/system';
import { useIsMounted } from 'lib/hooks';
import SaveButtons from 'components/buttons/SaveButtons';
import { IItem, useRqItemUpdate } from './queries';
import { ConditionField } from './ConditionField';

const validationSchema = yup.object({
    content: yup.array().of(
        yup.object({
            active: yup.boolean(),
            email: yup.string().when('active', {
                is: true,
                then: (schema) => schema.max(256, `максимум 256 символов`).required('отсутствует адрес email'),
            }),
        })
    ),
});

interface IProps {
    item: IItem;
}
const ItemForm: React.FC<IProps> = ({ item }) => {
    //корректировка
    //isLoading из useMutation срабатывает, когда данные перечитываются при редактировании
    const rqItemUpdate = useRqItemUpdate();
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();
    //данные
    const defaultValues = item;

    const {
        handleSubmit,
        formState: { errors, isDirty, dirtyFields },
        reset,
        control,
        register,
    } = useForm<IItem>({ defaultValues, resolver: yupResolver(validationSchema) });
    const { fields } = useFieldArray({
        name: 'content',
        control,
    });

    const onSubmit = (values: IItem) => {
        rqItemUpdate.mutate(getDirtyValues(dirtyFields, values) as IItem, {
            onSuccess: (data /*, variables, context */) => {
                if (isMounted.current) {
                    reset(data);
                    sysNotifyCommon();
                }
            },
        });
    };

    const isLoading = rqItemUpdate.isLoading;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col className="d-flex justify-content-end">
                    <span className="h5 form-control-label">
                        В каждом поле можно указывать несколько адресов через ;
                    </span>
                </Col>
            </Row>

            {fields.map((field, index) => {
                return (
                    <ConditionField
                        control={control}
                        register={register}
                        errors={errors}
                        index={index}
                        key={field.id}
                        title={field.title || ''}
                        disabled={isLoading}
                    />
                );
            })}

            <div className="mt-4" />
            <SaveButtons
                buttonType="submit"
                isDirty={isDirty}
                backTitle="к настройкам"
                pathToList="/admin/settings"
                disabled={isLoading}
                isLoading={isLoading}
            />
        </Form>
    );
};

export default ItemForm;
