export const substrByTags = (text: string, startTag: string, finishTag: string) => {
    const i = text.indexOf(startTag);
    if (i >= 0) {
        const j = text.indexOf(finishTag, i + startTag.length);
        if (j >= 0) return text.slice(i + startTag.length, j);
    }

    return '';
};

export const substrByStartTag = (text: string, startTag: string) => {
    const i = text.indexOf(startTag);
    if (i >= 0) return text.slice(i + startTag.length);

    return '';
};

//*************************************************************************************************************
//Поиск значения key в строке параметров search типа '?source=quiz&action=read'
export const getSearchValue = (source: string, key: string) => {
    if (source.length < 1) return '';
    let s = source;
    if (s.slice(0, 1) === '?') s = s.slice(1);
    const a = s.split('&');
    for (let i = 0; i < a.length; i++) {
        const p = a[i].split('=');
        if (p.length === 2 && p[0].trim() === key) return p[1].trim();
    }
    return '';
};

//*************************************************************************************************************
export const sleep = (count: number) => new Promise((resolve) => setTimeout(resolve, count));
