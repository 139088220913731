import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { isUndefined } from 'lodash';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import Loader from 'components/Loader';
import { ListItemsStores } from 'lib/types';
import getPagination from 'components/tables/ItemsListTable/getPaginationOptions';
import Notification from 'components/notifications/Notification';
import FiltersToolkit, { IFiltersToolKit } from 'components/filters/FiltersToolkit';
import { ITableChangeState } from 'store/common/List';

interface IItemsListTableProps {
    defaultTableParams: ITableChangeState;
    storeName: ListItemsStores;
    columns: ColumnDescription[];
    filters?: IFiltersToolKit['filters'];
}

const ItemsListTable: React.FC<IItemsListTableProps> = observer(
    ({ storeName, columns, defaultTableParams, children, filters }) => {
        const { list: listStore } = useStore(storeName);

        useEffect(() => {
            if (isUndefined(listStore.itemsList)) {
                listStore.pullList(defaultTableParams);
            } else {
                listStore.pullList();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultTableParams]);

        const pagination = getPagination(listStore);

        if (isUndefined(listStore.itemsList)) {
            return null;
        }
        let defaultSorted: [{ dataField: string; order: SortOrder }] | undefined;

        if (
            !isUndefined(defaultTableParams) &&
            !isUndefined(defaultTableParams?.sortField) &&
            !isUndefined(defaultTableParams?.sortOrder)
        ) {
            defaultSorted = [
                {
                    dataField: defaultTableParams.sortField,
                    order: defaultTableParams.sortOrder,
                },
            ];
        }

        if (!isUndefined(listStore.queryParams.sortField) && !isUndefined(listStore.queryParams.sortOrder)) {
            defaultSorted = [
                {
                    dataField: listStore.queryParams.sortField,
                    order: listStore.queryParams.sortOrder,
                },
            ];
        }

        const emptyStoreView = listStore.isListLoading ? <Loader /> : null;

        return isUndefined(listStore.itemsList) ? (
            emptyStoreView
        ) : (
            <React.Fragment>
                {children}
                <Container className="mt--6" fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <div className="py-4 table-responsive">
                                    {!isUndefined(filters) && (
                                        <FiltersToolkit filters={filters} listStore={listStore} />
                                    )}
                                    <BootstrapTable
                                        data={listStore.itemsList}
                                        columns={columns}
                                        keyField="id"
                                        bootstrap4={true}
                                        pagination={pagination}
                                        bordered={false}
                                        id="react-bs-table"
                                        remote={true}
                                        onTableChange={(event, { page, sizePerPage, sortField, sortOrder }) =>
                                            listStore.pullList({
                                                page,
                                                sizePerPage,
                                                sortField,
                                                sortOrder,
                                                ...(!isUndefined(listStore.queryParams.filters) && {
                                                    filters: listStore.queryParams.filters,
                                                }),
                                            })
                                        }
                                        defaultSorted={defaultSorted}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Notification />
            </React.Fragment>
        );
    }
);

export default ItemsListTable;
