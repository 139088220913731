import React, { useEffect } from 'react';
import { useLocation, Navigate, Routes, Route, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import Notification from 'components/notifications/Notification';

import Login from 'views/auth/Login';
import StartResetPassword from 'views/auth/StartResetPassword';
import ResetPassword from 'views/auth/ResetPassword';
import PasswordExpired from 'views/auth/PasswordExpired';
import LoginFactor2Container from 'views/auth/LoginFactor2Container';

const AuthLayout: React.FC = observer(() => {
    const location = useLocation();
    const mainContentRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
        document.body.classList.add('bg-default');

        return function cleanup() {
            document.body.classList.remove('bg-default');
        };
    });
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
    }, [location]);
    const authStore = useStore('auth');

    const navigate = useNavigate();
    useEffect(() => {
        if (authStore.isLoggedIn) {
            navigate('/admin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isLoggedIn]);

    if (authStore.isLoggedIn) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="main-content" ref={mainContentRef}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/start-reset-password" element={<StartResetPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/password-expired" element={<PasswordExpired />} />
                    <Route path="/login-by-factor2-code" element={<LoginFactor2Container />} />
                    <Route path="*" element={<Navigate to="/auth/login" replace={true} />} />
                </Routes>
            </div>
            <Notification />
        </React.Fragment>
    );
});

export default AuthLayout;
