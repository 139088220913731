import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import { config } from 'lib/config';
import formatDate from 'lib/formatDate';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const List: React.FC = observer(() => {
    const storeName = 'tag';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.tags;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    itemName="Тэг"
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                    //данные для заполнения события удаления
                    deleteEventDescriptor={{
                        name: 'tag_delete',
                        props: { tag_id: 'id', tag_name: 'title' },
                    }}
                />
            ),
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                width: '7em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            style: {
                textOverflow: 'ellipsis',
                maxWidth: '30em',
                minWidth: '30em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        {
            formatter: (date) => formatDate(date, config.formats.time),
            dataField: 'create',
            text: 'Дата создания',
            sort: true,
            style: {
                width: '12em',
            },
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: { title: 'Добавить тэг', path: '/admin/tags/create' },
    };

    useResetListIfGoOut(storeName, 'tags');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'create',
                sortOrder: 'desc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Список тэгов" {...creatingProp} />
        </ItemsListTable>
    );
});

export default List;
