import React, { useRef, useEffect, useState } from 'react';
import { ISimpleRoute, TRouteArray, TRoute, isSimpleRoute, ICollapsedRoute } from 'routes';
import { useLocation, NavLink as NavLinkRRD } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, Navbar, NavItem, NavLink, Nav } from 'reactstrap';

import { stat } from 'lib/stat';
import { useStore } from 'store';
import { TPermission } from 'store/role';
import { isUndefined } from 'lodash';
import { IReminders, serviceApi } from 'api';

interface ISidebarProps {
    // function used to make sidenav mini or normal
    toggleSidenav: () => void;
    // prop to know if the sidenav is mini or normal
    sidenavOpen: boolean;
    // links that will be displayed inside the component
    routes: TRouteArray;
}

//const Sidebar: React.FC<ISidebarProps> = ({
const Sidebar = ({ toggleSidenav, sidenavOpen, routes }: ISidebarProps) => {
    //хранилище admin
    const {
        currentUser: { permissions },
    } = useStore('admin');
    //хранилище auth
    const authStore = useStore('auth');
    //хранилище оповещений
    const remindStorage = useRef<IReminders>({}).current;
    //refresh оповещений
    const [, setRefresh] = useState(false);
    //таймер оповещений
    const remindTimer = useRef<NodeJS.Timer | null>(null);
    //при старте запустить remindTimer, при выходе удалить
    useEffect(() => {
        //определить функцию обновления напоминаний
        const checkReminders = async () => {
            if (authStore.isLoggedIn) {
                //читаем напоминания
                let result;
                try {
                    result = await serviceApi.getReminders();
                } catch (error) {
                    result = null;
                }
                if (result) {
                    const { data } = result;
                    let needRefresh = false;
                    Object.keys(data).forEach((key) => {
                        if (remindStorage[key] === undefined || remindStorage[key] !== data[key]) {
                            remindStorage[key] = data[key];
                            needRefresh = true;
                        }
                    });
                    if (needRefresh) setRefresh((prev) => !prev);
                }
            }
        };

        remindTimer.current = setInterval(checkReminders, 60000);
        checkReminders();

        return () => {
            if (remindTimer.current) clearInterval(remindTimer.current);
        };
    }, [remindStorage, authStore.isLoggedIn]);

    const location = useLocation();

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string) => {
        return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    };
    // makes the sidenav normal on hover (actually when mouse enters on it)
    const onMouseEnterSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.add('g-sidenav-show');
        }
    };
    // makes the sidenav mini on hover (actually when mouse leaves from it)
    const onMouseLeaveSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-show');
        }
    };

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    const closeSidenav = () => {
        if (window.innerWidth < 1200 && sidenavOpen) {
            toggleSidenav();
        }
    };

    React.useEffect(() => {
        closeSidenav();
        // eslint-disable-next-line
    }, []);

    const getRouteStyledTitle = (route: ISimpleRoute | ICollapsedRoute): JSX.Element | undefined => {
        let routeTitle;

        let color = 'text-primary';
        if ((route as ISimpleRoute).path) {
            const path = window.location.pathname.split('/').pop();
            if (path && (route as ISimpleRoute).path === `/${path}`) color = 'text-success';
        }

        if (route.icon) {
            routeTitle = (
                <React.Fragment>
                    <i className={`${route.icon} ${color}`} />
                    <span className="nav-link-text ml-1">{route.name}</span>
                </React.Fragment>
            );
        } else if (route.miniName) {
            routeTitle = (
                <React.Fragment>
                    <span className="sidenav-mini-icon"> {route.miniName} </span>
                    <span className="sidenav-normal"> {route.name} </span>
                </React.Fragment>
            );
        }

        return routeTitle;
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routesForLinks: TRoute[]) => {
        return routesForLinks.map((route): JSX.Element | null => {
            if (isSimpleRoute(route)) {
                const [, itemName] = route.path.split('/');

                //секция mobilers косвенная, имеет те же права, что и секция users
                const pathPermission =
                    permissions && permissions[(itemName === 'mobilers' ? 'users' : itemName) as keyof TPermission];

                const isMenuShown =
                    isUndefined(permissions) ||
                    isUndefined(pathPermission) ||
                    (!isUndefined(pathPermission) && pathPermission.read);

                if (isMenuShown) {
                    let alarm = null;
                    if (remindStorage[route.path.substring(1)]) {
                        alarm = (
                            <React.Fragment>
                                <i style={{ marginLeft: '1em' }} className="ni ni-bell-55 text-warning" />
                            </React.Fragment>
                        );
                    }

                    return (
                        <NavItem className={activeRoute(route.layout + route.path)} key={route.layout + route.path}>
                            <NavLink
                                className="py-2"
                                to={route.layout + route.path}
                                // activeclassname=""
                                onClick={() => {
                                    stat.addEvent({
                                        name: 'chapter_open',
                                        value: { chapter_name: route.path.slice(1) },
                                    }); //событие
                                    closeSidenav();
                                }}
                                tag={NavLinkRRD}
                            >
                                {getRouteStyledTitle(route) || route.name}
                                {alarm}
                            </NavLink>
                        </NavItem>
                    );
                }

                return null;
            }

            return null;
        });
    };

    const scrollBarInner = (
        <div className="scrollbar-inner">
            <div className="sidenav-header d-flex align-items-center">
                <div className="ml-auto">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                        className={classnames('sidenav-toggler d-none d-xl-block', { active: sidenavOpen })}
                        onClick={toggleSidenav}
                    >
                        <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-inner">
                <Collapse navbar={true} isOpen={true}>
                    <Nav navbar={true}>{createLinks(routes)}</Nav>
                </Collapse>
            </div>
        </div>
    );

    return (
        <Navbar
            className="sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"
            onMouseEnter={onMouseEnterSidenav}
            onMouseLeave={onMouseLeaveSidenav}
        >
            {navigator.platform.indexOf('Win') > -1 ? (
                <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
            ) : (
                scrollBarInner
            )}
        </Navbar>
    );
};

export default Sidebar;
