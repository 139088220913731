import React from 'react';

import { observer } from 'mobx-react-lite';
import { ColumnDescription } from 'react-bootstrap-table-next';

import Header from 'components/headers/ItemListHeader';
import ItemsListTable from 'components/tables/ItemsListTable';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { useStore } from 'store';
import useResetListIfGoOut from 'hooks/resetListIfGoOut';

const ChannelList: React.FC = observer(() => {
    const storeName = 'channel';

    const {
        currentUser: { permissions },
    } = useStore('admin');

    const userAccess = permissions && permissions.channels;

    const isActionButtonsHidden = userAccess?.update === false && userAccess?.delete === false;

    const columns: ColumnDescription[] = [
        {
            hidden: isActionButtonsHidden,
            dataField: 'edit',
            text: 'Действия',
            sort: false,
            formatter: (text, row) => (
                <ItemActionButtons
                    storeName={storeName}
                    key={row.id}
                    id={row.id}
                    isEdit={userAccess?.update === true}
                    isDelete={userAccess?.delete === true}
                />
            ),
            style: {
                width: '5em',
            },
        },
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: {
                width: '5em',
            },
        },
        {
            dataField: 'title',
            text: 'Название',
            sort: true,
            //formatter: (text, row) => <TableTextTooltipField id={`${row.id}-title`} text={text} isShowTooltip={true} />,
            style: {
                textOverflow: 'ellipsis',
                minWidth: '50em',
                maxWidth: '50em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    ];

    const creatingProp = userAccess?.create === true && {
        creating: { title: 'Добавить канал', path: '/admin/channels/create' },
    };
    useResetListIfGoOut(storeName, 'channels');

    return (
        <ItemsListTable
            defaultTableParams={{
                page: 1,
                sizePerPage: 10,
                sortField: 'title',
                sortOrder: 'asc',
            }}
            storeName={storeName}
            columns={columns}
        >
            <Header title="Каналы" {...creatingProp} />
        </ItemsListTable>
    );
});

export default ChannelList;
