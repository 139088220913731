import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal, ListGroupItem, ListGroup } from 'reactstrap';

export interface IErrorsRequest {
    isOpen: boolean;
    data: string[];
}
interface IErrorsModal {
    isOpen: boolean;
    data: string[];
    setErrorsRequest: Dispatch<SetStateAction<IErrorsRequest>>;
}

export const ErrorsModal: React.FC<IErrorsModal> = ({ data, setErrorsRequest, isOpen }) => {
    if (!isOpen) return null;
    if (!data.length) return null;

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpen}
                toggle={() => setErrorsRequest((prev) => ({ ...prev, isOpen: !prev.isOpen }))}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Сохранение запрещено, есть ошибки!
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setErrorsRequest({ isOpen: false, data: [] })}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ListGroup>
                        {/* показываем первые 5 ошибок */}
                        {data.slice(0, 5).map((message) => (
                            <ListGroupItem>
                                <span>{message}</span>
                            </ListGroupItem>
                        ))}
                        {data.length > 5 ? (
                            <ListGroupItem>{`... есть еще ошибки (${data.length - 5})`}</ListGroupItem>
                        ) : null}
                    </ListGroup>
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setErrorsRequest({ isOpen: false, data: [] })}
                    >
                        Отмена
                    </Button>
                </div>
            </Modal>
        </>
    );
};
