import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import * as yup from 'yup';
import { isUndefined, isEmpty, isNull } from 'lodash';

import FormTextInput from 'components/inputs/FormTextInput';
import HTMLEditField from 'components/edit/HTMLEditor';
import { useStore } from 'store';
import FormDateSelect from 'components/inputs/FormDateSelect';
import SaveAndPublishButtons from 'components/buttons/SaveAndPublishButtons';
import getEditedValues from 'lib/getEditedValues';
import ChoicesSelect from 'components/inputs/dropdown-selects/ChoicesSelectFromRemote';
import FormCheckBox from 'components/inputs/FormCheckBox';
import { tagApi, userApi } from 'api';
import ReactDropzone from 'components/inputs/ReactDropzone';
import Poll from 'components/inputs/Poll';
import articleStore, { IPoll, IMeta, TMobileBlockSave, TArticleDataType } from 'store/article';
import { BlockType } from 'components/edit/HTMLEditor/types';

interface IMobileBlockForm {
    item: typeof articleStore.item;
    mode: 'create' | 'edit';
    setUnsavedTab: (isSaved: boolean) => void;
    setSavingFunction: (func: () => Promise<boolean>) => void;
}

const allowBlockTypes = [BlockType.Raw, BlockType.Embed];

const MobileBlockForm: React.FC<IMobileBlockForm> = observer(({ item, mode, setUnsavedTab, setSavingFunction }) => {
    const plainItem = toJS(item);
    const { currentUser } = useStore('admin');
    //метаданные
    const meta = useRef<IMeta>({ isFilled: false, isVideoCover: false }).current;
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };

    let initialValues: TMobileBlockSave = {};
    if (mode === 'edit') {
        //если редактирование, то извлечь прочитанные данные из store
        const {
            title,
            annotate,
            importantTo,
            publishedAt,
            isPublished,
            author,
            cover,
            coverVideo,
            editorData,
            tags,
            poll,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        } = plainItem.data!.mobile;

        initialValues = {
            title,
            annotate,
            ...(!isUndefined(tags) && !isEmpty(tags) && { tags: tags.map((tag) => tag.id) }),
            importantTo,
            isPublished,
            publishedAt,
            cover,
            coverVideo,
            poll,
            ...(!isUndefined(author) && { author: author.id }),
            editorData,
        };
    } else {
        //если создание, то занести пустые данные
        initialValues = {
            title: '',
            annotate: '',
            cover: '',
            coverVideo: [],
            tags: [],
            publishedAt: new Date(),
            isPublished: false,
            author: currentUser.id,
            editorData: [],
            poll: undefined,
        };
    }

    const validationSchema = yup.object({
        title: yup
            .string()
            .max(300, `Название должно быть максимум 300 символов`)
            .required(`Название не должно быть пустым`),
        annotate: yup
            .string()
            .max(3000, `Описание должно быть максимум 1000 символов`)
            .required(`Описание не должно быть пустым`),
        editorData: yup.array().min(1, 'Отсутствует содержание'),
        poll: yup
            .object({
                title: yup.string().required(`Голосование: вопрос не должен быть пустым`),
                choices: yup
                    .array()
                    .min(1, 'Голосование: должен быть хотя бы один вариант ответа')
                    .max(50, 'Голосование: допускается максимум 50 вариантов ответа')
                    .of(
                        yup.object({
                            text: yup.string().required(`Голосование: вариант ответа не должен быть пустым`),
                        })
                    ),
            })
            .nullable()
            .default(undefined)
            .notRequired(),
    });

    //заполнить meta по данным из initialValues
    if (!meta.isFilled) {
        meta.isFilled = true;
        meta.isVideoCover = false;
        if (initialValues.coverVideo && initialValues.coverVideo.length > 0) meta.isVideoCover = true;
    }

    const { dirty, values, handleChange, handleSubmit, setFieldValue, isValid, errors, validateForm } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            const dataType = TArticleDataType.MOBILE;
            //если редактирование
            if (mode === 'edit') {
                const ed = getEditedValues(initialValues, data);
                if (ed) {
                    const { isPublished: newIsPublishedValue, ...editedValues } = ed;
                    //если снять с публикации
                    if (newIsPublishedValue === false) {
                        item.updateOne({ dataType, mobile: { isPublished: false } });
                    }
                    //в остальных случаях
                    else if (dirty) {
                        const v = {
                            ...editedValues,
                            ...(!isUndefined(newIsPublishedValue) && { isPublished: newIsPublishedValue }),
                            ...(!isUndefined(editedValues.tags) && { tags: editedValues.tags }),
                            ...(!isUndefined(editedValues.author) && { author: editedValues.author }),
                            //если ранее было зафиксировано id poll (например, если было редактирование),
                            //то разворачиваем id поверх ранее развернутого poll из editedValues
                            ...(!isNull(editedValues.poll) &&
                                initialValues.poll?.id && {
                                    poll: { id: initialValues.poll.id, ...editedValues.poll },
                                }),
                        };
                        item.updateOne({ dataType, mobile: { ...(v as TMobileBlockSave) } });
                    }
                }
            }
            //если добавить новый
            else if (dirty) {
                //описание события: соответствие между именем свойства в событии и именем поля в БД
                const eventDescriptor = {
                    name: 'article_new',
                    props: {
                        article_id: 'id',
                        article_name: 'mobile.title',
                        tags: 'mobile.tags',
                        isPublished: 'mobile.isPublished',
                    },
                };
                item.createOne({ dataType, mobile: { ...data } }, eventDescriptor);
            }
        },
    });

    //информирование о несохраненных данных в закладке
    setUnsavedTab(!dirty);

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = useRef(async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return false;
        handleSubmit();
        return true;
    }).current;

    useEffect(() => {
        setSavingFunction(handleSave);
    }, [setSavingFunction, handleSave]);

    //*************************************************************************************************************
    //Опубликовать
    const handlePublish = async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return;
        await setFieldValue('isPublished', true);
        handleSubmit();
    };

    //*************************************************************************************************************
    //Снять с публикации
    const handleUnpublish = async () => {
        setFormSubmitted();
        //здесь нет проверки свойств, т.к. при снятии с публикации другие свойства не сохраняются
        await setFieldValue('isPublished', false);
        handleSubmit();
    };

    //поменять значение isVideoCover (checkbox)
    const handleIsVideoCover = async () => {
        const isVideoCover = !meta.isVideoCover;
        meta.isVideoCover = isVideoCover;
        //всегда обновляем значение => обновляем экран
        if (isVideoCover) setFieldValue(`cover`, '');
        else setFieldValue(`coverVideo`, []);
    };

    //ОШИБКА в formik!
    //если в списке контролов есть только один контрол, например, ввод текста, то при нажатии enter форма валится
    //причем событие submit и соответствующие кнопки в этом не участвуют
    //
    return (
        <Form className="needs-validation" noValidate={true}>
            {/* обложка - картинка */}
            {!meta.isVideoCover && (
                <Row className="justify-content-center">
                    <Col className="mb-3">
                        <ReactDropzone
                            buttonText="Загрузите обложку здесь"
                            name="cover"
                            label="Обложка"
                            setFieldValue={setFieldValue}
                            preloadedImageUrl={values.cover}
                            invalidText={errors.cover}
                        />
                    </Col>
                </Row>
            )}
            {/* видео обложка */}
            {meta.isVideoCover && (
                <Row>
                    <Col className="mb-3">
                        <HTMLEditField
                            placeholder="Это видео будет показано в ленте"
                            name="coverVideo"
                            label="Видео обложка"
                            initialValue={values.coverVideo}
                            invalidText={errors.coverVideo as string}
                            setFieldValue={setFieldValue}
                            blockTypes={allowBlockTypes}
                        />
                    </Col>
                </Row>
            )}

            {/* переключатель обложки */}
            <Row>
                <Col className="col-10 mb-3">
                    <FormCheckBox
                        name="isVideoCover"
                        title="Видео обложка"
                        value={meta.isVideoCover}
                        handleChange={() => handleIsVideoCover()}
                    />
                </Col>
            </Row>

            <Row>
                <Col md="6" className="mb-3">
                    <FormTextInput
                        invalidText={errors.title}
                        title="Название"
                        placeholder="Введите название статьи"
                        name="title"
                        value={values.title}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12" className="mb-3">
                    <FormTextInput
                        title="Подзаголовок"
                        placeholder="Кратко опишите о чем статья"
                        name="annotate"
                        type="textarea"
                        value={values.annotate}
                        invalidText={errors.annotate}
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    <HTMLEditField
                        name="editorData"
                        label="Контент"
                        initialValue={values.editorData}
                        invalidText={errors.editorData as string}
                        setFieldValue={setFieldValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4" className="mb-3">
                    <ChoicesSelect
                        getSelect={userApi.getSelect}
                        defaultSelect={values.author}
                        isRemoveButton={true}
                        placeholder="Укажите автора"
                        name="author"
                        setFieldValue={setFieldValue}
                        label="Автор"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="6">
                    <ChoicesSelect
                        maxItemCount={10}
                        isMultiple={true}
                        defaultSelects={values.tags || undefined}
                        getSelect={tagApi.getSelect}
                        placeholder="Укажите тэги"
                        name="tags"
                        setFieldValue={setFieldValue}
                        label="Тэги"
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4" className="mb-3">
                    <FormDateSelect
                        initialValue={values.publishedAt || undefined}
                        name="publishedAt"
                        label="Дата публикации"
                        placeholder="Введите дату"
                        setFieldValue={setFieldValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4" className="mb-3">
                    <FormDateSelect
                        initialValue={values.importantTo || undefined}
                        name="importantTo"
                        label="Сделать главной до"
                        placeholder="Введите дату"
                        setFieldValue={setFieldValue}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mb-3">
                    <Poll
                        value={values.poll}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        errors={errors.poll as Partial<IPoll>}
                    />
                </Col>
            </Row>

            {/* кнопки */}
            <div className="mt-4" />
            <SaveAndPublishButtons
                isPublished={values.isPublished || false}
                isValid={isValid}
                isDirty={dirty}
                pathToList="/admin/articles"
                onSave={handleSave}
                onPublish={handlePublish}
                onUnpublish={handleUnpublish}
            />
        </Form>
    );
});

export default MobileBlockForm;
