import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row, Button } from 'reactstrap';
import * as yup from 'yup';

import { config } from 'lib/config';
import { stat } from 'lib/stat';
import FormTextInput from 'components/inputs/FormTextInput';
import reportStore from 'store/report';
import getEditedValues from 'lib/getEditedValues';
import { reportApi } from '../../../api';

interface ITagEditForm {
    store: typeof reportStore.item;
}

const ReportViewForm: React.FC<ITagEditForm> = ({ store }) => {
    const idReport = store.data?.id;
    //ссылка на сформированный отчет
    const [reportLink, setReportLink] = useState({ exists: false, fileName: '' });

    //заполнить начальные значения в соответствии с параметрами вызова выбранного отчета
    //в данном примере это один параметр - id викторины
    const initialValues = { quizId: 0 };

    const validationSchema = yup.object().shape({
        quizId: yup.number().integer().min(1, 'должно быть больше нуля').required('обязательное поле'),
    });

    const { dirty, values, handleChange, handleSubmit, /* setFieldValue, isValid, validateForm */ errors } = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (data) => {
            if (dirty) {
                const { quizId } = getEditedValues(initialValues, data);
                if (quizId) {
                    //событие
                    stat.addEvent({
                        name: idReport === 1 ? 'reports_edit_csv' : 'reports_edit_pdf',
                        value: { quiz_id: quizId },
                    });
                    //подготовить отчет
                    let fileName;
                    try {
                        const result = await reportApi.job({ idReport, quizId });
                        fileName = result.data.fileName;
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                    if (fileName) setReportLink({ exists: true, fileName });
                    else setReportLink({ exists: false, fileName: '' });
                }
            }
        },
    });

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        invalidText={errors.quizId}
                        title="Идентификатор викторины"
                        placeholder="Введите ID"
                        name="quizId"
                        value={values.quizId}
                        handleChange={handleChange}
                        type="number"
                    />
                </Col>
            </Row>
            <div className="text-center">
                <Button className="mt-4" color="primary" type="submit" outline={true}>
                    Подготовить
                </Button>
                {reportLink.exists && (
                    <a href={`${config.api.getBaseUrl()}/reporting/file?fileName=${reportLink.fileName}`}>
                        <Button className="mt-4" color="primary" type="button">
                            Ссылка на отчет
                        </Button>
                    </a>
                )}
                {!reportLink.exists && (
                    <Button className="mt-4" color="primary" type="button" outline={true} disabled={true}>
                        отчет не подготовлен
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default ReportViewForm;
