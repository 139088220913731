import { action, makeAutoObservable, observable } from 'mobx';
//import { isEmpty, isUndefined } from 'lodash';

import { TPermission } from 'store/role';

interface IUser {
    id: number;
    username?: string;
    lastname?: string;
    firstname?: string;
    permissions?: TPermission;
    commonName?: string;
}

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable currentUser: IUser = { id: 0 };

    // @observable shownUserName = '';

    @action setUserData(data: IUser) {
        const { username, firstname, lastname, permissions, id, commonName } = data;
        this.currentUser = { id, username, firstname, lastname, permissions, commonName };
        // this.setShownUserName(data);
    }

    // @action setShownUserName(user: IUser) {
    //     this.shownUserName = this.generateShownUserName(user);
    // }

    // generateShownUserName(user: IUser) {
    //     if (isUndefined(user)) {
    //         return '';
    //     }
    //     if (!isEmpty(user.lastname) && !isEmpty(user.firstname) && !isEmpty(user.username)) {
    //         return `${user.lastname} ${user.firstname} ${user.username}`;
    //     }

    //     if (!isEmpty(user.lastname) && !isEmpty(user.firstname)) {
    //         return `${user.lastname} ${user.firstname}`;
    //     }

    //     return user.username || '';
    // }
}

export default new UserStore();
