import { makeAutoObservable } from 'mobx';

import { adviceApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';

export enum AdviceStatus {
    NEW = 'NEW', //новый
    IN_PROCESS = 'IN_PROCESS', //прочитан
}

export const AdviceStatusTitle = {
    [AdviceStatus.NEW]: 'новый',
    [AdviceStatus.IN_PROCESS]: 'прочитан',
};

export interface IAdvice {
    id: number;
    email: string;
    text: string;
    status: keyof typeof AdviceStatus;
    createdAt: Date;
    userCreateId: number;
    userCommonName: string;
}

export interface IAdviceUpdate {
    status?: string;
}

class AdviceStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IAdvice, IAdviceUpdate, never>(adviceApi);

    readonly list = new ListStore<IAdvice>(adviceApi);
}

export default new AdviceStore();
