import React, { useState } from 'react';
import {
    Button,
    Modal,
    Card,
    CardBody,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
} from 'reactstrap';

interface IChooseQuizModal {
    isOpen: boolean;
    toggleOpen: (isOpen: boolean | ((value: boolean) => boolean)) => void;
    callback: (id: number) => void;
    initialValue: number;
}

export const ChooseQuizModal: React.FC<IChooseQuizModal> = ({ callback, toggleOpen, isOpen, initialValue }) => {
    const [data, setData] = useState({ quizId: initialValue });

    function handleChangeData(event: React.ChangeEvent<HTMLInputElement>, itemName: string) {
        setData((prev) => ({ ...prev, [itemName]: event.target.value }));
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (data.quizId > 0) {
            toggleOpen(false);
            callback(data.quizId);
        }
    };

    return (
        <React.Fragment>
            <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggleOpen((value) => !value)}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0 mb-0">
                        <CardBody className="px-lg-5">
                            <div className="text-center text-muted mb-4">Задайте ID выбранной викторины:</div>
                            <Form role="form" onSubmit={handleSubmit}>
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            bsSize="lg"
                                            placeholder="ID"
                                            type="number"
                                            name="quizId"
                                            id="quizId"
                                            onChange={(event) => handleChangeData(event, 'quizId')}
                                            value={data.quizId}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        className="mt-4"
                                        color="secondary"
                                        //data-dismiss="modal"
                                        type="button"
                                        onClick={() => toggleOpen(false)}
                                    >
                                        Отмена
                                    </Button>
                                    <Button
                                        className="mt-4"
                                        color="primary"
                                        type="submit"
                                        //onClick={() => handleSubmit(data.quizId)}
                                    >
                                        Продолжить
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </React.Fragment>
    );
};
