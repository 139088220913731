import { makeAutoObservable } from 'mobx';

import { roleApi } from 'api';
import ListStore from 'store/common/List';
import ItemStore from 'store/common/Item';
import runItemsReactions from 'store/common/runItemsReactions';
import { TItemName } from 'lib/items';

export interface IRoleInList {
    id: number;
    title: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IRole {
    id: number;
    title: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    permissions: TPermission;
}

export type TPermission = {
    [key in TItemName]: IAccess;
};

export interface IAccess {
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface IRoleUpdate {
    title?: string;
    permissions?: Partial<TPermission>;
}

export interface IRoleCreate {
    title?: string;
}

class RoleStore {
    constructor() {
        makeAutoObservable(this);
        runItemsReactions(this);
    }

    item = new ItemStore<IRole, IRoleUpdate, IRoleCreate>(roleApi);

    readonly list = new ListStore<IRoleInList>(roleApi);
}

export default new RoleStore();
