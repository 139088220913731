import React, { useRef } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { useIdleTimer } from 'react-idle-timer';
import notifyStore from 'store/notification';
import { AlertOptions } from 'react-notification-alert';

import { authApi } from 'api';
import Loader from 'components/Loader';
import AdminLayoutComponent from 'layouts/Admin/AdminLayout';

const AdminLayout: React.FC = observer(() => {
    const authStore = useStore('auth');
    const isWaitingForLoginTry = !authStore.isLoggedIn && !authStore.isLoginTryDone;
    //таймер приостановки приложения из-за неактивности
    const timer = useRef<NodeJS.Timeout | null>(null);

    //обработчик события простоя
    const handleOnIdle = (/* event: Event */) => {
        notifyStore.showNotify({
            place: 'tc',
            message: `Приложение будет приостановлено через 10 сек из-за периода неактивности`,
            type: 'warning',
            autoDismiss: 10,
        } as AlertOptions);
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(() => {
            authApi
                .logout()
                .then(() => {})
                .catch(() => {});
            authStore.logout();
        }, 10000);
    };

    //обработчик события выхода из простоя
    const handleOnAction = (/* e: Event */) => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    //таймер простоя
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 500,
    });

    if (isWaitingForLoginTry) {
        return <Loader />;
    }

    return <AdminLayoutComponent />;
});

export default AdminLayout;
