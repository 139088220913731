import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { toJS } from 'mobx';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';

import FormTextInput from 'components/inputs/FormTextInput';
import mobilerStore from 'store/mobiler';

import getEditedValues from 'lib/getEditedValues';
import UserStatusSelect from 'components/inputs/dropdown-selects/UserStatusSelect';
import SaveButtons from 'components/buttons/SaveButtons';

interface IMobilerEditForm {
    item: typeof mobilerStore.item;
}

const MobilerEditForm: React.FC<IMobilerEditForm> = observer(({ item }) => {
    const plainItem = toJS(item);
    //признак динамической проверки полей
    const isFormSubmitted = useRef(false);
    const setFormSubmitted = () => {
        isFormSubmitted.current = true;
    };

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const initialValues = {
        email: plainItem.data!.email,
        commonName: plainItem.data!.commonName,
        status: String(plainItem.data!.status),
    };
    /* eslint-enable */

    const { dirty, values, /*handleChange,*/ handleSubmit, setFieldValue, isValid, errors, validateForm } = useFormik({
        initialValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            const editedValues = getEditedValues(initialValues, data);
            if (dirty && editedValues) {
                //описание события
                const eventDescriptor = {
                    name: 'user_edit',
                    props: { user_id: 'id' },
                };
                //исправить объект
                //компонент Choices отказывается работать со значением number, поэтому ранее преобразовали в string
                //а теперь возвращаем в number
                const { status, ...passThroughProps } = editedValues;
                item.updateOne(
                    {
                        ...passThroughProps,
                        ...(!isUndefined(status) && { status: Number(status) }),
                    },
                    eventDescriptor
                );
            }
        },
    });

    //*************************************************************************************************************
    //Если начат процесс сохранения формы (isFormSubmitted), то проверять все поля формы при каждом изменении
    //значений (values)
    useEffect(() => {
        if (isFormSubmitted.current) {
            validateForm().then(() => {});
        }
    }, [values, validateForm]);

    //*************************************************************************************************************
    //сохранить форму
    const handleSave = async () => {
        setFormSubmitted();
        if (Object.keys(await validateForm()).length > 0) return;
        handleSubmit();
    };

    //ОШИБКА в formik!
    //если в списке контролов есть только один контрол, например, ввод текста, то при нажатии enter форма валится
    //причем событие submit и соответствующие кнопки в этом не участвуют
    //
    return (
        <Form className="needs-validation" noValidate={true}>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Почта"
                        name="email"
                        value={values.email}
                        invalidText={errors.email}
                        //handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <FormTextInput
                        title="Псевдоним"
                        name="commonName"
                        invalidText={errors.commonName}
                        value={values.commonName}
                        //handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" md="4">
                    <UserStatusSelect
                        selectedValue={values.status}
                        label="Статус"
                        name="status"
                        setFieldValue={setFieldValue}
                    />
                </Col>
            </Row>

            <div className="mt-4" />
            <SaveButtons isValid={isValid} isDirty={dirty} pathToList="/admin/mobilers" onSave={handleSave} />
        </Form>
    );
});

export default MobilerEditForm;
