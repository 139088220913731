import { makeAutoObservable, action } from 'mobx';
import { omit, debounce } from 'lodash';
import { TTableFilter, TTableFilters } from '../../lib/types';

interface IApi<T> {
    getList: (
        params: ITableChangeState,
        persistedFilters: TTableFilters
    ) => Promise<{ data: { rows: T[]; total: number } }>;
}

export interface ITableChangeState {
    page: number;
    sizePerPage: number;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
    filters?: {
        [key: string]: TTableFilter;
    };
}

const defaultPagination = { page: 1, sizePerPage: 10 };

class ListStore<T> {
    constructor(api: IApi<T>) {
        this.api = api;
        makeAutoObservable(this, { persistedFilters: false });
    }

    //изменения этих свойств не отслеживаются в mobx, как указано в конструкторе
    //---------
    //постоянные условия фильтров - сохраняются между обновлениями компонентов:
    //будут добавляться к методу getList в подключенном api через pullList
    //если пусто, то не добавляются
    persistedFilters: TTableFilters = {};

    @action setPersistedFilter(filters: TTableFilters) {
        this.persistedFilters = filters;
    }

    api: IApi<T>;

    queryParams: ITableChangeState = defaultPagination;

    filtersEnabled: {
        [key: string]: boolean;
    } = {};

    listSize!: number;

    isListLoading = false;

    itemsList: T[] | undefined;

    errors = undefined;

    //при изменении значений фильтров список обновляем с задержкой
    debounceChangeFilters: undefined | (() => void) = undefined;

    @action changeFilters(filters?: ITableChangeState['filters']) {
        if (!this.debounceChangeFilters) {
            this.debounceChangeFilters = debounce(() => this.pullList(), 400);
        }
        this.queryParams.page = defaultPagination.page;
        this.queryParams.filters = {
            ...this.queryParams.filters,
            ...filters,
        };
        //this.pullList();
        this.debounceChangeFilters(); //при изменении значений фильтров список обновляем с задержкой
    }

    @action enableFilter(filterName: string) {
        this.filtersEnabled[filterName] = true;
    }

    @action disableFilter(filterName: string) {
        this.queryParams.page = defaultPagination.page;
        this.filtersEnabled[filterName] = false;
        this.queryParams.filters = omit(this.queryParams.filters, filterName);
        this.pullList();
    }

    @action disableAllFilters() {
        this.queryParams.page = defaultPagination.page;
        this.filtersEnabled = {};
        delete this.queryParams.filters;
        this.pullList();
    }

    @action pullList(query?: ITableChangeState) {
        this.isListLoading = true;
        this.api
            .getList(query || this.queryParams, this.persistedFilters)
            .then(
                action(({ data }) => {
                    if (query) {
                        this.queryParams = query;
                    }
                    this.itemsList = data.rows;
                    this.listSize = data.total;
                })
            )
            .finally(
                action(() => {
                    this.isListLoading = false;
                })
            );
    }

    @action resetState() {
        this.isListLoading = false;
        this.itemsList = undefined;
        this.queryParams = {
            sizePerPage: this.queryParams.sizePerPage,
            page: 1,
            sortField: this.queryParams.sortField,
            sortOrder: this.queryParams.sortOrder,
        };
        this.filtersEnabled = {};
        this.errors = undefined;
    }
}

export default ListStore;
