const formatTime = (count: number) => {
    return (count / 60).toFixed(2);
};

export function msToMMSS(value: number) {
    const minutes = Math.floor(value / 60000);
    const seconds = Math.floor((value - minutes * 60000) / 1000);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export default formatTime;
