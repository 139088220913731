import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import SpinnerButton from './SpinnerButton';

interface IProps {
    isValid: boolean;
    isDirty: boolean;
    isPublished: boolean;
    onSave?: () => void;
    onPublish?: () => void;
    onUnpublish?: () => void;
    pathToList: string;
    isLoading?: boolean;
}

const SaveAndPublishButtons: React.FC<IProps> = ({
    isValid,
    isDirty,
    isPublished,
    onSave,
    onPublish,
    onUnpublish,
    pathToList,
    isLoading = false,
}) => {
    const navigate = useNavigate();

    const handlePublish = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onPublish) onPublish();
    };

    const handleUnpublish = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onUnpublish) onUnpublish();
    };

    return (
        <Row>
            <Col className="d-flex justify-content-start">
                <Button
                    onClick={() => navigate(pathToList, { state: { isFromEdit: true } })}
                    size="sm"
                    color="secondary"
                    type="button"
                >
                    К списку
                </Button>
            </Col>
            <Col className="d-flex justify-content-end">
                {isPublished && (
                    <Button disabled={isLoading} onClick={handleUnpublish} size="sm" color="warning" type="button">
                        Снять с публикации
                    </Button>
                )}
                <SpinnerButton
                    onClick={onSave}
                    disabled={!isValid || !isDirty || isLoading}
                    isLoading={isLoading}
                    title={isPublished ? 'Сохранить изменения' : 'Сохранить как черновик'}
                />
                {!isPublished && (
                    <Button
                        disabled={!isValid || isLoading}
                        onClick={handlePublish}
                        size="sm"
                        color="success"
                        type="button"
                    >
                        Опубликовать
                    </Button>
                )}
            </Col>
        </Row>
    );
};

export default SaveAndPublishButtons;
